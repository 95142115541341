import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./WhatEaproTeamMateSaySlider.css";
import "./ResponsiveWhatEaproTeamMateSaySlider.css";
import $ from 'jquery';
import { geTtestimonialData } from '../../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const WhatEaproTeamMateSaySlider = (bannerData) => {
    const JavascriptVoid = (event) => {
        event.preventDefault();
    };
    let infinite
    const Slider_group = {
        dots: false,
        nav: true,
        infinite: { infinite },
        speed: 500,
        lazyLoad: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        initialSlide: 0,
        arrows: true,
        swipe: true,
        centerMode: true,
        centerPadding: '10px',
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(geTtestimonialData());
    }, []);

    const testimonialData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.testimonialDataList?.data) : []);
    const totalStars = 5;

    return (
        <>
            {testimonialData ?
                <section className='what-eapro-team-mate-slider-section'>
                    <div className="container">
                        <div className="heading-dv">
                            <h5>{bannerData?.bannerData?.careerSecondHeading ? bannerData?.bannerData?.careerSecondHeading : "What EAPRO Team Mate Say"}</h5>
                            <p>{bannerData?.bannerData?.careerSecondDescription ? bannerData?.bannerData?.careerSecondDescription : "At EAPRO, our success is not only measured by the satisfaction of our clients but also by the dedication and enthusiasm of our team members. Here's what some of our employees have to say about being a part of the EAPRO family:"}</p>
                        </div>
                        <div className="heading-tabs-parent">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12">
                                    <Slider {...Slider_group} className='slider-parent-dv'>
                                        {testimonialData?.map((data) => (
                                            <div className='slider-item-dv' key={data.id}>
                                                <div className="slider-card-parent">
                                                    <img src={data?.image ? `${host}/${data?.image}` : ""} alt="" className='img-fluid' />
                                                    <div className="card-content-parent">
                                                        <h4>{data?.name}</h4>
                                                        <p>{data?.message}</p>
                                                        <div className="star-bottom">
                                                            {/* Render filled stars */}
                                                            {Array.from({ length: data?.stars }).map((_, index) => (
                                                                <svg key={`filled_${index}`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F8B84E" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                </svg>
                                                            ))}

                                                            {/* Render unfilled stars */}
                                                            {Array.from({ length: totalStars - data?.stars }).map((_, index) => (
                                                                <svg key={`unfilled_${index}`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="grey" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                </svg>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                            {/* <div className="footer-view-all-btn">
                            <a href="#">View All</a>
                        </div> */}
                        </div>

                    </div>
                </section>
                :
                <section className='what-eapro-team-mate-slider-section'>

                </section>
            }
        </>
    )
}

export default WhatEaproTeamMateSaySlider