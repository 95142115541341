import React from 'react'
import "./EaproGlobalLtdBanner.css"
import "./ResponsiveEaproGlobalLtdBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const EaproGlobalLtdBanner = (eaproLtdData) => {
    return (
        <>
            <section className='global-energy-ltd-banner-section'>
                {/* <div className="container-fluid"> */}
                {/* <div className="row align-items-center"> */}
                <div className="col-md-12 text-start">
                    <div className="business-heading text-start">
                        <img src={eaproLtdData?.eaproLtdData?.banner_image ? `${host}/${eaproLtdData?.eaproLtdData?.banner_image}` : "./images/global-energy/banner-global-ltd.jpg"} alt="" className='img-fluid' />
                        {/* <h1>Help & Support</h1> */}
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </section>
        </>
    )
}

export default EaproGlobalLtdBanner