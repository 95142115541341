import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./ECatalogueBannerNew.css"
import { getAllBannerData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const ECatalogueBannerNew = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);



    return (
        <>
            <section className='e-catalogue-banner-page-banner-section'>
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel-parent">
                            <div className="banner-top-img-bg-rel">
                                <img src={bannerData?.catalogueBanner ? `${host}/${bannerData?.catalogueBanner}` : ""} alt="" className='img-fluid banner-img-tag' />
                                {bannerData?.catalogueHeading &&
                                    <div className="banner-top-content-absol business-heading text-start">
                                        <img src="./images/help-and-support/heading-bg.png" alt="" className='img-fluid' />
                                        <div className='business-banner-heading'>
                                            <div className="container">
                                                <h1>{bannerData?.catalogueHeading}</h1>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ECatalogueBannerNew