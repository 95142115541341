import React from 'react'
import parse from 'html-react-parser';
import "./BlogDetailsContent.css"
const BlogDetailsContent = (blogData) => {

    const url = blogData?.blogData?.link
    const videoId = url?.split('/')[3]?.split('?')[0];

    // Construct the embed URL format
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <>
            <section className='blog-details-con-page-content-section'>

                {blogData?.blogData ?
                    <div className="container">
                        <div className="blog-details-inner">
                            {blogData?.blogData?.summary ? <b> {parse(blogData?.blogData?.summary)}</b> :
                                ""}
                        </div>

                        {blogData?.blogData?.link && <div className="blog-details-inner-video">
                            <iframe width="100%" height="391" src={blogData?.blogData?.link ? `${embedUrl}` : "https://www.youtube.com/embed/FFBnPT1Z5uA"} title={blogData?.blogData?.title ? blogData?.blogData?.title : "EAPRO Industrial Visit by Teerthanker Mahaveer University"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>}
                        {blogData?.blogData?.description ? parse(blogData?.blogData?.description) :
                            ""}

                    </div>
                    :
                    ""

                }
            </section >
        </>
    )
}

export default BlogDetailsContent