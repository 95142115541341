import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./OurSpecialization.css"
import "./ResponsiveOurSpecialization.css"
import { getOurSpecializationData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const OurSpecialization = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getOurSpecializationData())
    }, []);


    const ourSpecializationData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.ourSpecializationDataList?.data) : []);

    return (
        <>
            <section className='our-specialization-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="specialization-heading-dv">
                                <h5>Our Specialization</h5>
                            </div>
                        </div>
                        <div className="col-lg-10 col-12 col-md-10 offset-md-1 offset-lg-1">
                            <div className="specialization-image">
                                <img src={ourSpecializationData?.image ? `${host}/${ourSpecializationData?.image}` : ""} alt="" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurSpecialization