import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./KnowledgeCentrePageContent.css"

import { getBlogData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;


const KnowledgeCenterPageBlogs = (bannerData) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBlogData());

    }, []);

    const blogData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.blogDataList?.data) : []);
    const navigate = useNavigate();
    const handleClick = (name) => {
        if (name != undefined) {
            navigate(`/blog-details/${name}`);
        }
    }

    const Slider_group = {
        dots: false,
        nav: true,
        infinite: blogData?.length > 3,
        speed: 500,
        lazyLoad: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        initialSlide: 0,
        arrows: true,
        swipe: true,
        centerMode: true,
        centerPadding: '10px',
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: blogData?.length > 3,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    dots: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    centerMode: false,
                    centerPadding: '0',
                    dots: false,
                },
            },
        ],
    };

    return (
        <>
            {blogData?.length != "0" ?
                <section className='knowledge-centre-page-bblogs-element-section'>
                    <div className="container">
                        <div className="heading-dv">
                            <h5>Blogs</h5>
                        </div>
                        <div className="heading-tabs-parent">
                            <Slider {...Slider_group} className='slider-parent-dv'>
                                {blogData?.map((data) => (
                                    <div className='slider-item-dv' >
                                        <div className="slider-item-img" onClick={() => { handleClick(data?.slug) }} style={{ cursor: "pointer" }}>
                                            <img src={data?.image ? `${host}/${data?.image}` : ""} alt="" className='img-fluid' />
                                            {/* <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div> */}
                                        </div>
                                        <div className="slider-item-heading" onClick={() => { handleClick(data?.slug) }} style={{ cursor: "pointer" }}>
                                            <h5>{data?.title ? data?.title : "DIFFRENCE BETWEEN MPPT & PWM"}</h5>
                                            <div className='blog-custom-div-scroll'>{data?.summary ? parse(data?.summary) : <div>Yoga is an ancient practice that originated in India and encompasses physical, mental, and spiritual aspects.</div>}</div>
                                            <div className='mt-2 '><b>{new Date(data?.createdAt).toDateString()}</b></div>
                                        </div>
                                    </div>
                                ))}

                            </Slider>
                        </div>

                    </div>
                </section>
                :
                <section className='knowledge-centre-page-bblogs-element-section'>

                </section>
            }
        </>
    )
}

export default KnowledgeCenterPageBlogs