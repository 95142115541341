import React from 'react'
import "./CareerAtEaproOneBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const CareerAtEaproOneBanner = (bannerData) => {
    return (
        <>
            <section className='career-at-eapro-banner-section'>
                <div className="col-md-12 text-start">
                    <div className="banner-top-img-bg-rel text-start">
                        <img src={bannerData?.bannerData?.careerBanner ? `${host}/${bannerData?.bannerData?.careerBanner}` : ""} alt="" className='img-fluid' />
                        <div className="banner-top-content-absol">
                            <div className="container">
                                {bannerData?.bannerData?.careerHeading && <h3>{bannerData?.bannerData?.careerHeading}</h3>}
                                {bannerData?.bannerData?.careerDescription && <p>{bannerData?.bannerData?.careerDescription}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CareerAtEaproOneBanner