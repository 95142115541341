
import "./AboutBannerCompo.css"
const host = process.env.REACT_APP_API_SERVER;
const AboutBannerCompo = (aboutUsData) => {

    return (
        <>
            <section className='about-banner-parent-section'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3">
                            <div className="year-of-dv-parent">
                                <img src={aboutUsData?.aboutUsData?.banner_image ? `${host}/${aboutUsData?.aboutUsData?.banner_image}` : ""} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="years-of-trust-content">
                                <h1>12 years of trust in our technology innovative, reliable, customer-centric, empowering businesses worldwide.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutBannerCompo