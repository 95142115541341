import React from 'react'
import ViewSolarProductSeriesListingContent from './ViewSolarProductSeriesListingContent'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import PowerSolutionPageBanner from '../PowerSolutionPage/PowerSolutionPageBanner/PowerSolutionPageBanner'
import SolarSolutionPageBanner from '../SolarSolutionPage/SolarSolutionPageBanner/SolarSolutionPageBanner'

const ViewSolarProductSeriesListing = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <SolarSolutionPageBanner/>
            {/* <PowerSolutionPageBanner/> */}
            <ViewSolarProductSeriesListingContent/>
            <Footer />
        </>
    )
}

export default ViewSolarProductSeriesListing