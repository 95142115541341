import React from 'react'
import "./EfficientMechanicalSolarContent.css"
import parse from 'html-react-parser';
const EfficientMechanicalSolarContent = (efficientMechanicalData) => {
    return (
        <>
            <section className='efficient-mechanical-solar-page-content-section'>
                <div className="container">
                    <div className="global-energy-heading">
                        <h5>Efficient Mechanical & Solar Pvt. Ltd.</h5>
                    </div>
                    <div className="top-para">
                        {efficientMechanicalData?.efficientMechanicalData?.about_us_content ? parse(efficientMechanicalData?.efficientMechanicalData?.about_us_content) : <p>Welcome to Eapro Efficient Mechanical Solar Pvt Ltd, a global player in the power electronics industry, connecting the world through cutting-edge solutions. Established in 2023 as a specialized entity within the Eapro Group, we are dedicated to serving the global market through export sales and providing OEM (Original Equipment Manufacturer) and ODM (Original Design Manufacturer) services</p>}
                    </div>
                    <div className="icon-row-parent row">
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/mission.png" alt="" />
                                    <h4>OUR  <span>MISSION</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-mission.png" alt="" />
                                    {efficientMechanicalData?.efficientMechanicalData?.our_mission ? parse(efficientMechanicalData?.efficientMechanicalData?.our_mission) : <p>Our mission is to empower businesses and individuals worldwide with top-tier power electronics solutions. Through our commitment to excellence, reliability, and innovation, we aim to exceed the expectations of our global clients. Eapro Overseas Pvt Ltd strives to be the go-to partner for OEM and ODM services, providing tailored solutions that cater to the unique needs of diverse markets.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/vision.png" alt="" />
                                    <h4>OUR  <span>Vision</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-vision.png" alt="" />
                                    {efficientMechanicalData?.efficientMechanicalData?.our_vision ? parse(efficientMechanicalData?.efficientMechanicalData?.our_vision) : <p>At Eapro Overseas Pvt Ltd, our vision is to be a trailblazer in the global power electronics arena. We aspire to be recognized as a driving force in shaping the international landscape of sustainable energy solutions, fostering innovation, and creating a positive impact on a global scale.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card last-icon-heading-card">
                                    <img src="./images/global-energy/values.png" alt="" />
                                    <h4>OUR  <span>Values</span></h4>
                                </div>
                                <div className="dark-icons-content last-dark-icons-content">
                                    <img src="./images/global-energy/dark-values.png" alt="" />
                                    {efficientMechanicalData?.efficientMechanicalData?.our_value ? parse(efficientMechanicalData?.efficientMechanicalData?.our_value) : <p>Global Collaboration: We believe in the power of collaboration on a global scale. By fostering partnerships with clients, suppliers, and stakeholders worldwide, we aim to create a network that transcends borders and drives mutual success.</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="icon-row-parent row mt-5">
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/reliability.png" alt="" />
                                    <h4>OUR  <span>Reliability</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-reliability.png" alt="" />
                                    {efficientMechanicalData?.efficientMechanicalData?.our_reliability ? parse(efficientMechanicalData?.efficientMechanicalData?.our_reliability) : <p>We operate with the highest standards of integrity and reliability. Our clients trust us to deliver quality products and services consistently, and we take pride in upholding these principles in every aspect of our global operations.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/sustainability.png" alt="" />
                                    <h4>OUR  <span>Sustainability</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-sustainability.png" alt="" />
                                    {efficientMechanicalData?.efficientMechanicalData?.our_sustainability ? parse(efficientMechanicalData?.efficientMechanicalData?.our_sustainability) : <p>Innovation is ingrained in our DNA. We constantly seek new ways to advance power electronics solutions, ensuring that our clients receive products and services that are at the forefront of technological progress.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card last-icon-heading-card">
                                    <img src="./images/global-energy/collaboration.png" alt="" />
                                    <h4>OUR  <span>Collaboration</span></h4>
                                </div>
                                <div className="dark-icons-content last-dark-icons-content">
                                    <img src="./images/global-energy/dark-collaboration.png" alt="" />
                                    {efficientMechanicalData?.efficientMechanicalData?.our_collaboration ? parse(efficientMechanicalData?.efficientMechanicalData?.our_collaboration) : <p>Beyond providing exceptional products and services, we are committed to sustainability. Our global initiatives aim to contribute to a greener future by promoting energy efficiency and environmentally responsible practices.</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EfficientMechanicalSolarContent