import { React, useEffect } from 'react'
import EventPageBanner from './EventPageBanner/EventPageBanner'
import Navbar from '../Header/Navbar'
import MobileNavbar from '../Header/MobileNavbar'
import NavbarSecond from '../Header/NavbarSecond'
import Footer from '../Footer/Footer'
import EventPageContent from './EventPageContent/EventPageContent'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAllBannerData } from '../../Redux/Action/Action'

const EventPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);



    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EventPageBanner bannerData={bannerData} />
            <EventPageContent />
            <Footer />
        </>
    )
}

export default EventPage