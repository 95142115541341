import { React, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import ProductDetailsPageContent from './ProductDetailsPageContent/ProductDetailsPageContent'
import { getProductData, getProductImgData } from '../../Redux/Action/Action'

const ProductDetailsPage = () => {

    const dispatch = useDispatch();
    const { name } = useParams();

    useEffect(() => {

        dispatch(getProductData(name))

    }, [name]);


    const productData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.productDataList?.data) : []);
    // const pid = productData?.[0]?.id;

    // useEffect(() => {
    //     if (pid != undefined) {
    //         dispatch(getProductImgData(pid))
    //     }

    // }, [productData]);
    // const productImgData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.productImgDataList?.data) : []);




    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ProductDetailsPageContent productData={productData} />
            <Footer />
        </>
    )
}

export default ProductDetailsPage