import React from 'react'
import parse from 'html-react-parser'
import "./AdvancedTechnology.css"


const AdvancedTechnologyContent = (pillarsData) => {
  const data = pillarsData?.pillarsData
  return (
    <>
      <section className='advanced-technology-content-sec'>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="new-page-heading">
                <h1>{data?.title}</h1>
              </div>
            </div>
            <div className="col-md-12 text-start">
              <div className="new-page-content">
                {data ? parse(data?.description) : ""}
              </div>
            </div>
          </div>


        </div>
      </section>

    </>
  )
}

export default AdvancedTechnologyContent