import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser'
import "./GroupOfEaproCompanies.css"
import { Link, useNavigate } from 'react-router-dom'
import { getEaproSummaryData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const GroupOfEaproCompanies = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {

        dispatch(getEaproSummaryData());

    }, []);

    const EaproSummaryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.EaproSummaryDataList?.data) : []);

    const handleEnergy = () => {
        navigate("/global-energy-ltd")
    }
    const handleLtd = () => {
        navigate("/global-energy")
    }
    const handleOverSeaClick = () => {
        navigate("/eapro-overseas")
    }
    const handleTrc = () => {
        navigate("/trc-efficient-services")
    }
    const handleMech = () => {
        navigate("/efficient-mechanical-solar")
    }
    return (
        <>
            <section className='group-of-eapro-companies-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h5>EAPRO Group Of Companies</h5>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-4" onClick={handleEnergy} style={{ cursor: "pointer" }}>
                            <div className="group-card-parent group-card-parent-yellow">
                                <div className="group-card-icon-strip">
                                    <div className='group-icon-dv'>
                                        <img src={EaproSummaryData?.ltddata?.icon ? `${host}${EaproSummaryData?.ltddata?.icon}` : ""} alt={EaproSummaryData?.ltddata?.icon} />
                                    </div>
                                    <div className="group-text-heading">
                                        <h6><Link to="/global-energy-ltd">Eapro Global Ltd.</Link></h6>
                                    </div>
                                    <div className="group-content-para">
                                        <p>{EaproSummaryData?.ltddata?.shortSummary ? parse(EaproSummaryData?.ltddata?.shortSummary) : "Welcome to Eapro Global Ltd! Since 2012, we've excelled in solar, non-solar products, and power electronics, offering top-notch OEM and ODM services to diverse clients."}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" onClick={handleLtd} style={{ cursor: "pointer" }}>
                            <div className="group-card-parent group-card-parent-dark" >
                                <div className="group-card-icon-strip">
                                    <div className='group-icon-dv'>
                                        <img src={EaproSummaryData?.energydata?.icon ? `${host}${EaproSummaryData?.energydata?.icon}` : ""} alt={EaproSummaryData?.energydata?.icon} />
                                    </div>
                                    <div className="group-text-heading">
                                        <h6><Link to="/global-energy">Eapro Global Energy Pvt Ltd</Link></h6>
                                    </div>
                                    <div className="group-content-para">
                                        <p className='text-white'>{EaproSummaryData?.energydata?.shortSummary ? parse(EaproSummaryData?.energydata?.shortSummary) : "Eapro Global Energy Pvt Ltd, a subsidiary of Eapro Group, introduces  sustainable energy solutions in India, leveraging channel sales and e-commerce. Pioneering innovation since 2023."}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" onClick={handleOverSeaClick} style={{ cursor: "pointer" }}>
                            <div className="group-card-parent group-card-parent-yellow">
                                <div className="group-card-icon-strip">
                                    <div className='group-icon-dv'>
                                        <img src={EaproSummaryData?.overseasdata?.icon ? `${host}${EaproSummaryData?.overseasdata?.icon}` : ""} alt={EaproSummaryData?.overseasdata?.icon} />
                                    </div>
                                    <div className="group-text-heading">
                                        <h6><Link to="/eapro-overseas">Eapro Overseas Pvt Ltd</Link></h6>
                                    </div>
                                    <div className="group-content-para">
                                        <p>{EaproSummaryData?.overseasdata?.shortSummary ? parse(EaproSummaryData?.overseasdata?.shortSummary) : "Welcome to Eapro Overseas Pvt Ltd, a global leader in power electronics, offering export sales, OEM, and ODM services since 2023. Connect with us for cutting-edge solutions."}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" onClick={handleTrc} style={{ cursor: "pointer" }}>
                            <div className="group-card-parent group-card-parent-dark">
                                <div className="group-card-icon-strip">
                                    <div className='group-icon-dv'>
                                        <img src={EaproSummaryData?.trcdata?.icon ? `${host}${EaproSummaryData?.trcdata?.icon}` : ""} alt={EaproSummaryData?.energydata?.icon} />
                                    </div>
                                    <div className="group-text-heading">
                                        <h6><Link to="/trc-efficient-services">TRC Efficient Services Pvt Ltd</Link></h6>
                                    </div>
                                    <div className="group-content-para">
                                        <p className='text-white'>{EaproSummaryData?.trcdata?.shortSummary ? parse(EaproSummaryData?.trcdata?.shortSummary) : "Welcome to TRC Efficient & Services Pvt Ltd, a premier service provider offering excellence in AMC support and specialized services to Eapro Group and esteemed clients."}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" onClick={handleMech} style={{ cursor: "pointer" }}>
                            <div className="group-card-parent group-card-parent-yellow">
                                <div className="group-card-icon-strip">
                                    <div className='group-icon-dv'>
                                        <img src={EaproSummaryData?.mechdata?.icon ? `${host}${EaproSummaryData?.mechdata?.icon}` : ""} alt={EaproSummaryData?.energydata?.icon} />
                                    </div>
                                    <div className="group-text-heading">
                                        <h6><Link to="/efficient-mechanical-solar">Efficient Mechanical & Solar</Link></h6>
                                    </div>
                                    <div className="group-content-para">
                                        <p>{EaproSummaryData?.mechdata?.shortSummary ? parse(EaproSummaryData?.mechdata?.shortSummary) : "A dedicated unit designs and crafts cabinets for Eapro inverters, prioritizing functionality, aesthetics, and durability, in line with Eapro's quality and innovation ethos."}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>

    )
}

export default GroupOfEaproCompanies