import React from 'react'
import "./PillarVideosDetailsBanner.css"
import "./ResponsivePillarVideosDetailsBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const PillarVideosDetailsBanner = (Banner) => {

    return (
        <>
            {/*  */}
            <section className='pillar-videos-details-banner-section' style={{ backgroundImage: `url(${host}${Banner?.Banner ? Banner?.Banner : ""})` }}>
                <div className="container-fluid">
                    {/* <div className="row align-items-center">
                        <div className="col-md-12 text-start">
                            <div className="business-heading text-start">
                                <img src="/images/help-and-support/heading-bg.png" alt="" />
                                <h1>Pillars</h1>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default PillarVideosDetailsBanner