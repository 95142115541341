import { React, useState } from 'react'
import "./StoreLocatorContent.css"
import StoreLocatorContentCard from './StoreLocatorContentCard'
const StoreLocatorContent = (storeLocatorData) => {




  const [displayLimit, setDisplayLimit] = useState(10);


  const loadMore = () => {
    setDisplayLimit((prevLimit) => prevLimit + 5);
  };

  return (
    <>
      <section className='store-locator-content-section'>

        {storeLocatorData?.storeLocatorData?.slice(0, displayLimit).map((data) => (
          <StoreLocatorContentCard data={data} key={data.id} />
        ))}

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {storeLocatorData?.storeLocatorData?.length > 0 ? <>
                {storeLocatorData?.storeLocatorData && storeLocatorData?.storeLocatorData?.length > displayLimit ? (
                  <div className="see-more-btn">

                    <button onClick={loadMore}>Load More</button>
                  </div>
                ) : storeLocatorData?.storeLocatorData && storeLocatorData?.storeLocatorData?.length <= displayLimit ? (
                  ""
                ) :
                  <h4>Search To Find Store Near You</h4>
                }
              </>
                :
                <h4> <img src='/images/no-connection.png' width={50}></img> No stores found</h4>}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StoreLocatorContent