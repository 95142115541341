import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./PurchaseAMCContent.css"
import { Link, useNavigate } from 'react-router-dom'
import { getContactDetailsData, getProductExtendedWarr } from '../../../Redux/Action/Action';
const PurchaseAMCContent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getContactDetailsData());
        dispatch(getProductExtendedWarr());
    }, []);

    const contactDetailsData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.contactDetailsDataList?.data) : []);
    const productData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.productExtendedWarrList?.data) : []);
    const [product, setProduct] = useState('');
    const handleChange = (e) => {
        setProduct(e.target.value)

    }

    const handleClick = () => {
        navigate(`/purchase-amc3/${product}`)
    }


    return (
        <>
            <section className='purchase-amc-content-content-section'>
                <div className="container">

                    <div className="form-parent-custon-dv">
                        <form className="row g-3 custom-row-form form-container">

                            <div className="col-md-12">
                                {/* <Link to="/purchase-amc3"> */}

                                <select name="" id=""
                                    className='upload-product-front'
                                    onChange={handleChange}
                                >
                                    <option value="">Select a product</option>
                                    {productData?.map((data) => (
                                        <option key={data?.id} value={data?.slug}>{data?.name}</option>
                                    ))}
                                </select>
                                {/* <label htmlFor="upload-product-front" className="upload-product-icon">
                                            <img src="./images/purchase-amc/right-icon.png" alt="" />
                                        </label> */}

                                {/* </Link> */}
                            </div>


                            <div className="col-12 text-center">
                                <div onClick={handleClick} className="btn submit-btn-custom" type="submit">
                                    Proceed
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='help-and-support-section-inner'>
                    <div className="container">
                        {/* <div className="heading-dv">
                            <h5>Help & Suport</h5>
                        </div> */}
                        <div className="help-support-inner-section">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href={`tel:${contactDetailsData?.mobile ? contactDetailsData?.mobile : "8650115000"}`}><img src="../images/phone.png" alt="" />{contactDetailsData?.mobile ? contactDetailsData?.mobile : "8650115000"}</a></p>
                                            <p><img src="../images/alarm.png" alt="" />{contactDetailsData?.whatsapp ? contactDetailsData?.whatsapp : "09:00 AM to 06:00 PM"} </p>
                                        </div>
                                    </div>
                                    {/* <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="https://api.whatsapp.com/send?phone=919068563156" target='_blank'><img src="../images/whatsapp.png" alt="" />+91-9068513651</a></p>
                                        </div>
                                    </div> */}
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href={`mailto:${contactDetailsData?.email ? contactDetailsData?.email : "info@eapro.in"}`}><img src="../images/mail.png" alt="" />{contactDetailsData?.email ? contactDetailsData?.email : "info@eapro.in"}</a></p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <a href="http://eaproerp.site:3006" target='blank'><img src="../images/book-complaint.png" alt="" />Book a complaint</a>
                                                <a href="http://eaproerp.site:3006" target='blank'><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>

                                            </p>
                                        </div>
                                    </div>
                                    <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <a href="http://eaproerp.site:3006" target='blank'><img src="../images/register-product.png" alt="" />Register your product</a>
                                                <a href="http://eaproerp.site:3006" target='blank'><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PurchaseAMCContent