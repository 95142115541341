import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser'
import { Link, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./KnowledgeCentrePageContent.css"
import $ from 'jquery';
import { getNewsData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const JavascriptVoid = (event) => {
    event.preventDefault();
};
function pauseVideo() {

    let videoId = document.getElementById("iframeyt");
    videoId.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*')
}

const KnowledgeCentrePageNewsElement = (bannerData) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getNewsData());


    }, []);

    const newsData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.newsDataList?.data) : []);
    const handleClick = (name) => {
        if (name != undefined) {
            navigate(`/news-event-details/${name}`);
        }
    }


    const Slider_group = {
        dots: false,
        nav: true,
        infinite: newsData?.length > 3,
        speed: 500,
        lazyLoad: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        initialSlide: 0,
        arrows: true,
        swipe: true,
        centerMode: true,
        centerPadding: '10px',
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: newsData?.length > 3,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    dots: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    centerMode: false,
                    centerPadding: '0',
                    dots: false,
                },
            },
        ],
    };

    return (
        <>
            {newsData?.length != 0 ? <section className='knowledge-centre-page-news-element-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h5>News & Events</h5>
                    </div>
                    <div className="heading-tabs-parent">
                        <Slider {...Slider_group} className='slider-parent-dv'>
                            {newsData?.map((data) => (
                                <div className='slider-item-dv'>
                                    <div className="slider-item-img" onClick={() => { handleClick(data?.slug) }} style={{ cursor: "pointer" }}>
                                        <img src={data?.image ? `${host}/${data?.image}` : ""} alt="" className='img-fluid' />
                                        {/* <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div> */}
                                    </div>
                                    <div className="slider-item-heading" onClick={() => { handleClick(data?.slug) }} style={{ cursor: "pointer" }}>
                                        <h5>{data?.title ? data?.title : "DIFFRENCE BETWEEN MPPT & PWM"}</h5>
                                        {data?.summary ? parse(data?.summary) : <p>Yoga is an ancient practice that originated in India and encompasses physical, mental, and spiritual aspects.</p>}
                                        <p className='mt-2'><b>{new Date(data?.createdAt).toDateString()}</b></p>
                                    </div>
                                </div>
                            ))}



                        </Slider>
                    </div>

                </div>
            </section>
                :
                <section className='knowledge-centre-page-news-element-section'>
                    ""
                </section>
            }
        </>
    )
}

export default KnowledgeCentrePageNewsElement