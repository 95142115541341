import React from 'react'
import "./BlogDetailsBanner.css"
import "./ResponsiveBlogDetailsBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const BlogDetailsBanner = (blogData) => {

    return (
        <>
            {/*  */}
            <section className='blog-banner-section' >
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel">
                            <img src={blogData?.blogData?.banner ? `${host}/${blogData?.blogData?.banner}` : ""} alt="" className='img-fluid banner-img-tag' />
                            {/* {blogData?.blogData?.title && <div className="banner-top-content-absol business-heading text-start">
                                <img src="/images/help-and-support/heading-bg.png" alt="" className='img-fluid' />
                                <div className='business-banner-heading'>
                                    <div className="container">
                                        <h1>{blogData?.blogData?.title}</h1>
                                    </div>
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogDetailsBanner