import { React, useState } from 'react'
import "./RegisterYourComplaintContent.css"
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import HourglassLoader from '../../HourglassLoader';
import HelpAndSupport from '../../HomePageComponent/HelpAndSupport/HelpAndSupport';
const host = process.env.REACT_APP_API_SERVER;
const RegisterYourComplaintContent = () => {
    const [disableBtn, setDisableBtn] = useState(false);

    let [initialValues, setInitialValues] = useState({
        customerType: "",
        name: "",
        contact: "",
        email: "",
        prdctSerialNum: "",
        address: "",
        city: "",
        state: "",
        postal: "",
        country: "",
        complaint: "",
        prdctFrontImg: "",
        prdctBackImg: "",

    })

    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: Yup.object().shape({
        //     name: Yup.string().required("Name is required"),
        //     email: Yup.string().required("Email is required.").matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid E-mail"),
        //     contact: Yup.string().required("Contact No. is required").matches(/^\+?[0-9]{1,4}?[-\s]?[0-9]{8,15}$/, "Please enter a valid Contact No. with optional country code."),
        //     city: Yup.string().required("City is required"),
        //     state: Yup.string().required("State is required"),
        //    address: Yup.string().required("Address is required"),
        //     postal: Yup.string().required("Postal Code is required").matches(/^[0-9]+$/, 'Postal code must contain only numbers')
        //         .min(4, 'Enter a valid postal code')
        //         .required('Postal code is required'),
        //     country: Yup.string().required("Country is required"),
        //     complaint: Yup.string().required("Complaint is required"),
        //     prdctFrontImg: Yup.string().required("Front Image is required"),
        //     prdctBackImg: Yup.string().required("Back Image is required"),
        // }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            try {

                setSubmitting(true);

                document.body.style.overflow = 'hidden';

                await axios.post(`${host}/web/customerSupport`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.success("Form Submitted Succesfully");
                            setDisableBtn(false)
                            resetForm()
                        } else {

                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.error(response.data.msg);
                            setDisableBtn(false)
                            resetForm()
                        }
                    })
                    .catch(function (error) {

                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.error('Error submitting form. Please try again.');
                        setDisableBtn(false)
                        resetForm()
                    })

            }
            catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');
                setDisableBtn(false)
                resetForm()
            }


        }
    })

    const [fileName, setFileName] = useState('');


    const handleFrontImgFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    const [fileBackName, setFileBackName] = useState('');


    const handleBackImgFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileBackName(file.name);
        }
    };

    return (
        <>
            <section className='register-your-complaint-content-content-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="help-support-heading">
                                <h5>Customer Support</h5>
                            </div>
                        </div>
                    </div>
                    <div className="form-parent-custon-dv">
                        <form className="row g-3 custom-row-form form-container" method='post' onSubmit={formik.handleSubmit} >
                            <FocusError formik={formik} />
                            <div className="col-md-12">
                                <label htmlFor="customer-type" className="form-label">
                                    Book your complaints with us for EAPRO products and our customer care team will get back to you.
                                </label>
                                <select
                                    className="form-select"
                                    id="customer-type"
                                    aria-describedby=""
                                    required=""
                                    placeholder="Customer Type"
                                    name="customerType"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.customerType}
                                >
                                    <option selected="" disabled="" value="customer type">
                                        Customer Type
                                    </option>
                                    <option>...</option>
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.customerType && formik.errors.customerType ? (
                                        <div className='text-danger'>{formik.errors.customerType}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address-input"
                                    defaultValue=""
                                    required=""
                                    placeholder='Name'
                                    name="name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className='text-danger'>{formik.errors.name}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address-input"
                                    defaultValue=""
                                    required=""
                                    placeholder='Contact No.'
                                    name="contact"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.contact}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.contact && formik.errors.contact ? (
                                        <div className='text-danger'>{formik.errors.contact}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="address-input"
                                    defaultValue=""
                                    required=""
                                    placeholder='E-Mail Id'
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className='text-danger'>{formik.errors.email}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address-input"
                                    defaultValue=""
                                    required=""
                                    placeholder='Product Serial No.'
                                    name='prdctSerialNum'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.prdctSerialNum}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.prdctSerialNum && formik.errors.prdctSerialNum ? (
                                        <div className='text-danger'>{formik.errors.prdctSerialNum}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="address-type" className="form-label">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address-input"
                                    defaultValue=""
                                    required=""
                                    placeholder='Address'
                                    name='address'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.address && formik.errors.address ? (
                                        <div className='text-danger'>{formik.errors.address}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    required=""
                                    placeholder='City'
                                    name='city'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.city && formik.errors.city ? (
                                        <div className='text-danger'>{formik.errors.city}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    required=""
                                    placeholder='State'
                                    name='state'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.state}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.state && formik.errors.state ? (
                                        <div className='text-danger'>{formik.errors.state}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    required=""
                                    placeholder='Postal Code'
                                    name='postal'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.postal}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.postal && formik.errors.postal ? (
                                        <div className='text-danger'>{formik.errors.postal}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <select
                                    className="form-select"
                                    id="validationServerstate"
                                    aria-describedby=""
                                    required=""
                                    placeholder="Country"
                                    name='country'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.country}
                                >
                                    <option selected="" disabled="" value="">
                                        Country
                                    </option>
                                    <option>...</option>
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.postal && formik.errors.postal ? (
                                        <div className='text-danger'>{formik.errors.postal}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="complaint-details" className="form-label">
                                    Complaint in Detail
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="complaint-details"
                                    defaultValue=""
                                    required=""
                                    placeholder='Enter Complaint in Detail'
                                    name='complaint'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.complaint}
                                ></textarea>
                                <div className="help-block with-errors">
                                    {formik.touched.complaint && formik.errors.complaint ? (
                                        <div className='text-danger'>{formik.errors.complaint}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="" className="form-label">
                                    Upload Product image
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="upload-product-front" className="form-label upload-product-front">
                                    {/* Display the file name if available, otherwise show 'Front Image' */}
                                    <span>{fileName || 'Front Image'}</span>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="upload-product-front"
                                        accept=".jpg,.jpeg,.png"

                                        hidden
                                        onChange={e => {
                                            handleFrontImgFileChange(e);
                                            formik.handleChange(e);
                                        }}

                                        onBlur={formik.handleBlur}
                                        value={formik.values.prdctFrontImg}
                                    />
                                    <label htmlFor="upload-product-front" className="upload-product-icon">
                                        <img src="./images/register-your-complaint/upload-file.png" alt="" />
                                    </label>
                                </label>
                                <div className="help-block with-errors">
                                    {formik.touched.prdctFrontImg && formik.errors.prdctFrontImg ? (
                                        <div className='text-danger'>{formik.errors.prdctFrontImg}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="upload-product-back" className="form-label upload-product-back">
                                    <span>{fileBackName || 'Back Image'}</span>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="upload-product-back"
                                        accept=".jpg,.jpeg,.png"

                                        placeholder='Back Image' hidden
                                        onChange={e => {
                                            handleBackImgFileChange(e);
                                            formik.handleChange(e);
                                        }}

                                        onBlur={formik.handleBlur}
                                        value={formik.values.prdctBackImg}
                                    />
                                    <label htmlFor="upload-product-back" className="upload-product-icon">
                                        <img src="./images/register-your-complaint/upload-file.png" alt="" />
                                    </label>
                                </label>
                                <div className="help-block with-errors">
                                    {formik.touched.prdctBackImg && formik.errors.prdctBackImg ? (
                                        <div className='text-danger'>{formik.errors.prdctBackImg}</div>
                                    ) : null}
                                </div>
                            </div>


                            <div className="col-12 text-center">
                                <button className="btn submit-btn-custom" type="submit" disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <HelpAndSupport />
                {/* <div className='help-and-support-section-inner'>
                    <div className="container">
                        <div className="heading-dv">
                            <h5>Help & Suport</h5>
                        </div>
                        <div className="help-support-inner-section">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="tel:8650115000"><img src="../images/phone.png" alt="" />8650115000</a></p>
                                            <p><img src="../images/alarm.png" alt="" />09:00 AM to 06:00 PM </p>
                                        </div>
                                    </div>
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="https://api.whatsapp.com/send?phone=919068563156" target='_blank'><img src="../images/whatsapp.png" alt="" />+91-9068513651</a></p>
                                        </div>
                                    </div>
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="mailto:info@eapro.in"><img src="../images/mail.png" alt="" />info@eapro.in</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                   
                                    <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <a href="#"><img src="../images/register-product.png" alt="" />Register your product</a>
                                                <a href="#"><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <Link to="/purchase-amc"><img src="../images/buy-amc.png" alt="" />Extended Warranty</Link>
                                                <Link to="/purchase-amc"><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </>
    )
}

export default RegisterYourComplaintContent