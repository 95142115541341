import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getEaproLtdData, } from '../../Redux/Action/Action';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EaproGlobalLtdBanner from './EaproGlobalLtdBanner/EaproGlobalLtdBanner'
import EaproGlobalLtdContent from './EaproGlobalLtdContent/EaproGlobalLtdContent'
import EaproGlobalLtdForms from './EaproGlobalLtdContent/EaproGlobalLtdForms'

const EaproGlobalLtd = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEaproLtdData());

    }, []);

    const eaproLtdData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.eaproLtdDataList?.data) : []);

    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EaproGlobalLtdBanner eaproLtdData={eaproLtdData} />
            <EaproGlobalLtdContent eaproLtdData={eaproLtdData} />
            <EaproGlobalLtdForms />
            <Footer />
        </>
    )
}

export default EaproGlobalLtd