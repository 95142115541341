import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./HelpAndSupportContent.css"

import HelpAndSupport from '../../HomePageComponent/HelpAndSupport/HelpAndSupport'
import { Link } from 'react-router-dom'
import { getContactDetailsData } from '../../../Redux/Action/Action';
const HelpAndSupportContent = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContactDetailsData());
  }, []);

  const contactDetailsData = useSelector((state) => state?.Reducer?.contactDetailsDataList?.data || []);


  return (
    <>
      <section className="help-and-support-content-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="help-support-heading">
                <h5>Customer Support</h5>
              </div>
            </div>
            <div className="col-md-12">
              <div className="help-support-content-dv">
                <p>
                  At Eapro Care, our commitment shines through exceptional pre
                  and post-sales assistance. Reach out to us for unparalleled
                  customer support that goes beyond. We eagerly await your
                  connection, ready to listen and assist. Your satisfaction is
                  our pride.
                </p>
              </div>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-md-3 mb-md-0 mb-3">
              <div className="services-card-parent-yellow">
                <a
                  href="http://eaproerp.site:3006"
                  className="text-decoration-none text-dark"
                >
                  <div className="services-images">
                    <img
                      src="./images/help-and-support/customer-service.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="services-heading">
                    <p>
                      Service care{' '}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
              <div className="services-card-parent-black">
                <Link
                  to="/store-locator"
                  className="text-decoration-none text-light"
                >
                  <div className="services-images">
                    <img
                      src="./images/help-and-support/location.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="services-heading">
                    <p>
                      Store Locator{' '}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
              <div className="services-card-parent-yellow">
                <Link
                  to="/service-center-locator"
                  className="text-decoration-none text-dark"
                >
                  <div className="services-images">
                    <img
                      src="./images/help-and-support/service-center.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="services-heading">
                    <p>
                      Service center Locator{' '}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
              <div className="services-card-parent-black">
                <Link
                  to="/contact-us"
                  className="text-decoration-none text-light"
                >
                  <div className="services-images">
                    <img
                      src="./images/help-and-support/contact-mail.png"
                      className="img-fluid"
                    />
                  </div>
                  <div className="services-heading">
                    <p>
                      Contact Us{' '}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='help-and-support-section-inner'>
          <div className="container">
            <div className="heading-dv">
              <h5>Help & Suport</h5>
            </div>
            <div className="help-support-inner-section">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="first-card-parent">
                    <div className="mobile-tag">
                      <p><a href={`tel:${contactDetailsData?.mobile ? contactDetailsData?.mobile : "8650115000"}`}><img src="../images/phone.png" alt="" />{contactDetailsData?.mobile ? contactDetailsData?.mobile : "8650115000"}</a></p>
                      <p><img src="../images/alarm.png" alt="" />{contactDetailsData?.whatsapp ? contactDetailsData?.whatsapp : "09:00 AM to 06:00 PM"} </p>
                    </div>
                  </div>
                  {/* <div className="first-card-parent">
                    <div className="mobile-tag">
                      <p><a href="https://api.whatsapp.com/send?phone=919068563156" target='_blank'><img src="../images/whatsapp.png" />+91-9068513651</a></p>
                    </div>
                  </div> */}
                  <div className="first-card-parent">
                    <div className="mobile-tag">
                      <p><a href={`mailto:${contactDetailsData?.email ? contactDetailsData?.email : "info@eapro.in"}`}><img src="../images/mail.png" alt="" />{contactDetailsData?.email ? contactDetailsData?.email : "info@eapro.in"}</a></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="second-side-card-parent">
                    <div className="mobile-tag">
                      <p>
                        <a href="http://eaproerp.site:3006" target='blank'><img src="../images/book-complaint.png" alt="" />Book a complaint</a>
                        <a href="http://eaproerp.site:3006" target='blank'><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>

                      </p>
                    </div>
                  </div>
                  <div className="second-side-card-parent">
                    <div className="mobile-tag">
                      <p>
                        <a href="http://eaproerp.site:3006" target='blank'><img src="../images/register-product.png" alt="" />Register your product</a>
                        <a href="http://eaproerp.site:3006" target='blank'><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                      </p>
                    </div>
                  </div>
                  <div className="second-side-card-parent">
                    <div className="mobile-tag">

                      <p>
                        <a href="http://eaproerp.site:3006" target='blank'><img src="../images/buy-amc.png" alt="" />Extended Warranty</a>
                        <a href="http://eaproerp.site:3006" target='blank'><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HelpAndSupportContent