import React from 'react'
import "./ContactUsPageNewBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const ContactUsPageNewBanner = (contactDetailsData) => {

    return (
        <>
            <section className='contact-us-page-new-banner-section'>
                {/* <div className="container"> */}
                {/* <div className="row align-items-center"> */}
                {/* <div className="col-md-12"> */}
                <div className="banner-images-left ">
                    <img src={contactDetailsData?.contactDetailsData?.image ? `${host}/${contactDetailsData?.contactDetailsData?.image}` : ""} alt="" className='img-fluid w-100' />
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div> */}
                </div>
            </section>
        </>
    )
}

export default ContactUsPageNewBanner