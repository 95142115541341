import React, { useEffect } from 'react'

import './CsrContent.css'
import './ResponsiveCsrContent.css'
import CsrContentCard from './CsrContentCard'

const CsrContent = () => {


    return (
        <>

            <section className='csr-page-content-section'>
                <div className="container">
                    {/* ------------------------------------------------ */}

                    <div className="tab-content blog-tab-content" id="myTabContent">
                        <div className="tab-pane fade show active">

                            <CsrContentCard />
                        </div>

                    </div>
                    {/* ------------------------------------------------ */}

                </div>
            </section>
        </>
    )
}

export default CsrContent