import React from 'react'
import './Navbar.css';
import './ResponsiveNavbar.css';
import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'font-awesome/css/font-awesome.min.css';
import { Link } from 'react-router-dom';
const Navbar = () => {
    return (
        <>
            <section className='navbar-sec d-none d-md-none d-lg-block'>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <Link className="navbar-brand" to="/">
                            <img src="../images/logo.png" alt="" className='img-fluid' />
                        </Link>
                        <button
                            className="navbar-toggler d-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto navbar-nav-center-cus">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/about-us">
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="/business-division">
                                        Business Divisions
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="/help-and-support">
                                        Support
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="/knowledge-center">
                                        Knowledge Center
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to="/career-at-eapro">
                                        Career
                                    </Link>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Power Solution  <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link className="dropdown-item" to="/power-solution">Power Solution</Link></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Solar Solution <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link className="dropdown-item" to="/solar-solution">Solar Solution</Link></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/store-locator">
                                        Store Locator
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/download">
                                        Catalogue
                                    </Link>
                                </li> */}


                            </ul>
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item user-icon">
                                    <a className="nav-link" href="#">
                                        {/* <img src="../images/user.png" alt="" /> */}
                                    </a>
                                </li>
                                {/* <li className="nav-item cart-icon">
                                    <a className="nav-link" href="#">
                                        <img src="../images/cart.png" alt="" />
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>
        </>
    )
}

export default Navbar