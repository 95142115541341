import React from 'react'
import "./PurchaseAMCPage3Content.css"
const PurchaseAMCPage3Content = (productData) => {

    const data = productData?.productData
    let specs = [];
    if (data?.specification) {
        try {
            specs = JSON.parse(data.specification);
        } catch (error) {
            console.error("Failed to parse specifications:", error);

        }
    }
    let modelNumber = '';


    const modelTitles = ["Model No.", "Model number", "Model Number"];
    const modelSpec = specs.find(spec => modelTitles.includes(spec.title));
    modelNumber = modelSpec ? modelSpec.description : "Default Model Number";


    return (
        <>
            <section className='purchase-amc-page-3-content-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="purchase-amc-product-details-parent">
                                <div className="product-details-heading">
                                    <p>Product Details:</p>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>NAME</td>
                                                <td>{productData?.productData?.name}</td>
                                            </tr>
                                            <tr>
                                                <td>MODEL NO.</td>
                                                <td>{modelNumber}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="help-support-heading">
                                <h5>Select Your Plan</h5>
                            </div>
                        </div>

                    </div>
                    <div className="row select-plan-start-row">
                        <div className="col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-5">
                            <div className="select-you-plan-parent">
                                <div className="plan-inner-name-img">
                                    <img src="/images/purchase-amc/card-top.png" alt="" className='img-fluid' />
                                    <div className="plan-inner-heading">
                                        <p>START</p>
                                    </div>
                                </div>
                                <div className="plan-price-div">
                                    <h1><i className="fa fa-inr" aria-hidden="true"></i> 2000/-</h1>
                                </div>
                                <div className="plan-price-duration">
                                    <p>1 Year Plan</p>
                                </div>
                                <div className="plan-buy-now-btn">
                                    <a href="/purchase-amc1">Buy Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-5">
                            <div className="select-you-plan-parent">
                                <div className="plan-inner-name-img">
                                    <img src="/images/purchase-amc/card-top.png" alt="" className='img-fluid' />
                                    <div className="plan-inner-heading">
                                        <p>Pro</p>
                                    </div>
                                </div>
                                <div className="plan-price-div">
                                    <h1><i className="fa fa-inr" aria-hidden="true"></i> 3800/-</h1>
                                </div>
                                <div className="plan-price-duration">
                                    <p>2 Year Plan</p>
                                </div>
                                <div className="plan-buy-now-btn">
                                    <a href="#">Buy Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-5">
                            <div className="select-you-plan-parent">
                                <div className="plan-inner-name-img">
                                    <img src="/images/purchase-amc/card-top.png" alt="" className='img-fluid' />
                                    <div className="plan-inner-heading">
                                        <p>ULTIMATE</p>
                                    </div>
                                </div>
                                <div className="plan-price-div">
                                    <h1><i className="fa fa-inr" aria-hidden="true"></i> 5400/-</h1>
                                </div>
                                <div className="plan-price-duration">
                                    <p>3 Year Plan</p>
                                </div>
                                <div className="plan-buy-now-btn">
                                    <a href="#">Buy Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='help-and-support-section-inner'>
                    <div className="container">
                        {/* <div className="heading-dv">
                            <h5>Help & Suport</h5>
                        </div> */}
                        <div className="help-support-inner-section">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="tel:8650115000"><img src="../images/phone.png" alt="" />8650115000</a></p>
                                            <p><img src="../images/alarm.png" alt="" />09:00 AM to 06:00 PM </p>
                                        </div>
                                    </div>
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="https://api.whatsapp.com/send?phone=919068563156" target='_blank'><img src="../images/whatsapp.png" alt="" />+91-9068513651</a></p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    {/* <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <a href="#"><img src="../images/book-complaint.png" alt="" />Book a complaint</a>
                                                <a href="#"><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>

                                            </p>
                                        </div>
                                    </div> */}
                                    <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <a href="#"><img src="../images/register-product.png" alt="" />Register your product</a>
                                                <a href="#"><i className="fa fa-chevron-right" aria-hidden="true"></i><i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="mailto:info@eapro.in"><img src="../images/mail.png" alt="" />info@eapro.in</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PurchaseAMCPage3Content