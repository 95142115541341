import React from 'react'
import parse from 'html-react-parser';
import "./EventPageDetailsContent.css"
const EventPageDetailsContent = (newsData) => {

    const url = newsData?.newsData?.link
    const videoId = url?.split('/')[3]?.split('?')[0];

    // Construct the embed URL format
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;


    return (
        <>
            <section className='blog-details-con-page-content-section'>
                {newsData?.newsData ? <div className="container">
                    <div className="blog-details-inner">
                        {newsData?.newsData?.summary ? <b> {parse(newsData?.newsData?.summary)}</b> :
                            ""}
                    </div>
                    {newsData?.newsData?.link &&
                        <div className="blog-details-inner-video">
                            <iframe width="100%" height="391" src={newsData?.newsData?.link ? `${embedUrl}` : "https://www.youtube.com/embed/FFBnPT1Z5uA"} title={newsData?.newsData?.title ? newsData?.newsData?.title : "EAPRO Industrial Visit by Teerthanker Mahaveer University"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>}
                    {newsData?.newsData?.description ? parse(newsData?.newsData?.description) :
                        ""}

                </div>
                    :
                    ""
                }
            </section >
        </>
    )
}

export default EventPageDetailsContent