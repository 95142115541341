import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import KnowledgeCentrePageBanner from './KnowledgeCentrePageBanner/KnowledgeCentrePageBanner'
import KnowledgeCentrePageContent from './KnowledgeCentrePageContent/KnowledgeCentrePageContent'
import KnowledgeCentrePageVideoTuto from './KnowledgeCentrePageContent/KnowledgeCentrePageVideoTuto'
import KnowledgeCentrePageNewsElement from './KnowledgeCentrePageContent/KnowledgeCentrePageNewsElement'
import KnowledgeCenterPageBlogs from './KnowledgeCentrePageContent/KnowledgeCenterPageBlogs'
import { getAllBannerData } from '../../Redux/Action/Action';

const KnowledgeCentrePage = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);


    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <KnowledgeCentrePageBanner bannerData={bannerData} />
            <KnowledgeCentrePageVideoTuto bannerData={bannerData} />
            <KnowledgeCenterPageBlogs bannerData={bannerData} />
            <KnowledgeCentrePageNewsElement bannerData={bannerData} />
            <Footer />
        </>
    )
}

export default KnowledgeCentrePage