import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import PurchaseAMCPage3Banner from './PurchaseAMCPage3Banner/PurchaseAMCPage3Banner'
import PurchaseAMCPage3Content from './PurchaseAMCPage3Content/PurchaseAMCPage3Content'
import { getAllBannerData, getProductData } from '../../Redux/Action/Action';
import PurchaseAMCBanner from '../PurchaseAMCPage/PurchaseAMCBanner/PurchaseAMCBanner';
import { useParams } from 'react-router-dom';
const PurchaseAMCPage3 = () => {
    const dispatch = useDispatch();
    const { name } = useParams();

    useEffect(() => {

        dispatch(getProductData(name))

    }, [name]);
    useEffect(() => {

        dispatch(getAllBannerData());


    }, []);


    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);
    const productData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.productDataList?.data) : []);

    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            {/* <PurchaseAMCPage3Banner bannerData={bannerData} /> */}

            <PurchaseAMCBanner bannerData={bannerData} />
            <PurchaseAMCPage3Content productData={productData} />
            <Footer />
        </>
    )
}

export default PurchaseAMCPage3