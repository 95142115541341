import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EaproGlobalEnergyBanner from './EaproGlobalEnergyBanner/EaproGlobalEnergyBanner'
import EaproGlobalEnergyContent from './EaproGlobalEnergyContent/EaproGlobalEnergyContent'
import EaproGlobalEnergyForms from './EaproGlobalEnergyContent/EaproGlobalEnergyForms'
import { getEaproEnergyData } from '../../Redux/Action/Action';

const EaproGlobalEnergy = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEaproEnergyData());

    }, []);

    const eaproEnergyData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.eaproEnergyDataList?.data) : []);

    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EaproGlobalEnergyBanner eaproEnergyData={eaproEnergyData} />
            <EaproGlobalEnergyContent eaproEnergyData={eaproEnergyData} />
            <EaproGlobalEnergyForms />
            <Footer />
        </>
    )
}

export default EaproGlobalEnergy