import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser'
import { useLocation, useParams } from 'react-router-dom';
import "./DirectorMessagePage.css"
import { getFounderDirectorDataParams } from '../../Redux/Action/Action';

const host = process.env.REACT_APP_API_SERVER;
const DirectorMessagePage = () => {
    const dispatch = useDispatch();
    const { name } = useParams();



    useEffect(() => {
        dispatch(getFounderDirectorDataParams(name));
    }, []);


    const data = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.founderDirectorDataParamsList?.data) : []);


    return (
        <>
            <section className='director-message-page-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <div className="director-image">
                                <img src={data?.image ? `${host}/${data?.image}` : ""} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-9 text-start mt-3">
                            <div className="director-image-name-post">
                                <h5 className=''><b>{data?.name}</b></h5>
                                <p>({data?.designation})</p>
                            </div>
                            <div className="director-msg-para">
                                {data?.desc1 ? parse(data.desc1) : ''}
                            </div>

                        </div>
                        <div className='col-md-12 text-start mt-3'>
                            <div className="director-msg-para">
                                {data?.desc2 ? parse(data.desc2) : ''}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DirectorMessagePage