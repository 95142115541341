import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'

import { getAllBannerData } from '../../Redux/Action/Action';
import ECatalogueBannerNew from './ECataloguePageContent/ECatalogueBannerNew';
import ECataloguePageContent from './ECataloguePageContent/ECataloguePageContent';

const ECataloguePage = () => {



    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            {/* <ECataloguePageBanner bannerData={bannerData} /> */}
            <ECatalogueBannerNew />
            <ECataloguePageContent />
            <Footer />
        </>
    )
}

export default ECataloguePage