import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import PrivacyPolicyPageContent from './PrivacyPolicyPageContent'
import { getCmsData } from '../../Redux/Action/Action';

const PrivacyPolicyPage = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { name } = useParams();



    const dispatch = useDispatch();

    useEffect(() => {

        if (!name) {
            toast.error("No such policy.");


            navigate(-1)
        } else {

            dispatch(getCmsData(name));

        }

    }, [name]);

    const CmsData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.CmsDataList?.data) : []);


    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <PrivacyPolicyPageContent CmsData={CmsData} />
            <Footer />
        </>
    )
}

export default PrivacyPolicyPage