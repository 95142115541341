import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import Navbar from '../../../Header/Navbar'
import NavbarSecond from '../../../Header/NavbarSecond'
import MobileNavbar from '../../../Header/MobileNavbar'
import Footer from '../../../Footer/Footer'
import PillarVideosDetailsContent from './PillarVideosContent/PillarVideosDetailsContent';
import { useParams } from 'react-router-dom';
import { getPillarsVideoData } from '../../../../Redux/Action/Action';
import PillarVideosDetailsBanner from './PillarVideoDetailsBanner/PillarVideosDetailsBanner';

const PillarVideoDetails = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        if (!name) {
            toast.error("No such pillar");
            navigate(-1)
        } else {


            dispatch(getPillarsVideoData(name));
        }
    }, []);




    const { name } = useParams();
    const navigate = useNavigate();



    const pillarsVideoData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.pillarsVideoDataList?.data) : []);
    const Banner = pillarsVideoData?.banner


    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <PillarVideosDetailsBanner Banner={Banner} />
            <PillarVideosDetailsContent pillarsVideoData={pillarsVideoData} />
            <Footer />
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </>
    )
}

export default PillarVideoDetails