
import { React, useEffect } from 'react'
import Navbar from '../Header/Navbar'
import MobileNavbar from '../Header/MobileNavbar'
import NavbarSecond from '../Header/NavbarSecond'
import Footer from '../Footer/Footer'
import CsrContent from './CsrContent/CsrContent'
import CsrBanner from './CsrBanner/CsrBanner'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { getAllBannerData } from '../../Redux/Action/Action'
const Csr = () => {
    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);

    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CsrBanner bannerData={bannerData} />
            <CsrContent />
            <Footer />
        </>
    )
}

export default Csr