import React from 'react'
import "./ViewProductDetailsContent.css"
import parse from 'html-react-parser'
import { Link, useNavigate } from 'react-router-dom'
const host = process.env.REACT_APP_API_SERVER;
const ViewProductDetailsContent = (childCategoryData) => {
    const navigate = useNavigate();
    const products = childCategoryData?.childCategoryData
    const handleClick = (name) => {

        if (name !== undefined) {
            navigate(`/view-product-series-listing/${name}`)
        }
    }
    return (
        <>
            {products?.map((product, index) => (
                index % 2 === 0 ? (

                    <section className="power-product-custom-product-2" key={product.id}>
                        <div className="bg-graphic-center">
                            <img src="/images/power-solution/bg-graphic-3.png" className="img-fluid" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <h4 className="text-center">{product?.SubCategoryy?.name ? product?.SubCategoryy?.name : "Pure Sine Wave"}</h4>
                                <div className="col-lg-6 offset-lg-1  mb-lg-0 mb-5">
                                    <div className="product-info">
                                        <h2>{product?.name ? product?.name.toUpperCase() : "BOOST SERIES"}</h2>
                                        <p>{product?.description ? parse(product?.description) : ""}</p>
                                        <button onClick={() => { handleClick(product?.slug) }} className="view-all-btn">View All Product</button>
                                    </div>
                                </div>
                                <div className="col-lg-4 offset-lg-1">
                                    <div className="product-img">
                                        <img src={product?.image ? `${host}/${product?.image}` : "./images/power-solution/product-one.png"} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    // Odd-indexed products (1, 3, 5, ...)
                    <section className="power-product-custom-product-1" key={product.id}>
                        <div className="bg-graphic-right-top">
                            <img src="/images/power-solution/bg-graphic-1.png" className="img-fluid" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <h4 className="text-center">{product?.SubCategoryy?.name}</h4>
                                <div className="col-lg-4 mb-lg-0 mb-5">
                                    <div className="product-img">
                                        <img src={product?.image ? `${host}/${product?.image}` : "./images/power-solution/product-one.png"} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-6 offset-lg-1">
                                    <div className="product-info">
                                        <h2>{product.name}</h2>
                                        <p>{product.description ? parse(product.description) : ""}</p>
                                        <button onClick={() => { handleClick(product?.slug) }} className="view-all-btn">View All Product</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-graphic-left-bottom">
                            <img src="/images/power-solution/bg-graphic-2.png" className="img-fluid" />
                        </div>
                    </section>
                )
            ))}
        </>
    )
}

export default ViewProductDetailsContent