import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./KnowledgeCentrePageContent.css"
import $ from 'jquery';
import { getProductVideoData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const JavascriptVoid = (event) => {
    event.preventDefault();
};
function pauseVideo() {

    let videoId = document.getElementById("iframeyt");
    videoId.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*')
}
const Slider_group = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    arrows: true,
    swipe: true,
    centerMode: true,
    centerPadding: '10px',
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0,
                dots: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                centerMode: false,
                centerPadding: '0',
                dots: false,
            }
        }
    ]
};
const KnowledgeCentrePageVideoTuto = (bannerData) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductVideoData());

    }, []);

    const productVideoData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.productVideoDataList?.data) : []);
    const [currentVideoUrl, setCurrentVideoUrl] = useState("");

    const updateVideoUrl = (url) => {
        const videoId = url.split('/')[3].split('?')[0];

        // Construct the embed URL format
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;

        // Update the current video URL state with the new embed URL
        setCurrentVideoUrl(embedUrl);
    };


    return (
        <>
            {productVideoData?.length != "0" ?
                <section className='knowledge-center-page-video-tuto-slider-section'>
                    <div className="container">
                        <div className="heading-dv">
                            <h5>Video Tutorials</h5>
                        </div>
                        <div className="heading-tabs-parent">
                            {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#technical-video" type="button" role="tab" aria-controls="home" aria-selected="true">Product video</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link blogs-btn" id="profile-tab" data-bs-toggle="tab" data-bs-target="#blogs-tabs" type="button" role="tab" aria-controls="profile" aria-selected="false">Blogs</button>
                            </li>
                        </ul> */}
                            {/* <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="technical-video" role="tabpanel" aria-labelledby="home-tab">

                                <div className="footer-view-all-btn">
                                    <a href="#">View All</a>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="blogs-tabs" role="tabpanel" aria-labelledby="profile-tab">
                                ...........
                            </div>
                        </div> */}
                            <Slider {...Slider_group} className='slider-parent-dv'>
                                {productVideoData?.map((data) => (
                                    <div className='slider-item-dv'>
                                        <div className="slider-item-img" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ cursor: "pointer" }} onClick={() => updateVideoUrl(data?.link)}>
                                            <img src={data?.image ? `${host}/${data?.image}` : ""} alt="" className='img-fluid' />
                                            <div className="play-btn-youtube">
                                                <a href="#" >
                                                    <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="slider-item-heading">
                                            <h4>{data?.title} </h4>
                                            <p>{data?.subtitle}</p>
                                            {data?.url && <p>Know more: <a className='text-decoration-none text-light' href={data?.url}>{data?.url}</a></p>}
                                            {data?.phone1 && <p>Call: <a className='text-decoration-none text-light' href={`tel:${data?.phone1}`}>{data?.phone1}</a></p>}
                                            {data?.phone2 && <p>Customer care:  <a className='text-decoration-none text-light' href={`tel:${data?.phone2}`}>{data?.phone2}</a></p>}
                                        </div>
                                    </div>
                                ))}

                            </Slider>
                        </div>

                    </div>
                </section>
                :
                ""
            }

            {/* ================================modal start here============================ */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Video Heading</h5>
                            <button type="button" onClick={pauseVideo} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <iframe id='iframeyt' className='yt_player_iframe' width="100%" height="400" src={currentVideoUrl} title="PWM vs MPPT - Which one to choose for your application?" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Understood</button>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* ================================modal end here============================== */}
        </>
    )
}

export default KnowledgeCentrePageVideoTuto