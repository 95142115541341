import React from 'react'
import parse from 'html-react-parser';
import "./CsrDetailsContent.css"
import Csr from '../../Csr';
const CsrDetailsContent = (CsrOneData) => {

    const url = CsrOneData?.CsrOneData?.link
    const videoId = url?.split('/')[3]?.split('?')[0];

    // Construct the embed URL format
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <>
            {CsrOneData?.CsrOneData !== "" ?
                <section className='csr-details-con-page-content-section'>
                    <div className="container">
                        <div className="blog-details-inner">
                            {CsrOneData?.CsrOneData?.summary ? <b> {parse(CsrOneData?.CsrOneData?.summary)}</b> :
                                <p> <b>In the ever-evolving landscape of power generation and distribution, technological advancements continue to shape the way we harness and utilize electricity. One such innovation that stands out for its precision and efficiency is Pure Sine Wave Technology. In this blog post, we'll delve into the intricacies of this technology, exploring its applications, benefits, and why it has become a cornerstone in various industries.</b></p>}
                        </div>
                        {CsrOneData?.CsrOneData?.link &&
                            <div className="blog-details-inner-video">
                                <iframe width="100%" height="391" src={CsrOneData?.CsrOneData?.link ? `${embedUrl}` : "https://www.youtube.com/embed/FFBnPT1Z5uA"} title={CsrOneData?.CsrOneData?.title ? CsrOneData?.CsrOneData?.title : "EAPRO Industrial Visit by Teerthanker Mahaveer University"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>}
                        {CsrOneData?.CsrOneData?.description ? parse(CsrOneData?.CsrOneData?.description) :
                            ""}

                    </div>

                </section >
                :
                ""}
        </>
    )
}

export default CsrDetailsContent