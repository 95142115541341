import React from 'react'
import "./PowerSolutionHomeInverter.css"
import { useNavigate, Link } from 'react-router-dom';
import parse from 'html-react-parser'
const host = process.env.REACT_APP_API_SERVER;

const PowerSolutionHomeInverter = (data) => {
    const navigate = useNavigate();
    const cdata = data?.data

    const handleClick = (name) => {

        if (name !== undefined) {
            navigate(`/view-product/${name}`)
        }
    }

    return (
        <>
            <section className='power-solution-home-inverter-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="specialization-heading-dv">
                                <h5>{cdata?.name ? cdata?.name : ""}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="left-images">
                                <img src={cdata?.image ? `${host}${cdata?.image}` : ""} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="right-content-parent">
                                <p>{cdata?.description ? parse(cdata?.description) : ""}</p>
                                <div className="row">
                                    <div className="col-md-6 mb-lg-0 mb-md-0 mb-3">
                                        <div className="img-content-dv">
                                            <img src={cdata?.image ? `${host}${cdata?.tile1image}` : ""} alt="" className='img-fluid' />
                                            <p>{cdata?.tile1 ? cdata?.tile1 : ""}</p>
                                        </div>
                                        <div className="img-content-dv mt-4">
                                            <img src={cdata?.image ? `${host}${cdata?.tile2image}` : ""} alt="" className='img-fluid' />
                                            <p>{cdata?.tile2 ? cdata?.tile2 : ""}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-lg-0 mb-md-0 mb-3">
                                        <div className="img-content-dv">
                                            <img src={cdata?.image ? `${host}${cdata?.tile3image}` : ""} alt="" className='img-fluid' />
                                            <p>{cdata?.tile3 ? cdata?.tile3 : ""}</p>
                                        </div>
                                        <div className="img-content-dv mt-4">
                                            <img src={cdata?.image ? `${host}${cdata?.tile4image}` : ""} alt="" className='img-fluid' />
                                            <p>{cdata?.tile4 ? cdata?.tile4 : ""}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-start">
                                        <div className="view-product-learn-more">
                                            <button onClick={() => { handleClick(cdata?.slug) }} className='view-product-btn ms-0'>View Product</button>
                                            {/* <Link to="/learn-more" className='view-product-btn learn-more-btn'>Learn More</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default PowerSolutionHomeInverter