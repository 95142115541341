import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EAPROOverseasBanner from './EAPROOverseasBanner/EAPROOverseasBanner'
import EAPROOverseasContent from './EAPROOverseasContent/EAPROOverseasContent'
import EAPROOverseasForms from './EAPROOverseasContent/EAPROOverseasForms'
import { getEaproOverseasData } from '../../Redux/Action/Action';

const EAPROOverseas = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEaproOverseasData());

    }, []);

    const eaproOverseasData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.eaproOverseasDataList?.data) : []);

    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EAPROOverseasBanner eaproOverseasData={eaproOverseasData} />
            <EAPROOverseasContent eaproOverseasData={eaproOverseasData} />
            <EAPROOverseasForms />
            <Footer />
        </>
    )
}

export default EAPROOverseas