

let host = process.env.REACT_APP_API_SERVER;

export const getAboutUsData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-eapro-group`, { method: "GET" })
            .then((res) => {
                res.json().then((aboutUsData) => {
                    dispatch(setAboutUsData(aboutUsData));
                    // console.log("action AboutUsBanner data", aboutUsData);
                })
            }).catch((err) => {
                console.log("ERROR in getAboutUsData function(redux):", err.message, err);
            })
    }
}

export const setAboutUsData = (aboutUsData) => {
    if (aboutUsData) {
        return {
            type: "SET_ABOUT_US_DATA",
            payload: aboutUsData,
        }
    }
}

export const getOurSpecializationData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-our-specialization`, { method: "GET" })
            .then((res) => {
                res.json().then((ourSpecializationData) => {
                    dispatch(setOurSpecializationData(ourSpecializationData));
                    // console.log("action ourSpecializationBanner data", ourSpecializationData);
                })
            }).catch((err) => {
                console.log("ERROR in getOurSpecializationData function(redux):", err.message, err);
            })
    }
}

export const setOurSpecializationData = (ourSpecializationData) => {
    if (ourSpecializationData) {
        return {
            type: "SET_OUR_SPECIALIZATION_DATA",
            payload: ourSpecializationData,
        }
    }
}

export const getEaproLtdData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-eapro-group-ltd`, { method: "GET" })
            .then((res) => {
                res.json().then((eaproLtdData) => {
                    dispatch(setEaproLtdData(eaproLtdData));
                    // console.log("action EaproLtdBanner data", eaproLtdData);
                })
            }).catch((err) => {
                console.log("ERROR in getEaproLtdData function(redux):", err.message, err);
            })
    }
}

export const setEaproLtdData = (eaproLtdData) => {
    if (eaproLtdData) {
        return {
            type: "SET_EAPRO_LTD_DATA",
            payload: eaproLtdData,
        }
    }
}

export const getEaproEnergyData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-eapro-group-Energy`, { method: "GET" })
            .then((res) => {
                res.json().then((eaproEnergyData) => {
                    dispatch(setEaproEnergyData(eaproEnergyData));
                    // console.log("action EaproEnergyBanner data", eaproEnergyData);
                })
            }).catch((err) => {
                console.log("ERROR in getEaproEnergyData function(redux):", err.message, err);
            })
    }
}

export const setEaproEnergyData = (eaproEnergyData) => {
    if (eaproEnergyData) {
        return {
            type: "SET_EAPRO_ENERGY_DATA",
            payload: eaproEnergyData,
        }
    }
}

export const getEaproOverseasData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-eapro-group-overseas`, { method: "GET" })
            .then((res) => {
                res.json().then((eaproOverseasData) => {
                    dispatch(setEaproOverseasData(eaproOverseasData));
                    // console.log("action EaproOverseasBanner data", eaproOverseasData);
                })
            }).catch((err) => {
                console.log("ERROR in getEaproOverseasData function(redux):", err.message, err);
            })
    }
}

export const setEaproOverseasData = (eaproOverseasData) => {
    if (eaproOverseasData) {
        return {
            type: "SET_EAPRO_OVERSEAS_DATA",
            payload: eaproOverseasData,
        }
    }
}

export const getEfficientMechanicalData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-efficient-mechanical-solar`, { method: "GET" })
            .then((res) => {
                res.json().then((efficientMechanicalData) => {
                    dispatch(setEfficientMechanicalData(efficientMechanicalData));
                    // console.log("action efficientMechanicalBanner data", efficientMechanicalData);
                })
            }).catch((err) => {
                console.log("ERROR in getEfficientMechanicalData function(redux):", err.message, err);
            })
    }
}

export const setEfficientMechanicalData = (efficientMechanicalData) => {
    if (efficientMechanicalData) {
        return {
            type: "SET_EFFICIENT_MECHANICAL_DATA",
            payload: efficientMechanicalData,
        }
    }
}

export const getTrcEfficientData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-trc-efficient-services`, { method: "GET" })
            .then((res) => {
                res.json().then((trcEfficientData) => {
                    dispatch(setTrcEfficientData(trcEfficientData));
                    // console.log("action trcEfficientBanner data", trcEfficientData);
                })
            }).catch((err) => {
                console.log("ERROR in getTrcEfficientData function(redux):", err.message, err);
            })
    }
}

export const setTrcEfficientData = (trcEfficientData) => {
    if (trcEfficientData) {
        return {
            type: "SET_TRC_EFFICIENT_DATA",
            payload: trcEfficientData,
        }
    }
}

export const getHomeBannerData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-home-banners`, { method: "GET" })
            .then((res) => {
                res.json().then((homeBannerData) => {
                    dispatch(setHomeBannerData(homeBannerData));
                    // console.log("action homeBannerBanner data", homeBannerData);
                })
            }).catch((err) => {
                console.log("ERROR in gethomeBannerData function(redux):", err.message, err);
            })
    }
}

export const setHomeBannerData = (homeBannerData) => {
    if (homeBannerData) {
        return {
            type: "SET_HOME_BANNER_DATA",
            payload: homeBannerData,
        }
    }
}

export const getPillarsData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-pillars-eapro`, { method: "GET" })
            .then((res) => {
                res.json().then((pillarsData) => {
                    dispatch(setPillarsData(pillarsData));
                    // console.log("action pillarsBanner data", pillarsData);
                })
            }).catch((err) => {
                console.log("ERROR in getpillarsData function(redux):", err.message, err);
            })
    }
}

export const setPillarsData = (pillarsData) => {
    if (pillarsData) {
        return {
            type: "SET_PILLARS_DATA",
            payload: pillarsData,
        }
    }
}

export const getPillarsParamsData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-pillars-params-eapro?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((pillarsParamsData) => {
                    dispatch(setPillarsParamsData(pillarsParamsData));
                    // console.log("action pillarsParamsBanner data", pillarsParamsData);
                })
            }).catch((err) => {
                console.log("ERROR in getpillarsParamsData function(redux):", err.message, err);
            })
    }
}

export const setPillarsParamsData = (pillarsParamsData) => {
    if (pillarsParamsData) {
        return {
            type: "SET_PILLARS_PARAMS_DATA",
            payload: pillarsParamsData,
        }
    }
}

export const getPillarsVideoData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-pillars-video-eapro?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((pillarsVideoData) => {
                    dispatch(setPillarsVideoData(pillarsVideoData));
                    // console.log("action pillarsVideoBanner data", pillarsVideoData);
                })
            }).catch((err) => {
                console.log("ERROR in getpillarsVideoData function(redux):", err.message, err);
            })
    }
}

export const setPillarsVideoData = (pillarsVideoData) => {
    if (pillarsVideoData) {
        return {
            type: "SET_PILLARS_VIDEO_DATA",
            payload: pillarsVideoData,
        }
    }
}



export const getProductVideoData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-product-video`, { method: "GET" })
            .then((res) => {
                res.json().then((productVideoData) => {
                    dispatch(setProductVideoData(productVideoData));
                    // console.log("action productVideoBanner data", productVideoData);
                })
            }).catch((err) => {
                console.log("ERROR in getProductVideoData function(redux):", err.message, err);
            })
    }
}

export const setProductVideoData = (productVideoData) => {
    if (productVideoData) {
        return {
            type: "SET_PRODUCTVIDEO_DATA",
            payload: productVideoData,
        }
    }
}

export const getBusinessDivisionData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/business-division`, { method: "GET" })
            .then((res) => {
                res.json().then((businessDivisionData) => {
                    dispatch(setBusinessDivisionData(businessDivisionData));
                    // console.log("action businessDivisionBanner data", businessDivisionData);
                })
            }).catch((err) => {
                console.log("ERROR in getBusinessDivisionData function(redux):", err.message, err);
            })
    }
}

export const setBusinessDivisionData = (businessDivisionData) => {
    if (businessDivisionData) {
        return {
            type: "SET_BUSINESS_DIVISION_DATA",
            payload: businessDivisionData,
        }
    }
}

export const getHomeSecondSecData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-home-second-section`, { method: "GET" })
            .then((res) => {
                res.json().then((homeSecondSecData) => {
                    dispatch(setHomeSecondSecData(homeSecondSecData));
                    // console.log("action homeSecondSecBanner data", homeSecondSecData);
                })
            }).catch((err) => {
                console.log("ERROR in getHomeSecondSecData function(redux):", err.message, err);
            })
    }
}

export const setHomeSecondSecData = (homeSecondSecData) => {
    if (homeSecondSecData) {
        return {
            type: "SET_HOME_SECOND_SECTION_DATA",
            payload: homeSecondSecData,
        }
    }
}

export const getWhyEaproData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-why-eapro`, { method: "GET" })
            .then((res) => {
                res.json().then((whyEaproData) => {
                    dispatch(setWhyEaproData(whyEaproData));
                    // console.log("action whyEaproBanner data", whyEaproData);
                })
            }).catch((err) => {
                console.log("ERROR in getWhyEaproData function(redux):", err.message, err);
            })
    }
}

export const setWhyEaproData = (whyEaproData) => {
    if (whyEaproData) {
        return {
            type: "SET_WHY_EAPRO_DATA",
            payload: whyEaproData,
        }
    }
}

export const getCatalogueData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/catalogue-category?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((catalogueData) => {
                    dispatch(setCatalogueData(catalogueData));
                    console.log("action catalogueBanner data", catalogueData);
                })
            }).catch((err) => {
                console.log("ERROR in getCatalogueData function(redux):", err.message, err);
            })
    }
}

export const setCatalogueData = (catalogueData) => {
    if (catalogueData) {
        return {
            type: "SET_CATALOGUE_DATA",
            payload: catalogueData,
        }
    }
}

export const getCatalogueCategoryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/catalogue-on-category`, { method: "GET" })
            .then((res) => {
                res.json().then((catalogueCategoryData) => {
                    dispatch(setCatalogueCategoryData(catalogueCategoryData));
                    // console.log("action catalogueCategoryBanner data", catalogueCategoryData);
                })
            }).catch((err) => {
                console.log("ERROR in getCatalogueCategoryData function(redux):", err.message, err);
            })
    }
}

export const setCatalogueCategoryData = (catalogueCategoryData) => {
    if (catalogueCategoryData) {
        return {
            type: "SET_CATALOGUE_CAT_DATA",
            payload: catalogueCategoryData,
        }
    }
}

export const getLifeAtEaproData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/life-at-eapro`, { method: "GET" })
            .then((res) => {
                res.json().then((lifeAtEaproData) => {
                    dispatch(setLifeAtEaproData(lifeAtEaproData));
                    // console.log("action lifeAtEaproBanner data", lifeAtEaproData);
                })
            }).catch((err) => {
                console.log("ERROR in getLifeAtEaproData function(redux):", err.message, err);
            })
    }
}

export const setLifeAtEaproData = (lifeAtEaproData) => {
    if (lifeAtEaproData) {
        return {
            type: "SET_LIFE_AT_EAPRO_DATA",
            payload: lifeAtEaproData,
        }
    }
}


export const getStoreLocatorData = (searchTerm) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-store-locator?search=${searchTerm}`, { method: "GET" })
            .then((res) => {
                res.json().then((storeLocatorData) => {
                    dispatch(setStoreLocatorData(storeLocatorData));
                    // console.log("action storeLocatorBanner data", storeLocatorData);
                })
            }).catch((err) => {
                console.log("ERROR in getStoreLocatorData function(redux):", err.message, err);
            })
    }
}

export const setStoreLocatorData = (storeLocatorData) => {
    if (storeLocatorData) {
        return {
            type: "SET_STORE_LOCATOR_DATA",
            payload: storeLocatorData,
        }
    }
}

export const getServiceCenterLocatorData = (searchTerm) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-service-center-locator?search=${searchTerm}`, { method: "GET" })
            .then((res) => {
                res.json().then((serviceCenterLocatorData) => {
                    dispatch(setServiceCenterLocatorData(serviceCenterLocatorData));
                    // console.log("action storeLocatorBanner data", serviceCenterLocatorData);
                })
            }).catch((err) => {
                console.log("ERROR in getServiceCenterLocatorData function(redux):", err.message, err);
            })
    }
}

export const setServiceCenterLocatorData = (serviceCenterLocatorData) => {

    if (serviceCenterLocatorData) {
        return {
            type: "SET_SERVICE_CENTER_LOCATOR_DATA",
            payload: serviceCenterLocatorData,
        }
    }
}




// export const getServiceCenterLocatorData = (searchTerm) => {
//     return async function (dispatch) {
//         await fetch(`http://103.225.205.131:2227/api/EaproWebsiteLocation/EaproWebsiteLocationRoot?search=${searchTerm}&flag=0`, {
//             method: "GET",
//             headers: {
//                 'Content-Type': 'application/json',
//                 "auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZWNydWl0ZXIiOnsiaWQiOjJ9LCJpYXQiOjE3MDcyOTIxMjB9.Z71BxkSC7myleSYfBBP8yFJh_BQxrpzURBYvyS2456I",
//                 "Authorization": "Basic ZWdlcGwmdGVzcGw6ZWdlcGwmdGVzcGwhQCMyMDIyLTIz"
//             }
//         })
//             .then((res) => {
//                 res.json().then((serviceCenterLocatorData) => {
//                     dispatch(setServiceCenterLocatorData(serviceCenterLocatorData));
//                     // console.log("action serviceCenterLocatorBanner data", serviceCenterLocatorData);
//                 })
//             }).catch((err) => {
//                 console.log("ERROR in getServiceCenterLocatorData function(redux):", err.message, err);
//             })
//     }
// }

// export const setServiceCenterLocatorData = (serviceCenterLocatorData) => {
//     if (serviceCenterLocatorData) {
//         return {
//             type: "SET_SERVICE_CENTER_LOCATOR_DATA",
//             payload: serviceCenterLocatorData,
//         }
//     }
// }


// export const getServiceCenterLocatorData = (searchTerm) => {
//     return async function (dispatch) {
//         await fetch(`http://103.225.205.131:2227/api/EaproWebsiteLocation/EaproWebsiteLocationRoot?search=${searchTerm}&flag=0`, {
//             method: "GET",
//             headers: {
//                 'Content-Type': 'application/json',
//                 "auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZWNydWl0ZXIiOnsiaWQiOjJ9LCJpYXQiOjE3MDcyOTIxMjB9.Z71BxkSC7myleSYfBBP8yFJh_BQxrpzURBYvyS2456I",
//                 "Authorization": "Basic ZWdlcGwmdGVzcGw6ZWdlcGwmdGVzcGwhQCMyMDIyLTIz"
//             }
//         })
//             .then((res) => {
//                 res.json().then((serviceCenterLocatorData) => {
//                     dispatch(setServiceCenterLocatorData(serviceCenterLocatorData));
//                     // console.log("action serviceCenterLocatorBanner data", serviceCenterLocatorData);
//                 })
//             }).catch((err) => {
//                 console.log("ERROR in getServiceCenterLocatorData function(redux):", err.message, err);
//             })
//     }
// }

// export const setServiceCenterLocatorData = (serviceCenterLocatorData) => {
//     if (serviceCenterLocatorData) {
//         return {
//             type: "SET_SERVICE_CENTER_LOCATOR_DATA",
//             payload: serviceCenterLocatorData,
//         }
//     }
// }


export const getBlogData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-blog`, { method: "GET" })
            .then((res) => {
                res.json().then((blogData) => {
                    dispatch(setBlogData(blogData));
                    // console.log("action blogBanner data", blogData);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogData function(redux):", err.message, err);
            })
    }
}

export const setBlogData = (blogData) => {
    if (blogData) {
        return {
            type: "SET_BLOG_DATA",
            payload: blogData,
        }
    }
}

export const getBlogParamData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-blog-on-params?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((BlogParamData) => {
                    dispatch(setBlogParamData(BlogParamData));
                    // console.log("action catalogueBanner data", BlogParamData);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogParamData function(redux):", err.message, err);
            })
    }
}

export const setBlogParamData = (BlogParamData) => {
    if (BlogParamData) {
        return {
            type: "SET_BLOG_PARAM_DATA",
            payload: BlogParamData,
        }
    }
}

export const getNewsParamData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-news-on-params?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((NewsParamData) => {
                    dispatch(setNewsParamData(NewsParamData));
                    // console.log("action NewsParamData data", NewsParamData);
                })
            }).catch((err) => {
                console.log("ERROR in getNewsParamData function(redux):", err.message, err);
            })
    }
}

export const setNewsParamData = (NewsParamData) => {
    if (NewsParamData) {
        return {
            type: "SET_NEWS_PARAM_DATA",
            payload: NewsParamData,
        }
    }
}

export const getBlogCategoryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-blog-category`, { method: "GET" })
            .then((res) => {
                res.json().then((blogCategoryData) => {
                    dispatch(setBlogCategoryData(blogCategoryData));
                    // console.log("action blogCategoryBanner data", blogCategoryData);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogCategoryData function(redux):", err.message, err);
            })
    }
}

export const setBlogCategoryData = (blogCategoryData) => {
    if (blogCategoryData) {
        return {
            type: "SET_BLOG_CATEGORY_DATA",
            payload: blogCategoryData,
        }
    }
}

export const getNewsData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-news`, { method: "GET" })
            .then((res) => {
                res.json().then((newsData) => {
                    dispatch(setNewsData(newsData));
                    // console.log("action newsBanner data", newsData);
                })
            }).catch((err) => {
                console.log("ERROR in getNewsData function(redux):", err.message, err);
            })
    }
}

export const setNewsData = (newsData) => {
    if (newsData) {
        return {
            type: "SET_NEWS_DATA",
            payload: newsData,
        }
    }
}

export const getNewsCategoryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-news-category`, { method: "GET" })
            .then((res) => {
                res.json().then((newsCategoryData) => {
                    dispatch(setNewsCategoryData(newsCategoryData));
                    // console.log("action newsCategoryBanner data", newsCategoryData);
                })
            }).catch((err) => {
                console.log("ERROR in getNewsCategoryData function(redux):", err.message, err);
            })
    }
}

export const setNewsCategoryData = (newsCategoryData) => {
    if (newsCategoryData) {
        return {
            type: "SET_NEWS_CATEGORY_DATA",
            payload: newsCategoryData,
        }
    }
}

export const getAchievementData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-achievement`, { method: "GET" })
            .then((res) => {
                res.json().then((achievementData) => {
                    dispatch(setAchievementData(achievementData));
                    // console.log("action achievementBanner data", achievementData);
                })
            }).catch((err) => {
                console.log("ERROR in getAchievementData function(redux):", err.message, err);
            })
    }
}

export const setAchievementData = (achievementData) => {
    if (achievementData) {
        return {
            type: "SET_ACHIEVEMENT_DATA",
            payload: achievementData,
        }
    }
}

export const getContactDetailsData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-contact-details`, { method: "GET" })
            .then((res) => {
                res.json().then((contactDetailsData) => {
                    dispatch(setContactDetailsData(contactDetailsData));
                    // console.log("action contactDetailsBanner data", contactDetailsData);
                })
            }).catch((err) => {
                console.log("ERROR in getContactDetailsData function(redux):", err.message, err);
            })
    }
}

export const setContactDetailsData = (contactDetailsData) => {
    if (contactDetailsData) {
        return {
            type: "SET_CONTACT_DETAILS_DATA",
            payload: contactDetailsData,
        }
    }
}


export const getHomeEightSectionData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-home-eight-section`, { method: "GET" })
            .then((res) => {
                res.json().then((homeEightSectionData) => {
                    dispatch(setHomeEightSectionData(homeEightSectionData));
                    // console.log("action homeEightSectionBanner data", homeEightSectionData);
                })
            }).catch((err) => {
                console.log("ERROR in getHomeEightSectionData function(redux):", err.message, err);
            })
    }
}

export const setHomeEightSectionData = (homeEightSectionData) => {
    if (homeEightSectionData) {
        return {
            type: "SET_HOME_EIGHT_SECTION_DATA",
            payload: homeEightSectionData,
        }
    }
}

export const geTtestimonialData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-testimonial`, { method: "GET" })
            .then((res) => {
                res.json().then((testimonialData) => {
                    dispatch(setTestimonialData(testimonialData));
                    // console.log("action testimonialBanner data", testimonialData);
                })
            }).catch((err) => {
                console.log("ERROR in geTtestimonialData function(redux):", err.message, err);
            })
    }
}

export const setTestimonialData = (testimonialData) => {
    if (testimonialData) {
        return {
            type: "SET_TESTIMONIAL_DATA",
            payload: testimonialData,
        }
    }
}

export const getJobListData = ({ type, location, keyword }) => {
    return async function (dispatch) {
        let url;
        if (type === 'location') {
            url = `${host}/web/get-all-jobs?location=${location}`;
        }
        else if (type === 'keyword') {
            url = `${host}/web/get-all-jobs?keyword=${keyword}`;
        }
        else if (type === 'mixed') {
            url = `${host}/web/get-all-jobs?location=${location}&keyword=${keyword}`;
        }
        else if (type === 'all') {

            url = `${host}/web/get-all-jobs`;
        }
        try {
            const res = await fetch(url, { method: "GET" });
            const jobListData = await res.json();
            dispatch(setJobListData(jobListData));
            // console.log(`action jobListBanner ${type}data`, jobListData);
        } catch (err) {
            console.log("ERROR in getJobListData function(redux):", err.message);
        }
    }
}

export const setJobListData = (jobListData) => {
    if (jobListData) {
        return {
            type: "SET_JOB_LIST_DATA",
            payload: jobListData,
        }
    }
}



export const getAllBannerData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-all-banner`, { method: "GET" })
            .then((res) => {
                res.json().then((allBannerData) => {
                    dispatch(setAllBannerData(allBannerData));
                    // console.log("action allBannerBanner data", allBannerData);
                })
            }).catch((err) => {
                console.log("ERROR in getAllBannerData function(redux):", err.message, err);
            })
    }
}

export const setAllBannerData = (allBannerData) => {
    if (allBannerData) {
        return {
            type: "SET_ALL_BANNER_DATA",
            payload: allBannerData,
        }
    }
}

export const getAboutExpertData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-about-expert`, { method: "GET" })
            .then((res) => {
                res.json().then((aboutExpertData) => {
                    dispatch(setAboutExpertData(aboutExpertData));
                    // console.log("action aboutExpertBanner data", aboutExpertData);
                })
            }).catch((err) => {
                console.log("ERROR in getaboutExpertData function(redux):", err.message, err);
            })
    }
}

export const setAboutExpertData = (aboutExpertData) => {
    if (aboutExpertData) {
        return {
            type: "SET_ABOUT_EXPERT_DATA",
            payload: aboutExpertData,
        }
    }
}

export const getFounderDirectorDataParams = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-directors-founders-params?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((founderDirectorDataParams) => {
                    dispatch(setFounderDirectorDataParams(founderDirectorDataParams));
                    // console.log("action founderDirectorBanner data", founderDirectorDataParams);
                })
            }).catch((err) => {
                console.log("ERROR in getFounderDirectorDataParams function(redux):", err.message, err);
            })
    }
}

export const setFounderDirectorDataParams = (founderDirectorDataParams) => {
    if (founderDirectorDataParams) {
        return {
            type: "SET_FOUNDER_DIRECTOR_PARAMS_DATA",
            payload: founderDirectorDataParams,
        }
    }
}

export const getFounderDirectorData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-directors-founders`, { method: "GET" })
            .then((res) => {
                res.json().then((founderDirectorData) => {
                    dispatch(setFounderDirectorData(founderDirectorData));
                    // console.log("action founderDirectorBanner data", founderDirectorData);
                })
            }).catch((err) => {
                console.log("ERROR in getFounderDirectorData function(redux):", err.message, err);
            })
    }
}

export const setFounderDirectorData = (founderDirectorData) => {
    if (founderDirectorData) {
        return {
            type: "SET_FOUNDER_DIRECTOR_DATA",
            payload: founderDirectorData,
        }
    }
}

export const getOurTeamData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-our-team`, { method: "GET" })
            .then((res) => {
                res.json().then((ourTeamData) => {
                    dispatch(setOurTeamData(ourTeamData));
                    // console.log("action ourTeamBanner data", ourTeamData);
                })
            }).catch((err) => {
                console.log("ERROR in getOurTeamData function(redux):", err.message, err);
            })
    }
}

export const setOurTeamData = (ourTeamData) => {
    if (ourTeamData) {
        return {
            type: "SET_OUR_TEAM_DATA",
            payload: ourTeamData,
        }
    }
}

export const getSubCategoryData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-sub-category?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((subCategoryData) => {
                    dispatch(setSubCategoryData(subCategoryData));
                    // console.log("action subCategoryBanner data", subCategoryData);
                })
            }).catch((err) => {
                console.log("ERROR in getSubCategoryData function(redux):", err.message, err);
            })
    }
}

export const setSubCategoryData = (subCategoryData) => {
    if (subCategoryData) {
        return {
            type: "SET_SUB_CATEGORY_DATA",
            payload: subCategoryData,
        }
    }
}

export const getPwrCategoryMenuData = (id) => {

    return async function (dispatch) {
        await fetch(`${host}/api/master/get-all-menu-cat?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((PwrCategoryMenuData) => {
                    dispatch(setPwrCategoryMenuData(PwrCategoryMenuData));
                    // console.log("action PwrCategoryMenuBanner data", PwrCategoryMenuData);
                })
            }).catch((err) => {
                console.log("ERROR in getPwrCategoryMenuData function(redux):", err.message, err);
            })
    }
}

export const setPwrCategoryMenuData = (PwrCategoryMenuData) => {
    if (PwrCategoryMenuData) {
        return {
            type: "SET_PWR_CATEGORY_MENU_DATA",
            payload: PwrCategoryMenuData,
        }
    }
}


export const getSlrCategoryMenuData = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-all-menu-cat?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((SlrCategoryMenuData) => {
                    dispatch(setSlrCategoryMenuData(SlrCategoryMenuData));
                    // console.log("action SlrCategoryMenuBanner data", SlrCategoryMenuData);
                })
            }).catch((err) => {
                console.log("ERROR in getSlrCategoryMenuData function(redux):", err.message, err);
            })
    }
}

export const setSlrCategoryMenuData = (SlrCategoryMenuData) => {
    if (SlrCategoryMenuData) {
        return {
            type: "SET_SLR_CATEGORY_MENU_DATA",
            payload: SlrCategoryMenuData,
        }
    }
}

export const getChildCategoryData = (name) => {

    return async function (dispatch) {
        await fetch(`${host}/api/master/get-child-category?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((childCategoryData) => {
                    dispatch(setChildCategoryData(childCategoryData));
                    // console.log("action childCategoryBanner data", childCategoryData);
                })
            }).catch((err) => {
                console.log("ERROR in getChildCategoryData function(redux):", err.message, err);
            })
    }
}

export const setChildCategoryData = (childCategoryData) => {
    if (childCategoryData) {
        return {
            type: "SET_CHILD_CATEGORY_DATA",
            payload: childCategoryData,
        }
    }
}

export const getProductListData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-product-list?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((productListData) => {
                    dispatch(setProductListData(productListData));
                    // console.log("action productListBanner data", productListData);
                })
            }).catch((err) => {
                console.log("ERROR in getProductListData function(redux):", err.message, err);
            })
    }
}

export const setProductListData = (productListData) => {
    if (productListData) {
        return {
            type: "SET_PRODUCT_LIST_DATA",
            payload: productListData,
        }
    }
}

export const getProductData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-product-data?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((productData) => {
                    dispatch(setProductData(productData));
                    // console.log("action productBanner data", productData);
                })
            }).catch((err) => {
                console.log("ERROR in getProductData function(redux):", err.message, err);
            })
    }
}

export const setProductData = (productData) => {
    if (productData) {
        return {
            type: "SET_PRODUCT_DATA",
            payload: productData,
        }
    }
}

export const getProductImgData = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-product-image?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((productImgData) => {
                    dispatch(setProductImgData(productImgData));
                    // console.log("action productImgBanner data", productImgData);
                })
            }).catch((err) => {
                console.log("ERROR in getProductImgData function(redux):", err.message, err);
            })
    }
}

export const setProductImgData = (productImgData) => {
    if (productImgData) {
        return {
            type: "SET_PRODUCT_IMG_DATA",
            payload: productImgData,
        }
    }
}

export const getEaproSummaryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-all-about-summary`, { method: "GET" })
            .then((res) => {
                res.json().then((EaproSummaryData) => {
                    dispatch(setEaproSummaryData(EaproSummaryData));
                    // console.log("action EaproSummaryBanner data", EaproSummaryData);
                })
            }).catch((err) => {
                console.log("ERROR in getEaproSummaryData function(redux):", err.message, err);
            })
    }
}

export const setEaproSummaryData = (EaproSummaryData) => {
    if (EaproSummaryData) {
        return {
            type: "SET_EAPRO_SUMMARY_DATA",
            payload: EaproSummaryData,
        }
    }
}

export const getCategoryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-category`, { method: "GET" })
            .then((res) => {
                res.json().then((categoryData) => {
                    dispatch(setCategoryData(categoryData));
                    // console.log("action categoryBanner data", categoryData);
                })
            }).catch((err) => {
                console.log("ERROR in getCategoryData function(redux):", err.message, err);
            })
    }
}

export const setCategoryData = (categoryData) => {
    if (categoryData) {
        return {
            type: "SET_CATEGORY_DATA",
            payload: categoryData,
        }
    }
}


export const getCategoryParamsData = (name) => {

    return async function (dispatch) {
        await fetch(`${host}/api/master/get-category-params?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((categoryParamsData) => {
                    dispatch(setCategoryParamsData(categoryParamsData));
                    // console.log("action categoryParamsBanner data", categoryParamsData);
                })
            }).catch((err) => {
                console.log("ERROR in getCategoryParamsData function(redux):", err.message, err);
            })
    }
}

export const setCategoryParamsData = (categoryParamsData) => {
    if (categoryParamsData) {
        return {
            type: "SET_CATEGORY_PARAMS_DATA",
            payload: categoryParamsData,
        }
    }
}


export const getCategoryOnIdData = (id) => {

    return async function (dispatch) {
        await fetch(`${host}/api/master/get-category-on-id?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((categoryOnIdData) => {
                    dispatch(setCategoryOnIdData(categoryOnIdData));
                    // console.log("action categoryOnIdBanner data", categoryOnIdData);
                })
            }).catch((err) => {
                console.log("ERROR in getCategoryOnIdData function(redux):", err.message, err);
            })
    }
}

export const setCategoryOnIdData = (categoryOnIdData) => {
    if (categoryOnIdData) {
        return {
            type: "SET_CATEGORY_ON_ID_DATA",
            payload: categoryOnIdData,
        }
    }
}


export const getJobData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-job-data?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((jobData) => {
                    dispatch(setJobData(jobData));
                    // console.log("action jobBanner data", jobData);
                })
            }).catch((err) => {
                console.log("ERROR in getJobData function(redux):", err.message, err);
            })
    }
}

export const setJobData = (jobData) => {
    if (jobData) {
        return {
            type: "SET_JOB_DATA",
            payload: jobData,
        }
    }
}


export const getCmsData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-all-cms?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((CmsData) => {
                    dispatch(setCmsData(CmsData));
                    // console.log("action CmsBanner data", CmsData);
                })
            }).catch((err) => {
                console.log("ERROR in getCmsData function(redux):", err.message, err);
            })
    }
}

export const setCmsData = (CmsData) => {
    if (CmsData) {
        return {
            type: "SET_CMS_DATA",
            payload: CmsData,
        }
    }
}


export const getCsrData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-csr-data`, { method: "GET" })
            .then((res) => {
                res.json().then((CsrData) => {
                    dispatch(setCsrData(CsrData));
                    // console.log("action CsrBanner data", CsrData);
                })
            }).catch((err) => {
                console.log("ERROR in getCsrData function(redux):", err.message, err);
            })
    }
}

export const setCsrData = (CsrData) => {
    if (CsrData) {
        return {
            type: "SET_CSR_DATA",
            payload: CsrData,
        }
    }
}

export const getCsrOneData = (name) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-csr-one-data?name=${name}`, { method: "GET" })
            .then((res) => {
                res.json().then((CsrOneData) => {
                    dispatch(setCsrOneData(CsrOneData));
                    // console.log("action CsrOneBanner data", CsrOneData);
                })
            }).catch((err) => {
                console.log("ERROR in getCsrOneData function(redux):", err.message, err);
            })
    }
}

export const setCsrOneData = (CsrOneData) => {
    if (CsrOneData) {
        return {
            type: "SET_CSR_ONE_DATA",
            payload: CsrOneData,
        }
    }
}

export const getLearnMoreData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-learn-more-data`, { method: "GET" })
            .then((res) => {
                res.json().then((LearnMoreData) => {
                    dispatch(setLearnMoreData(LearnMoreData));
                    console.log("action LearnMoreBanner data", LearnMoreData);
                })
            }).catch((err) => {
                console.log("ERROR in getLearnMoreData function(redux):", err.message, err);
            })
    }
}


export const setLearnMoreData = (LearnMoreData) => {
    if (LearnMoreData) {
        return {
            type: "SET_LEARN_MORE_DATA",
            payload: LearnMoreData,
        }
    }
}

export const getLearnMoreListData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-learn-more-data-list`, { method: "GET" })
            .then((res) => {
                res.json().then((LearnMoreListData) => {
                    dispatch(setLearnMoreListData(LearnMoreListData));
                    // console.log("action LearnMoreBanner data", LearnMoreListData);
                })
            }).catch((err) => {
                console.log("ERROR in getLearnMoreListData function(redux):", err.message, err);
            })
    }
}


export const setLearnMoreListData = (LearnMoreListData) => {
    if (LearnMoreListData) {
        return {
            type: "SET_LEARN_MORE_LIST_DATA",
            payload: LearnMoreListData,
        }
    }
}

export const getCustomerFeedbackData = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-customer-feedback-data?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((CustomerFeedbackData) => {
                    dispatch(setCustomerFeedbackData(CustomerFeedbackData));
                    // console.log(" action CustomerFeedbackBanner data", CustomerFeedbackData);
                })
            }).catch((err) => {
                console.log("ERROR in getCustomerFeedbackData function(redux):", err.message, err);
            })
    }
}

export const setCustomerFeedbackData = (CustomerFeedbackData) => {
    if (CustomerFeedbackData) {
        return {
            type: "SET_CUSTOMER_FEEDBACK_DATA",
            payload: CustomerFeedbackData,
        }
    }
}

export const getProductSearchData = (keyword) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-product-data-search?keyword=${keyword}`, { method: "GET" })
            .then((res) => {
                res.json().then((productSearchData) => {
                    dispatch(setProductSearchData(productSearchData));
                    // console.log("action productSearchBanner data", productSearchData);
                })
            }).catch((err) => {
                console.log("ERROR in getProductSearchData function(redux):", err.message, err);
            })
    }
}

export const setProductSearchData = (productSearchData) => {
    if (productSearchData) {
        return {
            type: "SET_PRODUCT_SEARCH_DATA",
            payload: productSearchData,
        }
    }
}

export const getProductExtendedWarr = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-product-list-amc`, { method: "GET" })
            .then((res) => {
                res.json().then((productExtendedWarr) => {
                    dispatch(setProductExtendedWarr(productExtendedWarr));
                    // console.log("action productExtendedWarr data", productExtendedWarr);
                })
            }).catch((err) => {
                console.log("ERROR in getProductExtendedWarr function(redux):", err.message, err);
            })
    }
}

export const setProductExtendedWarr = (productExtendedWarr) => {
    if (productExtendedWarr) {
        return {
            type: "SET_PRODUCT_EXTENDED_WARR_DATA",
            payload: productExtendedWarr,
        }
    }
}

export const getRatingChartData = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-customer-rating-data?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((RatingChartData) => {
                    dispatch(setRatingChartData(RatingChartData));
                    // console.log("action RatingChartBanner data", RatingChartData);
                })
            }).catch((err) => {
                console.log("ERROR in getRatingChartData function(redux):", err.message, err);
            })
    }
}

export const setRatingChartData = (RatingChartData) => {
    if (RatingChartData) {
        return {
            type: "SET_RATING_CHART_DATA",
            payload: RatingChartData,
        }
    }
}


export const getReferenceData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-reference`, { method: "GET" })
            .then((res) => {
                res.json().then((ReferenceData) => {
                    dispatch(setReferenceData(ReferenceData));
                    // console.log("action ReferenceBanner data", ReferenceData);
                })
            }).catch((err) => {
                console.log("ERROR in getReferenceData function(redux):", err.message, err);
            })
    }
}

export const setReferenceData = (ReferenceData) => {
    if (ReferenceData) {
        return {
            type: "SET_REFERENCE_DATA",
            payload: ReferenceData,
        }
    }
}

export const getCountryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-country`, { method: "GET" })
            .then((res) => {
                res.json().then((CountryData) => {
                    dispatch(setCountryData(CountryData));
                    // console.log("action CountryBanner data", CountryData);
                })
            }).catch((err) => {
                console.log("ERROR in getCountryData function(redux):", err.message, err);
            })
    }
}

export const setCountryData = (CountryData) => {
    if (CountryData) {
        return {
            type: "SET_COUNTRY_DATA",
            payload: CountryData,
        }
    }
}

export const getStateData = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-state?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((StateData) => {
                    dispatch(setStateData(StateData));
                    // console.log("action StateBanner data", StateData);
                })
            }).catch((err) => {
                console.log("ERROR in getStateData function(redux):", err.message, err);
            })
    }
}

export const setStateData = (StateData) => {
    if (StateData) {
        return {
            type: "SET_STATE_DATA",
            payload: StateData,
        }
    }
}

export const getApplianceCategoryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-appliance-category`, { method: "GET" })
            .then((res) => {
                res.json().then((ApplianceCategoryData) => {
                    dispatch(setApplianceCategoryData(ApplianceCategoryData));
                    // console.log("action CityBanner data", ApplianceCategoryData);
                })
            }).catch((err) => {
                console.log("ERROR in getApplianceCategoryData function(redux):", err.message, err);
            })
    }
}

export const setApplianceCategoryData = (ApplianceCategoryData) => {
    if (ApplianceCategoryData) {
        return {
            type: "SET_APPLIANCE_CATEGORY_DATA",
            payload: ApplianceCategoryData,
        }
    }
}


export const getCityData = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-city?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((CityData) => {
                    dispatch(setCityData(CityData));
                    // console.log("action CityBanner data", CityData);
                })
            }).catch((err) => {
                console.log("ERROR in getCityData function(redux):", err.message, err);
            })
    }
}

export const setCityData = (CityData) => {
    if (CityData) {
        return {
            type: "SET_CITY_DATA",
            payload: CityData,
        }
    }
}

export const getApplianceData = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/api/master/get-appliance?catId=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((ApplianceData) => {
                    dispatch(setApplianceData(ApplianceData));
                    // console.log("action CityBanner data", ApplianceData);
                })
            }).catch((err) => {
                console.log("ERROR in getApplianceData function(redux):", err.message, err);
            })
    }
}

export const setApplianceData = (ApplianceData) => {
    if (ApplianceData) {
        return {
            type: "SET_APPLIANCE_DATA",
            payload: ApplianceData,
        }
    }
}
