import React from 'react'
import "./ViewSolarProductDetailsContent.css"
import { Link } from 'react-router-dom'
const ViewSolarProductDetailsContent = () => {
    return (
        <>
            {/* ------- product-1  ------------ */}
            <section className="solar-product-custom-product-1">
                {/* graphic-1  */}
                <div className="bg-graphic-right-top">
                    <img src="./images/power-solution/bg-graphic-1.png" className="img-fluid" />
                </div>
                <div className="container">
                    <div className="row">
                        <h4 className="text-center">Pure Sine Wave</h4>
                        <div className="col-lg-4 mb-lg-0 mb-5">
                            <div className="product-img">
                                <img src="./images/power-solution/product-one.png" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="product-info">
                                <h2>TRANO SERIES</h2>
                                <p>
                                    Enjoy hassle-free days with EAPRO, India's premier brand for
                                    top-notch power solutions. EAPRO's commitment to innovation and
                                    cutting-edge technology shines through its products, ensuring
                                    reliability and efficiency. Experience peace of mind knowing that
                                    your power needs are met with excellence. Whether it's for home or
                                    business, EAPRO delivers trusted solutions that keep you powered up
                                    without worries. Join countless satisfied customers who rely on
                                    EAPRO for superior performance and durability. Make the smart choice
                                    for your power needs and choose EAPRO for a seamless experience
                                    every day.
                                </p>
                                <Link to="/view-product-series-listing" className="view-all-btn">View All Product</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* graphic-2  */}
                <div className="bg-graphic-left-bottom">
                    <img src="./images/power-solution/bg-graphic-2.png" className="img-fluid" />
                </div>
            </section>
            {/* ------- product-2  ------------ */}
            <section className="solar-product-custom-product-2">
                {/* graphic-center  */}
                <div className="bg-graphic-center">
                    <img src="./images/power-solution/bg-graphic-3.png" className="img-fluid" />
                </div>
                <div className="container">
                    <div className="row">
                        <h4 className="text-center">Boost</h4>
                        <div className="col-lg-6 offset-lg-1  mb-lg-0 mb-5">
                            <div className="product-info">
                                <h2>BOOST SERIES</h2>
                                <p>
                                    Enjoy hassle-free days with EAPRO, India's premier brand for
                                    top-notch power solutions. EAPRO's unwavering commitment to
                                    innovation and cutting-edge technology is evident in every product.
                                    From reliable inverters to efficient solar panels, EAPRO ensures
                                    seamless power supply tailored to your needs. Whether it's for your
                                    home or business, trust EAPRO to deliver unmatched quality and
                                    performance, allowing you to focus on what matters most without
                                    worrying about power disruptions. Experience the convenience and
                                    reliability that EAPRO brings, making your days stress-free and
                                    productive. Choose EAPRO for power solutions that exceed
                                    expectations.
                                </p>
                                <Link to="/view-product-series-listing" className="view-all-btn">View All Product</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-1">
                            <div className="product-img">
                                <img src="./images/power-solution/product-two.png" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ------- product-3  ------------ */}
            <section className="solar-product-custom-product-3">
                {/* graphic-1  */}
                <div className="bg-graphic-right-top">
                    <img src="./images/power-solution/bg-graphic-1.png" className="img-fluid" />
                </div>
                <div className="container">
                    <div className="row">
                        <h4 className="text-center">SAVER</h4>
                        <div className="col-lg-4 mb-lg-0 mb-5">
                            <div className="product-img">
                                <img src="./images/power-solution/product-three.png" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="product-info">
                                <h2>SAVER SERIES</h2>
                                <p>
                                    Enjoy hassle-free days with EAPRO, India's premier brand for
                                    top-notch power solutions. EAPRO's unwavering commitment to
                                    innovation and cutting-edge technology is evident in every product.
                                    From reliable inverters to efficient solar panels, EAPRO ensures
                                    seamless power supply tailored to your needs. Whether it's for your
                                    home or business, trust EAPRO to deliver unmatched quality and
                                    performance, allowing you to focus on what matters most without
                                    worrying about power disruptions. Experience the convenience and
                                    reliability that EAPRO brings, making your days stress-free and
                                    productive. Choose EAPRO for power solutions that exceed
                                    expectations.
                                </p>
                                <Link to="/view-product-series-listing" className="view-all-btn">View All Product</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* graphic-2  */}
                <div className="bg-graphic-left-bottom">
                    <img src="./images/power-solution/bg-graphic-2.png" className="img-fluid" />
                </div>
            </section>

        </>
    )
}

export default ViewSolarProductDetailsContent