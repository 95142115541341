import React from 'react'
import "./ManufacturingPower.css"
const host = process.env.REACT_APP_API_SERVER;
const ManufacturingPowerBanner = (whyEaproData) => {
  return (
    <>
      <section className='manufacturing-power-banner-sec'>
        <img src={whyEaproData?.whyEaproData?.banner ? `${host}/${whyEaproData?.whyEaproData?.banner}` : ""} alt="" className='img-fluid' />
      </section>
    </>
  )
}

export default ManufacturingPowerBanner