import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import Navbar from '../../Header/Navbar'


import NavbarSecond from '../../Header/NavbarSecond'
import MobileNavbar from '../../Header/MobileNavbar'
import Footer from '../../Footer/Footer'

import CsrDetailsContent from './CsrDetailsContent/CsrDetailsContent';
import { getCsrOneData } from '../../../Redux/Action/Action';

import CsrDetailsBanner from './CsrDetailsBanner/CsrDetailsBanner';

const CsrDetails = () => {

    const dispatch = useDispatch();





    const navigate = useNavigate();

    const { name } = useParams();
    useEffect(() => {
        if (!name) {
            toast.error("No such csr.");


            // navigate(-1)
        } else {
            dispatch(getCsrOneData(name))

        }
    }, [name])
    const CsrOneData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.CsrOneDataList?.data) : []);





    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CsrDetailsBanner CsrOneData={CsrOneData} />
            <CsrDetailsContent CsrOneData={CsrOneData} />
            <Footer />
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </>
    )
}

export default CsrDetails