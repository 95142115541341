import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./DesignSolarPowerLoadCal.css"
import "./ResponsiveDesignSolarPowerLoadCal.css"
import { getHomeSecondSecData } from '../../../Redux/Action/Action';
import { Link } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;
const DesignSolarPowerLoadCal = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHomeSecondSecData());

    }, []);

    const homeSecondSecData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.homeSecondSecDataList?.data) : []);


    return (
        <>
            <section className='design-solar-power-load-calculator-section'>
                <div className="design-before-row-dv" style={{ backgroundImage: `url(${homeSecondSecData?.image ? `${host}${homeSecondSecData?.image}` : ""})` }} >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-9">
                                <div className="content-parent-dv">
                                    <div className="content-inner-first">
                                        <h4>{homeSecondSecData?.title ? homeSecondSecData?.title : " We Can Help You Find The Right Power & Solar Solution"}</h4>
                                        <h5 className='my-3'>{homeSecondSecData?.subtitle ? homeSecondSecData?.subtitle : "Our Team Of  Experts Or Simple Calculator Can Help You Understand The Over All Load Requirements "}</h5>
                                    </div>
                                    <div className="content-inner-second">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-4 mb-2">
                                                <div className="second-inner-button">
                                                    <Link to="/design-solar-system">
                                                        <img src="../images/solar-system.png" alt="" /> Design Your Solar System
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-2">
                                                <div className="second-inner-button ">
                                                    <Link to='/load-calculator'>
                                                        <img src="../images/load-calculator.png" alt="" /> Load Calculator
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DesignSolarPowerLoadCal