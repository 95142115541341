import React from 'react'
import "./EAPROOverseasBanner.css"
import "./ResponsiveEAPROOverseasBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const EAPROOverseasBanner = (eaproOverseasData) => {
    return (
        <>
            <section className='over-energy-banner-section'>
                {/* <div className="container-fluid"> */}
                {/* <div className="row align-items-center"> */}
                <div className="col-md-12 text-start">
                    <div className="business-heading text-start">
                        <img src={eaproOverseasData?.eaproOverseasData?.banner_image ? `${host}/${eaproOverseasData?.eaproOverseasData?.banner_image}` : "./images/global-energy/overseas-banner.png"} alt="" className='img-fluid' />
                        {/* <h1>Help & Support</h1> */}
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </section>
        </>
    )
}

export default EAPROOverseasBanner