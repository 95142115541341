import React from 'react'
import "./StoreLocatorContent.css"
const StoreLocatorContentCard = (storeLocatorData) => {




  const address = storeLocatorData?.data?.BaseAddressLine;
  const addressLines = address?.split(/\r\n/);

  return (
    <>
      <section className='store-locator-content-section-card'>
        <div className='container'>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              {(storeLocatorData?.data) ?
                <div className="store-locator-card">
                  <h4>{storeLocatorData?.data?.CustomerName ? storeLocatorData?.data?.CustomerName : ""}{storeLocatorData?.data?.CustomerType ? `, ${storeLocatorData?.data?.CustomerType}` : ""}</h4>

                  <div className="row">
                    <div className="col-md-10">
                      <div className="para-store-add">
                        <p>{storeLocatorData?.data?.ContactNo ? `${storeLocatorData?.data?.ContactNo}` : ""}</p>
                        <p>{storeLocatorData?.data?.address}{storeLocatorData?.data?.city ? `, ${storeLocatorData?.data?.city}` : ""}{storeLocatorData?.data?.state ? `, ${storeLocatorData?.data?.state}` : ""}{storeLocatorData?.data?.country ? `, ${storeLocatorData?.data?.country}` : ""}{storeLocatorData?.data?.pincode ? `, ${storeLocatorData?.data?.pincode}` : ""}</p>
                      </div>
                    </div>
                    {storeLocatorData?.data?.ContactNo &&
                      <div className="col-md-2">
                        <div className="phone-icon">
                          <a href={`tel:${storeLocatorData?.data?.ContactNo}`}><img src="./images/store-locator/phone.png" alt="" /></a>
                        </div>
                      </div>}
                  </div>
                </div>
                : <h4>No Stores Available At This Location.</h4>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StoreLocatorContentCard