import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import ContactUsPageNewBanner from './ContactUsPageNewBanner/ContactUsPageNewBanner'
import ContactUsPageNewContent from './ContactUsPageNewContent/ContactUsPageNewContent'
import { getContactDetailsData } from '../../Redux/Action/Action';

const ContactUsPageNew = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactDetailsData());
    }, []);

    const contactDetailsData = useSelector((state) => state?.Reducer?.contactDetailsDataList?.data || []);

    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ContactUsPageNewBanner contactDetailsData={contactDetailsData} />
            <ContactUsPageNewContent contactDetailsData={contactDetailsData} />
            <Footer />
        </>
    )
}

export default ContactUsPageNew