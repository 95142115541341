import React from 'react'
import parse from 'html-react-parser'
import "./EaproGlobalLtdContent.css"
const EaproGlobalLtdContent = (eaproLtdData) => {
    return (
        <>
            <section className='global-energy-ltd-con-page-content-section'>
                <div className="container">
                    <div className="global-energy-heading">
                        <h5>About EAPRO Global Ltd.</h5>
                    </div>
                    <div className="top-para">
                        {eaproLtdData?.eaproLtdData?.about_us_content ? parse(eaproLtdData?.eaproLtdData?.about_us_content) : <p>Welcome to Eapro Global Ltd, the driving force behind innovation and excellence in the manufacturing of solar and non-solar products, alongside a comprehensive range of power electronics items. Established in 2012, we take pride in being the specialized hub within the Eapro Group, providing OEM (Original Equipment Manufacturer) and ODM (Original Design Manufacturer) products and services to a diverse clientele, including popular brands and other companies within the Eapro Group.</p>}
                    </div>
                    <div className="icon-row-parent row">
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/mission.png" alt="" />
                                    <h4>OUR  <span>MISSION</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-mission.png" alt="" />
                                    {eaproLtdData?.eaproLtdData?.our_mission ? parse(eaproLtdData?.eaproLtdData?.our_mission) : <p>Our mission is to empower our clients with superior power electronics solutions. Through the manufacturing of solar and non-solar products, we aim to be the go-to partner for OEM and ODM services, offering tailored solutions that cater to the unique needs of our clients. Eapro Global Ltd is committed to driving technological advancement and contributing to the growth of our industry.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/vision.png" alt="" />
                                    <h4>OUR  <span>Vision</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-vision.png" alt="" />
                                    {eaproLtdData?.eaproLtdData?.our_vision ? parse(eaproLtdData?.eaproLtdData?.our_vision) : <p>At Eapro Global Ltd, our vision is to be a global leader in manufacturing, setting benchmarks for quality, innovation, and sustainability in the power electronics industry. We aspire to shape the future of energy solutions by delivering cutting-edge products that redefine industry standards.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card last-icon-heading-card">
                                    <img src="./images/global-energy/values.png" alt="" />
                                    <h4>OUR  <span>Values</span></h4>
                                </div>
                                <div className="dark-icons-content last-dark-icons-content">
                                    <img src="./images/global-energy/dark-values.png" alt="" />
                                    {eaproLtdData?.eaproLtdData?.our_value ? parse(eaproLtdData?.eaproLtdData?.our_value) : <p>Innovation Excellence: We thrive on innovation, fostering a culture that encourages creative thinking and continuous improvement. Our commitment to staying at the forefront of technological advancements ensures that our products meet the evolving needs of our clients.</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="icon-row-parent row mt-5">
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/reliability.png" alt="" />
                                    <h4>OUR  <span>Quality Assurance</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-reliability.png" alt="" />
                                    {eaproLtdData?.eaproLtdData?.our_quality_assurance ? parse(eaproLtdData?.eaproLtdData?.our_quality_assurance) : <p>We are unwavering in our dedication to quality. Every product manufactured by Eapro Global Ltd undergoes stringent quality control measures to ensure reliability, durability, and adherence to the highest industry standards.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/sustainability.png" alt="" />
                                    <h4>OUR  <span>Sustainability</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-sustainability.png" alt="" />
                                    {eaproLtdData?.eaproLtdData?.our_sustainability ? parse(eaproLtdData?.eaproLtdData?.our_sustainability) : <p>As a leader in power electronics manufacturing, we recognize our responsibility to the environment. Eapro Global Ltd is committed to sustainable practices, promoting energy efficiency, and contributing to a greener, more sustainable future.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card last-icon-heading-card">
                                    <img src="./images/global-energy/collaboration.png" alt="" />
                                    <h4>OUR  <span>Customer-Centric Approach</span></h4>
                                </div>
                                <div className="dark-icons-content last-dark-icons-content">
                                    <img src="./images/global-energy/dark-collaboration.png" alt="" />
                                    {eaproLtdData?.eaproLtdData?.our_customer_approach ? parse(eaproLtdData?.eaproLtdData?.our_customer_approach) : <p>Our clients are at the heart of everything we do. We are committed to understanding their unique requirements and delivering solutions that exceed expectations. Our customer-centric approach forms the foundation of lasting partnerships.</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EaproGlobalLtdContent