import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import QualityAssuranceBanner from './QualityAssuranceBanner'
import QualityAssuranceContent from './QualityAssuranceContent'
import { getLearnMoreData } from "../../Redux/Action/Action";

const QualityAssurance = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getLearnMoreData());
    }, []);

    const LearnMoreData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.LearnMoreDataList?.data) : []);




    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <QualityAssuranceBanner LearnMoreData={LearnMoreData} />
            <QualityAssuranceContent LearnMoreData={LearnMoreData} />
            <Footer />
        </>
    )
}

export default QualityAssurance