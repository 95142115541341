import React from 'react'
import Navbar from '../../../Header/Navbar'
import NavbarSecond from '../../../Header/NavbarSecond'
import MobileNavbar from '../../../Header/MobileNavbar'
import Footer from '../../../Footer/Footer'
import LoadCalculator from './LoadCalculator'

const LoadCalculatorHome = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <LoadCalculator />
            <Footer />
        </>
    )
}

export default LoadCalculatorHome