import { React, useEffect } from 'react'
import BlogBanner from './BlogBanner/BlogBanner'
import Navbar from '../Header/Navbar'
import MobileNavbar from '../Header/MobileNavbar'
import NavbarSecond from '../Header/NavbarSecond'
import Footer from '../Footer/Footer'
import BlogContent from './BlogContent/BlogContent'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAllBannerData } from '../../Redux/Action/Action'

const Blog = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);


    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <BlogBanner bannerData={bannerData} />
            <BlogContent />
            <Footer />
        </>
    )
}

export default Blog