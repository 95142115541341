import React from 'react'
import "./QualityAssurance.css"
import "./ResponsiveQualityAssurance.css"
import parse from 'html-react-parser'
const QualityAssuranceContent = (LearnMoreData) => {
  return (
    <>
      <section className='quality-assurance-content-sec'>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="new-page-heading mt-5 d-flex justify-content-center" >
                <h5 className='fw-bold text-center mb-5 title' >{LearnMoreData?.LearnMoreData?.title}</h5>
              </div>
            </div>
            <div className="col-md-12 text-start">
              <div className="new-page-content">
                {LearnMoreData?.LearnMoreData?.description ? parse(LearnMoreData?.LearnMoreData?.description) : ""}
              </div>
            </div>
          </div>


        </div>
      </section>
    </>
  )
}

export default QualityAssuranceContent