import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EfficientMechanicalSolarBanner from './EfficientMechanicalSolarBanner/EfficientMechanicalSolarBanner'
import EfficientMechanicalSolarContent from './EfficientMechanicalSolarContent/EfficientMechanicalSolarContent'
import EfficientMechanicalSolarForms from './EfficientMechanicalSolarContent/EfficientMechanicalSolarForms'
import { getEfficientMechanicalData } from '../../Redux/Action/Action';

const EfficientMechanicalSolar = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEfficientMechanicalData());

    }, []);

    const efficientMechanicalData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.efficientMechanicalDataList?.data) : []);
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EfficientMechanicalSolarBanner efficientMechanicalData={efficientMechanicalData} />
            <EfficientMechanicalSolarContent efficientMechanicalData={efficientMechanicalData} />
            <EfficientMechanicalSolarForms />
            <Footer />
        </>
    )
}

export default EfficientMechanicalSolar