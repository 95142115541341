import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EventPageDetailsBanner from './EventPageDetailsBanner/EventPageDetailsBanner'
import EventPageDetailsContent from './EventPageDetailsContent/EventPageDetailsContent'
import { getNewsParamData } from '../../Redux/Action/Action';

const EventPageDetails = () => {

    const dispatch = useDispatch();





    const navigate = useNavigate();

    const { name } = useParams();
    useEffect(() => {
        if (!name) {
            toast.error("No Such Page Found!");


            navigate(-1)
        } else {

            dispatch(getNewsParamData(name));

        }
    }, [name])
    const newsData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.NewsParamDataList?.data) : []);



    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EventPageDetailsBanner newsData={newsData} />
            <EventPageDetailsContent newsData={newsData} />
            <Footer />
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </>
    )
}

export default EventPageDetails