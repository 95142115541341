import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ViewProductSeriesListingContent from './ViewProductSeriesListingContent'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import PowerSolutionPageBanner from '../PowerSolutionPage/PowerSolutionPageBanner/PowerSolutionPageBanner'

import SolarSolutionPageBanner from '../SolarSolutionPage/SolarSolutionPageBanner/SolarSolutionPageBanner';
import { getCategoryOnIdData, getCategoryParamsData, getProductImgData, getProductListData } from '../../Redux/Action/Action';

const ViewProductSeriesListing = () => {

    const dispatch = useDispatch();
    const { name } = useParams();


    useEffect(() => {

        dispatch(getProductListData(name))

    }, [name]);


    const productListData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.productListDataList?.data) : []);

    const catId = productListData?.[0]?.catId;


    useEffect(() => {
        if (catId != undefined) {
            dispatch(getCategoryOnIdData(catId))
        }

    }, [productListData]);
    const categoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.categoryOnIdDataList?.data) : []);


    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />

            <PowerSolutionPageBanner categoryData={categoryData} />

            <ViewProductSeriesListingContent productListData={productListData} />
            <Footer />
        </>
    )
}

export default ViewProductSeriesListing