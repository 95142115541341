import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./CareerAtEaproThreeContent.css"
import { Link, useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { getJobListData } from '../../../../Redux/Action/Action';
const CareerAtEaproThreeContent = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleClick = (name) => {

    if (name !== undefined) {
      navigate(`/career-at-eapro-details/${name}`)
    }
  }
  const jobListData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.jobListDataList?.data) : []);

  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('');


  const keywordOnChange = (e) => {
    setKeyword(e.target.value);
  }

  const locationOnChange = (e) => {
    setLocation(e.target.value);
  }

  useEffect(() => {
    dispatch(getJobListData({ type: "all", location: "", keyword: "" }));

  }, [dispatch]);

  //pagination
  const [currentData, setCurrentData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  const dataPerPage = 10;
  const [showPagination, setShowPagination] = useState(false)
  useEffect(() => {

    const endOffset = dataOffset + dataPerPage;
    setCurrentData(jobListData?.slice(dataOffset, endOffset));
    setPageCount(Math.ceil(jobListData?.length / dataPerPage));
    if (pageCount > 1) {
      setShowPagination(true);
    }
    else {
      setShowPagination(false);
    }
  }, [dataOffset, dataPerPage, jobListData])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * dataPerPage) % jobListData.length;
    setDataOffset(newOffset);
  };


  const handleOnClick = () => {


    if (keyword === "" && location !== '') {

      dispatch(getJobListData({ type: "location", location: location, keyword: "" }));
    }
    else if (location === "" && keyword !== '') {

      dispatch(getJobListData({ type: "keyword", location: '', keyword: keyword }));
    }
    else if (keyword !== '' && location !== '') {

      dispatch(getJobListData({ type: "mixed", location: location, keyword: keyword }));
    }
    else {
      dispatch(getJobListData({ type: "all", location: "", keyword: "" }))
    }
  }



  return (
    <>
      <section className='career-at-eapro-three-con-search-jobs'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              <div className="row">
                <div className="col-md-6 text-start">
                  <label htmlFor="search-by-keyword-id" className='form-label'>Search by Keyword</label>
                  <input type="text" className="form-control" placeholder='' id='search-by-keyword-id' onChange={keywordOnChange} />
                </div>
                <div className="col-md-6 text-start">
                  <label htmlFor="search-by-location-id" className='form-label'>Search by Location</label>
                  <input type="text" className="form-control" placeholder='' id='search-by-location-id' onChange={locationOnChange} />
                </div>
                <div className="col-md-12">
                  <div className="search-job-btn">
                    <button type="button" onClick={handleOnClick} className=''>Search Jobs</button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className='career-at-eapro-three-page-content-section'>
        <div className="container">
          <div className="listing-parent-dv text-start">
            <div className="pagination-dv">
              {/* <p>
                <b>Results : 1 - 10 of 15</b>
              </p> */}
              {/* <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                      >
                        <path stroke="black"
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                        />
                      </svg>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item active" aria-current="page">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path stroke="black"
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </nav> */}
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr className="table-dark">
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Location</th>
                    <th scope="col">Date of Posting Job</th>
                  </tr>
                </thead>

                <tbody >
                  {currentData?.map((data) => (
                    <tr key={data?.id} onClick={() => { handleClick(data?.slug) }} style={{ cursor: "pointer" }}>
                      <td>{data?.title}</td>
                      <td>{data?.location}</td>
                      <td>{new Date(data?.createdAt).toDateString()}</td>
                    </tr>))}
                </tbody>

              </table>
            </div>
            <div className="pagination-dv">

              {/* <p>
                <b>Results : 1 - 10 of 15</b>
              </p> */}
              {showPagination && <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {/* <li className="page-item">
                    <a className="page-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                      >
                        <path stroke="black"
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                        />
                      </svg>
                    </a>
                  </li> */}
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=""
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel=""
                    renderOnZeroPageCount={null}
                    containerClassName='pagination'
                    pageLinkClassName='page-num'
                    previousLinkClassName='page-num'
                    nextLinkClassName='page-num'
                    activeClassName='active'
                  />
                  {/* <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item active" aria-current="page">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path stroke="black"
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </a>
                  </li> */}
                </ul>
              </nav>}
            </div>
            <div className="upload-btn-para-bottom">
              <p>Ready to take the next step in your career?</p>
              <p className='unable-to-find'>Unable to find the Job?</p>
              <div className='upload-your-dv'>
                <Link to="/career-at-eapro-form">Upload Your CV</Link>
              </div>
            </div>

          </div>


        </div>
      </section>
    </>
  )
}

export default CareerAtEaproThreeContent