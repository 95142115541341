import React from 'react'
import "./BussinessDivisionContent.css"
const host = process.env.REACT_APP_API_SERVER;
const BussinessDivisionContent = (businessDivisionData) => {


    return (
        <>
            <section className='business-division-content-section'>
                {businessDivisionData?.businessDivisionData?.length != "0" ?
                    <div className='container'>
                        {businessDivisionData?.businessDivisionData?.map((data, index) => (
                            <div className="row" key={data?.id}>
                                <div className="col-md-12">
                                    <div className="business-division-content-heading-dv">
                                        <h5>{data?.title}</h5>
                                    </div>
                                </div>
                                <div className="col-md-10 offset-md-1">
                                    <div className={`row dv-left-right-division-content-row align-items-start ${index % 2 !== 0 ? 'flex-row-reverse' : ''}`}>
                                        <div className="col-md-6">
                                            <div className={`dv-${index % 2 !== 0 ? 'right' : 'left'}-content`}>
                                                <p>{data?.description}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className={`dv-${index % 2 !== 0 ? 'right' : 'left'}-content`}>
                                                <img src={data?.image ? `${host}/${data?.image}` : ""} alt="" className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    :
                    ""}
            </section>
        </>
    )
}

export default BussinessDivisionContent