import React from 'react'
import "./GlobalFootprintsBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const GlobalFootprintsBanner = (bannerData) => {
    return (
        <>
            <section className='global-footprint-banner-section' >
                <div className="container">
                    <div className="row">
                        {bannerData?.bannerData?.globalFootprintTitle && <div className="col-md-12">
                            <div className="store-heading">
                                <img src="./images/global-footprints/heading-bg.png" alt="" />
                                <h5>{bannerData?.bannerData?.globalFootprintTitle}</h5>
                            </div>
                        </div>}
                        <div className="col-md-12">
                            <div className="banner-img-dv">
                                <img src={bannerData?.bannerData?.globalFootprintBanner ? `${host}${bannerData?.bannerData?.globalFootprintBanner}` : ""} alt="" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GlobalFootprintsBanner