import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import CareerAtEaproThreeBanner from './CareerAtEaproThreeBanner/CareerAtEaproThreeBanner'
import Navbar from '../../Header/Navbar'
import NavbarSecond from '../../Header/NavbarSecond'
import MobileNavbar from '../../Header/MobileNavbar'
import Footer from '../../Footer/Footer'
import CareerAtEaproThreeContent from './CareerAtEaproThreeContent/CareerAtEaproThreeContent'
import WhatEaproTeamMateSaySlider from './WhatEaproTeamMateSaySlider/WhatEaproTeamMateSaySlider'
import LifeAteaproSlider from './LifeAteaproSlider/LifeAteaproSlider'
import { getAllBannerData } from '../../../Redux/Action/Action';
const CareerAtEaproThree = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);



    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CareerAtEaproThreeBanner bannerData={bannerData} />
            <CareerAtEaproThreeContent />
            <WhatEaproTeamMateSaySlider bannerData={bannerData} />
            <LifeAteaproSlider />
            <Footer />
        </>
    )
}

export default CareerAtEaproThree