import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "./ECataloguePageContent.css"
import ECatalogueCard from './ECatalogueCard'
import { getAllBannerData, getCatalogueCategoryData, getCatalogueData } from '../../../Redux/Action/Action';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
const host = process.env.REACT_APP_API_SERVER;
const ECataloguePageContent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getAllBannerData());
        dispatch(getCatalogueCategoryData());

    }, []);

    const allBannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);
    const catalogueCategoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.catalogueCategoryDataList?.data) : []);

    const handleClick = (name) => {
        if (name != undefined) {

            navigate(`/sub-prduct-e-cataloge/${name}`)
        }
        else {
            toast.error('Id not found')
        }
    }

    return (
        <>
            <section className='e-catalogue-page-content-section'>
                <div className="container">
                    {/* ============================================================== */}
                    <div className="e-catelogue-before-row-parent-dv mb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="e-catalogue-heading">
                                    <h5>Our Comprehensive Resource Library</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='text-start'>{allBannerData?.catalogueDescription}</p>
                            </div>
                        </div>
                    </div>
                    {/* ============================================================== */}
                    {/* ============================================================== */}
                    {catalogueCategoryData?.map((catdata) => (
                        <div className="e-catelogue-before-row-parent-dv mb-5" key={catdata?.id}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="e-catalogue-heading">
                                        <h5>{catdata?.name}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                {catdata?.catalogue?.slice(0, 4)?.map((data) => (
                                    <div className="col-md-4 col-lg-3 my-2">
                                        <div className="catalogue-card-parent-dv">
                                            <div className="c-card-img">
                                                <img src={data?.image ? `${host}${data?.image}` : ""} alt="" className='img-fluid' />
                                            </div>
                                        </div>
                                        <div className="heading-down-icon">
                                            <div className="first-content-strip">
                                                <p><a href="#">{data?.title}</a></p>
                                            </div>
                                            <div className="second-content-strip">
                                                <p>
                                                    <a href={`${host}${data?.catalogue_file}`} target='blank'><img src="./images/e-catalogue/download-icon.png" alt="" /></a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {catdata?.catalogue?.length > 4 &&
                                    <div className="col-md-12 col-lg-12 col-12">
                                        <div className="view-more-btn-cat">
                                            <div onClick={() => { handleClick(catdata?.slug) }} style={{ cursor: 'pointer' }}> View All &gt; &gt;</div>
                                        </div>
                                    </div>}
                            </div>
                        </div>))
                    }

                </div>
            </section>
        </>
    )
}

export default ECataloguePageContent