import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Navigate, Route, Link } from 'react-router-dom';
import Home from './Component/HomePage/Home';
import AboutUs from './Component/AboutUSPageComponent/AboutUs';
import StoreLocatorPage from './Component/StoreLocatorPageComponent/StoreLocatorPage';
import GlobalFootprintsPage from './Component/GlobalFootprintsPage/GlobalFootprintsPage';
import BussinessDivisionsPage from './Component/BussinessDivisionsPage/BussinessDivisionsPage';
import ECataloguePage from './Component/ECataloguePage/ECataloguePage';
import HelpAndSupportPage from './Component/HelpAndSupportPage/HelpAndSupportPage';
import CareerPage from './Component/CareerPage/CareerPage';
import RegisterYourComplaintPage from './Component/RegisterYourComplaintPage/RegisterYourComplaintPage';
import PurchaseAMCPage from './Component/PurchaseAMCPage/PurchaseAMCPage';
import PurchaseAMCPage1 from './Component/PurchaseAMCPage1/PurchaseAMCPage1';
import PurchaseAMCPage3 from './Component/PurchaseAMCPage3/PurchaseAMCPage3';
import KnowledgeCentrePage from './Component/KnowledgeCentrePage/KnowledgeCentrePage';
import PowerSolutionPage from './Component/PowerSolutionPage/PowerSolutionPage';
import SolarSolutionPage from './Component/SolarSolutionPage/SolarSolutionPage';
import Blog from './Component/Blog/Blog';
import BlogDetails from './Component/BlogDetails/BlogDetails';
import EaproGlobalEnergy from './Component/EaproGlobalEnergy/EaproGlobalEnergy';
import TRCEfficientServices from './Component/TRCEfficientServices/TRCEfficientServices';
import EAPROOverseas from './Component/EAPROOverseas/EAPROOverseas';
import ServiceCenterLocatorPagePage from './Component/ServiceCenterLocatorPageComponent/ServiceCenterLocatorPagePage';
import InstallationHelpPage from './Component/InstallationHelpPage/InstallationHelpPage';
import ContactUsPage from './Component/ContactUsPage/ContactUsPage';
import EaproGlobalLtd from './Component/EaproGlobalLtd/EaproGlobalLtd';
import EfficientMechanicalSolar from './Component/EfficientMechanicalSolar/EfficientMechanicalSolar';
import EventPage from './Component/EventPage/EventPage';
import EventPageDetails from './Component/EventPageDetails/EventPageDetails';
import CareerAtEaproOne from './Component/CareerAtEapro/CareerAtEaproOne/CareerAtEaproOne';
import CareerAtEaproTwo from './Component/CareerAtEapro/CareerAtEaproTwo/CareerAtEaproTwo';
import CareerAtEaproThree from './Component/CareerAtEapro/CareerAtEaproThree/CareerAtEaproThree';
import ProductDetailsPage from './Component/ProductDetailsPage/ProductDetailsPage';
import DirectorMessageMainPage from './Component/DirectorMessagePage/DirectorMessageMainPage';
import ViewProductDetails from './Component/ViewProductDetails/ViewProductDetails';
import ViewProductSeriesListing from './Component/ViewProductSeriesListing/ViewProductSeriesListing';
import ViewSolarProductDetails from './Component/ViewSolarProductDetails/ViewSolarProductDetails';
import ViewSolarProductSeriesListing from './Component/ViewSolarProductSeriesListing/ViewSolarProductSeriesListing';
import PrivacyPolicyPage from './Component/PrivacyPolicyPage/PrivacyPolicyPage';
import { Toaster } from 'react-hot-toast';
import Csr from './Component/Csr/Csr';
import CsrDetails from './Component/Csr/CsrDetails/CsrDetails';
import LearnMoreDetails from './Component/LearnMore/LearnMoreDetails/LearnMoreDetails';
import LearnMore from './Component/LearnMore/LearnMore';
import ECatalogueSubProducttListing from './Component/ECataloguePage/ECataloguePageContent/ECatalogueSubProducttListing';
import ContactUsPageNew from './Component/ContactUsPageNew/ContactUsPageNew';
import ScrollToTop from './ScrollToTop';
import LeadingRandD from './Component/LeadingRandD/LeadingRandD';
import AdvancedTechnology from './Component/AdvancedTechnology/AdvancedTechnology';
import ManufacturingPower from './Component/ManufacturingPower/ManufacturingPower';
import QualityAssurance from './Component/QualityAssurance/QualityAssurance';
import AfterSalesService from './Component/AfterSalesService/AfterSalesService';
import PillarVideosDetails from './Component/AdvancedTechnology/PillarVideos/PillarVideosDetails/PillarVideosDetails';

import LoadCalculatorHome from './Component/HomePageComponent/DesignSolarPowerLoadCal/LoadCalculator/LoadCalculatorHome';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/about-us' element={<AboutUs />}></Route>
          <Route exact path='/store-locator' element={<StoreLocatorPage />}></Route>
          <Route exact path='/global-footprints' element={<GlobalFootprintsPage />}></Route>
          <Route exact path='/business-division' element={<BussinessDivisionsPage />}></Route>
          <Route exact path='/download' element={<ECataloguePage />}></Route>
          <Route exact path='/help-and-support' element={<HelpAndSupportPage />}></Route>
          <Route exact path='/career' element={<CareerPage />}></Route>
          <Route exact path='/register-your-complaint' element={<RegisterYourComplaintPage />}></Route>
          <Route exact path='/purchase-amc' element={<PurchaseAMCPage />}></Route>
          <Route exact path='/purchase-amc1' element={<PurchaseAMCPage1 />}></Route>
          <Route exact path='/purchase-amc3/:name' element={<PurchaseAMCPage3 />}></Route>
          <Route exact path='/knowledge-center' element={<KnowledgeCentrePage />}></Route>
          <Route exact path='/products/:name' element={<PowerSolutionPage />}></Route>
          {/* <Route exact path='/solar-solution' element={<SolarSolutionPage />}></Route> */}
          <Route exact path='/blog' element={<Blog />}></Route>
          <Route exact path='/blog-details/:name' element={<BlogDetails />}></Route>
          <Route exact path='/global-energy' element={<EaproGlobalEnergy />}></Route>
          <Route exact path='/global-energy-ltd' element={<EaproGlobalLtd />}></Route>
          <Route exact path='/trc-efficient-services' element={<TRCEfficientServices />}></Route>
          <Route exact path='/efficient-mechanical-solar' element={<EfficientMechanicalSolar />}></Route>
          <Route exact path='/eapro-overseas' element={<EAPROOverseas />}></Route>
          <Route exact path='/design-solar-system' element={<InstallationHelpPage />}></Route>
          <Route exact path='/contact-us' element={<ContactUsPageNew />}></Route>
          <Route exact path='/contact-us-new' element={<ContactUsPageNew />}></Route>
          <Route exact path='/service-center-locator' element={<ServiceCenterLocatorPagePage />}></Route>
          <Route exact path='/news-event' element={<EventPage />}></Route>
          <Route exact path='/news-event-details/:name' element={<EventPageDetails />}></Route>
          <Route exact path='/career-at-eapro-form' element={<CareerAtEaproOne />}></Route>
          <Route exact path='/career-at-eapro-details/:name' element={<CareerAtEaproTwo />}></Route>
          <Route exact path='/career-at-eapro' element={<CareerAtEaproThree />}></Route>
          <Route exact path='/product-details/:name' element={<ProductDetailsPage />}></Route>
          <Route exact path='/director-message/:name' element={<DirectorMessageMainPage />}></Route>
          <Route exact path='/view-product/:name' element={<ViewProductDetails />}></Route>
          <Route exact path='/view-product-series-listing/:name' element={<ViewProductSeriesListing />}></Route>
          <Route exact path='/view-solar-product' element={<ViewSolarProductDetails />}></Route>
          <Route exact path='/view-solar-product-series-listing' element={<ViewSolarProductSeriesListing />}></Route>
          <Route exact path='/csr' element={<Csr />}></Route>
          <Route exact path='/csr-details/:name' element={<CsrDetails />}></Route>
          {/* <Route exact path='/learn-more' element={<LearnMore />}></Route> */}
          {/* <Route exact path='/learn-more-details/:name' element={<LearnMoreDetails />}></Route> */}

          {/* <Route exact path='/pillar-videos-details' element={<PillarVideosDetails />}></Route> */}
          <Route exact path='/policies/:name' element={<PrivacyPolicyPage />}></Route>
          <Route exact path='/sub-prduct-e-cataloge/:name' element={<ECatalogueSubProducttListing />}></Route>
          {/* <Route exact path='/leading-research' element={<LeadingRandD />}></Route> */}
          <Route exact path='/pillars/:name' element={<AdvancedTechnology />}></Route>
          <Route exact path='/pillar-video-details/:name' element={<PillarVideosDetails />}></Route>
          <Route exact path='/why-eapro-detail' element={<ManufacturingPower />}></Route>
          <Route exact path='/learn-more' element={<QualityAssurance />}></Route>
          {/* <Route exact path='/after-sales-service' element={<AfterSalesService />}></Route> */}
          <Route exact path='/load-calculator' element={<LoadCalculatorHome />}></Route>

        </Routes>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        <ScrollToTop />
      </div>
    </Router>
  );
}

export default App;
