let recruiterInitialState = {
    aboutUsDataList: [],
    ourSpecializationDataList: [],
    eaproLtdDataList: [],
    eaproEnergyDataList: [],
    eaproOverseasDataList: [],
    efficientMechanicalDataList: [],
    trcEfficientDataList: [],
    homeBannerDataList: [],
    pillarsDataList: [],
    productVideoDataList: [],
    businessDivisionDataList: [],
    homeSecondSecDataList: [],
    whyEaproDataList: [],
    catalogueDataList: [],
    lifeAtEaproDataList: [],
    blogDataList: [],
    newsDataList: [],
    achievementDataList: [],
    contactDetailsDataList: [],
    newsCategoryDataList: [],
    blogCategoryDataList: [],
    homeEightSectionDataList: [],
    testimonialDataList: [],
    jobListDataList: [],
    allBannerDataList: [],
    aboutExpertDataList: [],
    founderDirectorDataList: [],
    ourTeamDataList: [],
    subCategoryDataList: [],
    childCategoryDataList: [],
    productListDataList: [],
    founderDirectorDataParamsList: [],
    EaproSummaryDataList: [],
    categoryDataList: [],
    categoryParamsDataList: [],
    productImgDataList: [],
    productDataList: [],
    jobDataList: [],
    CmsDataList: [],
    CsrDataList: [],
    LearnMoreDataList: [],
    CustomerFeedbackDataList: [],
    catalogueCategoryDataList: [],
    productSearchDataList: [],
    RatingChartDataList: [],
    PwrCategoryMenuDataList: [],
    SlrCategoryMenuDataList: [],
    pillarsParamsDataList: [],
    pillarsVideoDataList: [],
    BlogParamDataList: [],
    NewsParamDataList: [],
    categoryOnIdDataList: [],
    referenceDataList: [],
    countryDataList: [],
    stateDataList: [],
    cityDataList: [],
    CsrOneDataList: [],
    LearnMoreListDataList: [],
    productExtendedWarrList: [],
    ApplianceDataList: [],
    ApplianceCategoryDataList: [],
    storeLocatorDataList: [],
    serviceCenterLocatorDataList: [],

}
const Reducer = (state = recruiterInitialState, action) => {
    switch (action.type) {
        case "SET_ABOUT_US_DATA":
            return {
                ...state,
                aboutUsDataList: action.payload
            }
        case "SET_OUR_SPECIALIZATION_DATA":
            return {
                ...state,
                ourSpecializationDataList: action.payload
            }
        case "SET_EAPRO_LTD_DATA":
            return {
                ...state,
                eaproLtdDataList: action.payload
            }
        case "SET_EAPRO_ENERGY_DATA":
            return {
                ...state,
                eaproEnergyDataList: action.payload
            }
        case "SET_EAPRO_OVERSEAS_DATA":
            return {
                ...state,
                eaproOverseasDataList: action.payload
            }
        case "SET_EFFICIENT_MECHANICAL_DATA":
            return {
                ...state,
                efficientMechanicalDataList: action.payload
            }
        case "SET_TRC_EFFICIENT_DATA":
            return {
                ...state,
                trcEfficientDataList: action.payload
            }
        case "SET_HOME_BANNER_DATA":
            return {
                ...state,
                homeBannerDataList: action.payload
            }
        case "SET_PILLARS_DATA":
            return {
                ...state,
                pillarsDataList: action.payload
            }
        case "SET_PRODUCTVIDEO_DATA":
            return {
                ...state,
                productVideoDataList: action.payload
            }
        case "SET_BUSINESS_DIVISION_DATA":
            return {
                ...state,
                businessDivisionDataList: action.payload
            }
        case "SET_HOME_SECOND_SECTION_DATA":
            return {
                ...state,
                homeSecondSecDataList: action.payload
            }
        case "SET_WHY_EAPRO_DATA":
            return {
                ...state,
                whyEaproDataList: action.payload
            }
        case "SET_CATALOGUE_DATA":
            return {
                ...state,
                catalogueDataList: action.payload
            }
        case "SET_LIFE_AT_EAPRO_DATA":
            return {
                ...state,
                lifeAtEaproDataList: action.payload
            }
        case "SET_STORE_LOCATOR_DATA":
            return {
                ...state,
                storeLocatorDataList: action.payload
            }
        case "SET_BLOG_DATA":
            return {
                ...state,
                blogDataList: action.payload
            }
        case "SET_NEWS_DATA":
            return {
                ...state,
                newsDataList: action.payload
            }
        case "SET_ACHIEVEMENT_DATA":
            return {
                ...state,
                achievementDataList: action.payload
            }
        case "SET_CONTACT_DETAILS_DATA":
            return {
                ...state,
                contactDetailsDataList: action.payload
            }
        case "SET_NEWS_CATEGORY_DATA":
            return {
                ...state,
                newsCategoryDataList: action.payload
            }
        case "SET_BLOG_CATEGORY_DATA":
            return {
                ...state,
                blogCategoryDataList: action.payload
            }
        case "SET_HOME_EIGHT_SECTION_DATA":
            return {
                ...state,
                homeEightSectionDataList: action.payload
            }
        case "SET_TESTIMONIAL_DATA":
            return {
                ...state,
                testimonialDataList: action.payload
            }
        case "SET_JOB_LIST_DATA":
            return {
                ...state,
                jobListDataList: action.payload
            }
        case "SET_ALL_BANNER_DATA":
            return {
                ...state,
                allBannerDataList: action.payload
            }

        case "SET_ABOUT_EXPERT_DATA":
            return {
                ...state,
                aboutExpertDataList: action.payload
            }

        case "SET_SERVICE_CENTER_LOCATOR_DATA":
            return {
                ...state,
                serviceCenterLocatorDataList: action.payload
            }
        case "SET_FOUNDER_DIRECTOR_DATA":
            return {
                ...state,
                founderDirectorDataList: action.payload
            }
        case "SET_OUR_TEAM_DATA":
            return {
                ...state,
                ourTeamDataList: action.payload
            }
        case "SET_SUB_CATEGORY_DATA":
            return {
                ...state,
                subCategoryDataList: action.payload
            }
        case "SET_CHILD_CATEGORY_DATA":
            return {
                ...state,
                childCategoryDataList: action.payload
            }
        case "SET_PRODUCT_LIST_DATA":
            return {
                ...state,
                productListDataList: action.payload
            }
        case "SET_FOUNDER_DIRECTOR_PARAMS_DATA":
            return {
                ...state,
                founderDirectorDataParamsList: action.payload
            }
        case "SET_EAPRO_SUMMARY_DATA":
            return {
                ...state,
                EaproSummaryDataList: action.payload
            }
        case "SET_CATEGORY_DATA":
            return {
                ...state,
                categoryDataList: action.payload
            }
        case "SET_CATEGORY_PARAMS_DATA":
            return {
                ...state,
                categoryParamsDataList: action.payload
            }
        case "SET_PRODUCT_IMG_DATA":
            return {
                ...state,
                productImgDataList: action.payload
            }
        case "SET_PRODUCT_DATA":
            return {
                ...state,
                productDataList: action.payload
            }
        case "SET_JOB_DATA":
            return {
                ...state,
                jobDataList: action.payload
            }
        case "SET_CMS_DATA":
            return {
                ...state,
                CmsDataList: action.payload
            }
        case "SET_CSR_DATA":
            return {
                ...state,
                CsrDataList: action.payload
            }
        case "SET_LEARN_MORE_DATA":
            return {
                ...state,
                LearnMoreDataList: action.payload
            }
        case "SET_CUSTOMER_FEEDBACK_DATA":
            return {
                ...state,
                CustomerFeedbackDataList: action.payload
            }
        case "SET_CATALOGUE_CAT_DATA":
            return {
                ...state,
                catalogueCategoryDataList: action.payload
            }
        case "SET_PRODUCT_SEARCH_DATA":
            return {
                ...state,
                productSearchDataList: action.payload
            }
        case "SET_RATING_CHART_DATA":
            return {
                ...state,
                RatingChartDataList: action.payload
            }
        case "SET_PWR_CATEGORY_MENU_DATA":
            return {
                ...state,
                PwrCategoryMenuDataList: action.payload
            }
        case "SET_SLR_CATEGORY_MENU_DATA":
            return {
                ...state,
                SlrCategoryMenuDataList: action.payload
            }
        case "SET_PILLARS_PARAMS_DATA":
            return {
                ...state,
                pillarsParamsDataList: action.payload
            }
        case "SET_PILLARS_VIDEO_DATA":
            return {
                ...state,
                pillarsVideoDataList: action.payload
            }
        case "SET_BLOG_PARAM_DATA":
            return {
                ...state,
                BlogParamDataList: action.payload
            }
        case "SET_NEWS_PARAM_DATA":
            return {
                ...state,
                NewsParamDataList: action.payload
            }
        case "SET_CATEGORY_ON_ID_DATA":
            return {
                ...state,
                categoryOnIdDataList: action.payload
            }
        case "SET_REFERENCE_DATA":
            return {
                ...state,
                referenceDataList: action.payload
            }
        case "SET_COUNTRY_DATA":
            return {
                ...state,
                countryDataList: action.payload
            }
        case "SET_STATE_DATA":
            return {
                ...state,
                stateDataList: action.payload
            }
        case "SET_CITY_DATA":
            return {
                ...state,
                cityDataList: action.payload
            }
        case "SET_CSR_ONE_DATA":
            return {
                ...state,
                CsrOneDataList: action.payload
            }
        case "SET_LEARN_MORE_LIST_DATA":
            return {
                ...state,
                LearnMoreListDataList: action.payload
            }
        case "SET_PRODUCT_EXTENDED_WARR_DATA":
            return {
                ...state,
                productExtendedWarrList: action.payload
            }
        case "SET_APPLIANCE_CATEGORY_DATA":
            return {
                ...state,
                ApplianceCategoryDataList: action.payload
            }
        case "SET_APPLIANCE_DATA":
            return {
                ...state,
                ApplianceDataList: action.payload
            }
        default:
            return state;
    }
}

export default Reducer