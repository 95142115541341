import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./ECatalogueBannerNew.css"
import { getAllBannerData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const ECatalogueCategoryBannerNew = (cname) => {




    return (
        <>
            <section className='e-catalogue-banner-page-banner-section'>
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel-parent">
                            <div className="banner-top-img-bg-rel">
                                <img src={cname?.banner ? `${host}/${cname?.banner}` : ""} alt="" className='img-fluid banner-img-tag' />
                                {/* <div className="banner-top-content-absol business-heading text-start">
                                    <img src="/images/help-and-support/heading-bg.png" alt="" className='img-fluid' />
                                    <div className='business-banner-heading'>
                                        <div className="container">
                                            <h1>{cname?.cname}</h1>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ECatalogueCategoryBannerNew