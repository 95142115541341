import React from 'react'
import parse from 'html-react-parser'
import "./PrivacyPolicyPageContent.css"
const PrivacyPolicyPageContent = (CmsData) => {
    return (
        <>
            <section className='privacy-policy-section'>
                <div className="container">
                    <h1>{CmsData?.CmsData?.name}</h1>
                    {CmsData?.CmsData?.description ? parse(CmsData?.CmsData?.description) : ""}
                </div>
            </section>

        </>
    )
}

export default PrivacyPolicyPageContent