import React from 'react'
import "./AdvancedTechnology.css"
const host = process.env.REACT_APP_API_SERVER;
const AdvancedTechnologyBanner = (pillarsData) => {
  const data = pillarsData?.pillarsData
  return (
    <>
      <section className='advanced-technology-banner-sec'>
        <img src={data?.banner ? `${host}${data?.banner}` : ""} alt="" className='img-fluid' />
      </section>
    </>
  )
}

export default AdvancedTechnologyBanner