import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser'
import CountUp from 'react-countup';
import "./AboutExpert.css"
import { getAboutExpertData } from '../../../Redux/Action/Action';
const AboutExpert = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAboutExpertData());

    }, []);

    const aboutExpertData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.aboutExpertDataList?.data) : []);

    return (
        <>
            <section className='about-export-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="why-heading-dv">
                                <h5>About Expert</h5>
                            </div>
                        </div>
                        <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1">
                            <div className="para-content">
                                {aboutExpertData ? parse(aboutExpertData?.summary) :

                                    <p>EAPRO is a pioneering multinational company committed to providing cutting-edge environmental and sustainable solutions to address pressing global challenges. Founded in 2012, EAPRO has swiftly emerged as a key player in the realm of eco-friendly technologies, resource management, and sustainable practices. With a vision to create a greener and more sustainable future for generations to come, EAPRO's mission is centered around driving positive change through innovation, collaboration, and responsible business practices. </p>}
                            </div>
                        </div>
                        <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1">
                            <div className="row counter-row">
                                <div className="col-md-4">
                                    <div className="card-box-counter">
                                        <h4><CountUp duration={5.00} end={aboutExpertData?.box_1_count ? aboutExpertData?.box_1_count : 7} /></h4>

                                        <p>{aboutExpertData?.box_1_name ? aboutExpertData?.box_1_name : "Continents"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card-box-counter bg-yellow">
                                        <h4><CountUp duration={5.00} end={aboutExpertData?.box_2_count ? aboutExpertData?.box_2_count : 22} /> +</h4>
                                        <p>{aboutExpertData?.box_2_name ? aboutExpertData?.box_2_name : "Countries"}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card-box-counter">
                                        <h4><CountUp duration={5.00} end={aboutExpertData?.box_3_count ? aboutExpertData?.box_3_count : 10} /> M+</h4>
                                        <p>{aboutExpertData?.box_3_name ? aboutExpertData?.box_3_name : "Products Delivered "}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutExpert