import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ViewProductDetailsContent from './ViewProductDetailsContent'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import PowerSolutionPageBanner from '../PowerSolutionPage/PowerSolutionPageBanner/PowerSolutionPageBanner'
import { getCategoryOnIdData, getCategoryParamsData, getChildCategoryData } from '../../Redux/Action/Action';
import SolarSolutionPageBanner from '../SolarSolutionPage/SolarSolutionPageBanner/SolarSolutionPageBanner';

const ViewProductDetails = () => {

    const dispatch = useDispatch();
    const { name } = useParams();


    useEffect(() => {

        dispatch(getChildCategoryData(name))
    }, []);

    // const categoryParamsData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.categoryParamsDataList?.data) : []);
    const childCategoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.childCategoryDataList?.data) : []);
    const catId = childCategoryData?.[0]?.catId

    useEffect(() => {
        if (catId !== undefined) {
            dispatch(getCategoryOnIdData(catId));
        }
    }, [dispatch, catId]);
    const categoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.categoryOnIdDataList?.data) : []);


    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />

            <PowerSolutionPageBanner categoryData={categoryData} />


            <ViewProductDetailsContent childCategoryData={childCategoryData} />
            <Footer />
        </>
    )
}

export default ViewProductDetails