import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom';
import './BlogContent.css'
import './ResponsiveBlogContent.css'
import BlogContentCard from './BlogContentCard'
import { getBlogCategoryData, getBlogData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const BlogContent = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBlogCategoryData());
        dispatch(getBlogData());
    }, []);

    const blogCategoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.blogCategoryDataList?.data) : []);
    const blogData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.blogDataList?.data) : []);
    const navigate = useNavigate();
    const handleClick = (blogId) => {

        if (blogId !== undefined) {
            navigate(`/blog-details/${blogId}`)
        }


    }
    return (
        <>
            {(blogCategoryData) ?
                <section className='blog-page-content-section'>
                    <div className="container">
                        {/* ------------------------------------------------ */}
                        <ul className="nav nav-tabs blog-nav-tabs" id="myTab" role="tablist">
                            {blogCategoryData?.map((category, index) => (
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${index === 0 ? "active" : ""}`} id={`${category.id}-tab`} data-bs-toggle="tab" data-bs-target={`#category${category.id}`} type="button" role="tab" aria-controls={`category${category.id}`} aria-selected={index === 0}>{category.name}</button>
                                </li>
                            ))}
                        </ul>
                        <div className="tab-content blog-tab-content" id="myTabContent">
                            {blogCategoryData.map((category, index) => {
                                const filteredBlogs = blogData?.filter(blog => blog.categoryId === category.id.toString());
                                return (

                                    <div className={`tab-pane fade ${index === 0 ? "show active" : ""}`} id={`category${category.id}`} role="tabpanel" aria-labelledby={`${category.id}-tab`} key={category.id}>
                                        <div className="row">
                                            {filteredBlogs?.map(blog => (

                                                <div className="col-md-4 mb-5 text-decoration-none" onClick={() => { handleClick(blog?.slug) }} style={{ cursor: "pointer" }}>
                                                    <div className="card">
                                                        <img src={blog?.image ? `${host}${blog?.image}` : ""} className="card-img-top" alt="..." />

                                                        <div className="card-body">
                                                            <h5>{blog?.title}</h5>
                                                            <p className="card-text border-0">
                                                                {parse(blog?.summary)}
                                                            </p>
                                                            <p><i>{new Date(blog?.createdAt).toDateString()}</i></p>
                                                        </div>

                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                        {/* ------------------------------------------------ */}

                    </div>
                </section>
                :
                ""}
        </>
    )
}

export default BlogContent