import React from 'react'
import parse from 'html-react-parser';

import "./AboutUsContentDark.css"


const host = process.env.REACT_APP_API_SERVER;
const AboutUsContentDark = (aboutUsData) => {
    return (
        <>
            <section className='about-us-content-dark-section'>
                <div className='container-fluid container-xxl'>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="left-images-dv">
                                <img src={aboutUsData?.aboutUsData?.about_us_image ? `${host}/${aboutUsData?.aboutUsData?.about_us_image}` : ""} alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="right-about-content-dv">
                                <div className="about-heading-dv">
                                    <h5>About Us</h5>
                                </div>
                                <div className="about-content">
                                    {aboutUsData?.aboutUsData?.about_us_content ? parse(aboutUsData?.aboutUsData?.about_us_content) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUsContentDark