import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import CareerAtEaproOneBanner from './CareerAtEaproOneBanner/CareerAtEaproOneBanner'
import Navbar from '../../Header/Navbar'
import NavbarSecond from '../../Header/NavbarSecond'
import MobileNavbar from '../../Header/MobileNavbar'
import Footer from '../../Footer/Footer'
import CareerAtEaproOneContent from './CareerAtEaproOneContent/CareerAtEaproOneContent'
import { getAllBannerData } from '../../../Redux/Action/Action';
const CareerAtEaproOne = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);



    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CareerAtEaproOneBanner bannerData={bannerData} />
            <CareerAtEaproOneContent />
            <Footer />
        </>
    )
}

export default CareerAtEaproOne