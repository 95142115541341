import { React, useState, useEffect } from 'react'
import "./InstallationHelpPageContent.css"
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import { Link } from 'react-router-dom'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { getContactDetailsData, getReferenceData } from '../../../Redux/Action/Action';
import { useDispatch, useSelector } from 'react-redux';
const host = process.env.REACT_APP_API_SERVER;
const InstallationHelpPageContent = () => {

    const dispatch = useDispatch();


    useEffect(() => {

        dispatch(getReferenceData());
        dispatch(getContactDetailsData());

    }, []);


    const referenceData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.referenceDataList?.data) : []);
    const contactDetailsData = useSelector((state) => state?.Reducer?.contactDetailsDataList?.data || []);

    const [cphone, setPhone] = useState('');
    // Remove non-digit characters
    const cleanPhoneNumber = cphone.replace(/\D/g, '');

    // Check if there are digits after the fourth digit
    const hasDigitsAfterFourth = cleanPhoneNumber.length > 8;

    const triggerModalOpen = () => {
        const closeButton = document.getElementById('modal-button');
        if (closeButton) {
            closeButton.click();
        }

    };

    const triggerModalClose = () => {
        const closeButton = document.getElementById('close-sidebar');
        if (closeButton) {
            closeButton.click();
        }

    };



    let [initialValues, setInitialValues] = useState({
        firstname: "",
        lastname: "",
        phone: "",
        email: "",

        city: "",
        street: "",
        state: "",
        zipcode: "",
        typeOfProperty: "",
        propertySize: "",
        installationArea: "",
        otherInstallationArea: "",
        availableSpace: "",
        energyConsumption: "",
        monthlyElectricBill: "",
        currentElectricityProvider: "",
        existingSolarPanel: "",
        existingSolarPanelDetail: "",
        preferredSolarInverterTech: "",
        preferredSolarInverterTechDetail: "",
        preferredSolarPanelType: "",
        preferredSolarPanelTypeDetail: "",
        energyStorageOption: "",
        preferredBatteryType: "",
        systemSize: "",
        preferredFinancingOption: "",
        interestedInIncentives: "",
        preferredInstallationTimeline: "",
        specificConcern: "",
        howHeardAbout: "",
        share_data: "",





    })

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().matches(/^[A-Za-z]+$/, "Only alphabets are allowed for this field").required('This field is required'),
        email: Yup.string().email('Invalid email address').required('This field is required'),

        city: Yup.string().matches(/^[A-Za-z]+$/, "Only alphabets are allowed for this field").required('This field is required'),
        street: Yup.string().required('This field is required'),
        state: Yup.string().matches(/^[A-Za-z]+$/, "Only alphabets are allowed for this field").required('This field is required'),
        zipcode: Yup.string()
            .matches(/^\d{4,6}$/, 'Enter a valid pin code.').required('This field is required'),
        typeOfProperty: Yup.string().required('This field is required'),
        propertySize: Yup.string().required('This field is required'),
        installationArea: Yup.string().required('This field is required'),
        availableSpace: Yup.string().required('This field is required'),
        otherInstallationArea: Yup.string().notRequired(),
        energyConsumption: Yup.string().required('This field is required'),
        monthlyElectricBill: Yup.string().required('This field is required'),
        currentElectricityProvider: Yup.string().required('This field is required'),
        existingSolarPanel: Yup.string().required('This field is required'),
        existingSolarPanelDetail: Yup.string().notRequired(),
        preferredSolarInverterTech: Yup.string().required('This field is required'),
        preferredSolarInverterTechDetail: Yup.string().notRequired(),
        preferredSolarPanelType: Yup.string().required('This field is required'),
        preferredSolarPanelTypeDetail: Yup.string().notRequired(),
        energyStorageOption: Yup.string().required('This field is required'),
        preferredBatteryType: Yup.string().notRequired(),
        systemSize: Yup.string().required('This field is required'),
        preferredFinancingOption: Yup.string().required('This field is required'),
        interestedInIncentives: Yup.string().required('This field is required'),
        preferredInstallationTimeline: Yup.string().required('This field is required'),
        howHeardAbout: Yup.string().required('This field is required'),
        share_data: Yup.bool().oneOf([true], 'You must agree to be contacted').required('You must agree to be contacted'),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        validate: (values) => {
            const errors = {};


            if (values.existingSolarPanel === '1' && !values.existingSolarPanelDetail) {
                errors.existingSolarPanelDetail = 'Existing Solar Panel Detail is required';

            }


            if (values.installationArea === 'Other' && !values.otherInstallationArea) {
                errors.otherInstallationArea = 'Other Installation Area is required';

            }
            if (values.preferredSolarInverterTech === 'Other' && !values.preferredSolarInverterTechDetail) {
                errors.preferredSolarInverterTechDetail = 'Preferred Solar Inverter Technology Detail is required';

            }
            if (values.preferredSolarPanelType === 'Other' && !values.preferredSolarPanelTypeDetail) {
                errors.preferredSolarPanelTypeDetail = 'Preferred Solar Panel Type Detail is required';

            }
            if (values.energyStorageOption === '1' && !values.preferredBatteryType) {
                errors.preferredBatteryType = 'Preferred Battery Type is required';

            }

            return errors;
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {


            try {

                setSubmitting(true);


                document.body.style.overflow = 'hidden';

                values.phone = cphone
                await axios.post(`${host}/web/installation-help`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            // toast.success("Form Submitted Succesfully");
                            setPhone('');
                            resetForm();
                            triggerModalOpen();
                            // const myTimeout = setTimeout(triggerModalClose, 3000);

                        } else {

                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.error(response.data.msg);

                            resetForm()
                        }
                    })
                    .catch(function (error) {

                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.error(error.response.data.msg);

                        console.log(error)
                    })

            }
            catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.error('Error submitting form. Please try again.');


            }

        }
    })



    return (
        <>
            <section className='installation-help-page-content-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="help-support-heading mb-5">
                                <h5>Thank you for your interest in our solar installation services! Please fill out the form below so we can better understand your needs and provide you with a tailored solution. Your information will be kept confidential.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="form-parent-custon-dv">
                        <form className="row g-3 custom-row-form form-container" method='post' onSubmit={formik.handleSubmit}>

                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    First Name<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="full-name"
                                    defaultValue=""
                                    required=""
                                    placeholder='Enter First Name'
                                    name='firstname'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstname}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.firstname && formik.errors.firstname ? (
                                        <div className='text-danger'>{formik.errors.firstname}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="full-name"
                                    defaultValue=""
                                    required=""
                                    placeholder='Enter Last Name'
                                    name='lastname'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastname}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email-id" className="form-label">
                                    Email Address<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email-id"
                                    defaultValue=""
                                    required=""
                                    placeholder='Enter Email Address'
                                    name='email'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className='text-danger'>{formik.errors.email}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="contact-details" className="form-label">
                                    Phone Number<span className='text-danger'>*</span>
                                </label>
                                <PhoneInput
                                    placeholder='Phone number'
                                    required
                                    defaultCountry="in"
                                    value={cphone}
                                    onChange={(cphone) => setPhone(cphone)}

                                />
                                <div className="ms-2 help-block with-errors">
                                    {!hasDigitsAfterFourth && (
                                        <div className="text-danger">Phone number is required</div>
                                    )}
                                </div>
                            </div>



                            <div className="col-md-12 py-2 label-background  ">
                                <label className="form-label mb-0 pb-0">
                                    Address<span className='text-danger'>*</span>
                                </label>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    required=""
                                    placeholder='Enter Street'
                                    name='street'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.street}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.street && formik.errors.street ? (
                                        <div className='text-danger'>{formik.errors.street}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    required=""
                                    placeholder='Enter City'
                                    name='city'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.city && formik.errors.city ? (
                                        <div className='text-danger'>{formik.errors.city}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    required=""
                                    placeholder='Enter State'
                                    name='state'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.state}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.state && formik.errors.state ? (
                                        <div className='text-danger'>{formik.errors.state}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    required=""
                                    placeholder='Enter Pin Code'
                                    name='zipcode'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.zipcode}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.zipcode && formik.errors.zipcode ? (
                                        <div className='text-danger'>{formik.errors.zipcode}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-12 py-2 label-background ">
                                <label htmlFor="" className="form-label mb-0 pb-0">
                                    Property Details
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Type of property<span className='text-danger'>*</span>
                                </label>
                                <select
                                    className="form-select"
                                    id="validationServerstate"
                                    aria-describedby=""
                                    required=""

                                    name='typeOfProperty'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.typeOfProperty}
                                >
                                    <option selected="" disabled="" value="">
                                        Type of property
                                    </option>
                                    <option>Residential</option>
                                    <option>Commercial</option>
                                    <option>Industrial</option>
                                    <option>Agricultural</option>
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.typeOfProperty && formik.errors.typeOfProperty ? (
                                        <div className='text-danger'>{formik.errors.typeOfProperty}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="propertySize" className="form-label">
                                    Property Size (in square feet)<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="propertySize"
                                    defaultValue=""
                                    required=""
                                    placeholder='Property Size'
                                    name='propertySize'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.propertySize}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.propertySize && formik.errors.propertySize ? (
                                        <div className='text-danger'>{formik.errors.propertySize}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Installation Area<span className='text-danger'>*</span>
                                </label>
                                <select
                                    className="form-select"
                                    id="validationServerstate"
                                    aria-describedby=""
                                    required=""
                                    name='installationArea'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.installationArea}
                                >
                                    <option selected="" disabled="" value="">
                                        Installation Area
                                    </option>
                                    <option>Roof</option>
                                    <option>Land</option>
                                    <option>Other</option>
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.installationArea && formik.errors.installationArea ? (
                                        <div className='text-danger'>{formik.errors.installationArea}</div>
                                    ) : null}
                                </div>
                            </div>
                            {formik.values.installationArea === "Other" &&
                                <div className="col-md-6">
                                    <label htmlFor="full-name" className="form-label">
                                        Please specify<span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="complaint-details"
                                        defaultValue=""
                                        placeholder="Other Installation Area"
                                        name='otherInstallationArea'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.otherInstallationArea}
                                    />
                                    <div className="help-block with-errors">
                                        {formik.touched.otherInstallationArea && formik.errors.otherInstallationArea ? (
                                            <div className='text-danger'>{formik.errors.otherInstallationArea}</div>
                                        ) : null}
                                    </div>
                                </div>}
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Available Space for Solar Panels (in square feet)
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="availableSpace"
                                    defaultValue=""
                                    required=""
                                    placeholder='Enter Available Space for Solar Panels (in square feet)'
                                    name='availableSpace'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.availableSpace}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.availableSpace && formik.errors.availableSpace ? (
                                        <div className='text-danger'>{formik.errors.availableSpace}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12 py-2 label-background ">
                                <label htmlFor="" className="form-label mb-0 pb-0">
                                    Energy Usage
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Last 1 Year Average Monthly Electricity Consumption in Unit<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="full-name"
                                    defaultValue=""
                                    required=""
                                    placeholder='Enter Last 1 Year Average Monthly Electricity Consumption in Unit '
                                    name='energyConsumption'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.energyConsumption}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.energyConsumption && formik.errors.energyConsumption ? (
                                        <div className='text-danger'>{formik.errors.energyConsumption}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Last 1 Year Average Monthly Electricity Bill in INR<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="full-name"
                                    defaultValue=""
                                    required=""
                                    placeholder='Enter Last 1 Year Average Monthly Electricity Bill in INR '
                                    name='monthlyElectricBill'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.monthlyElectricBill}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.monthlyElectricBill && formik.errors.monthlyElectricBill ? (
                                        <div className='text-danger'>{formik.errors.monthlyElectricBill}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Current Electricity Provider<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="full-name"
                                    defaultValue=""
                                    required=""
                                    placeholder='Enter Current Electricity Provider '
                                    name='currentElectricityProvider'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.currentElectricityProvider}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.currentElectricityProvider && formik.errors.currentElectricityProvider ? (
                                        <div className='text-danger'>{formik.errors.currentElectricityProvider}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Do you have any existing solar panels installed?<span className='text-danger'>*</span>
                                </label>
                                <div>
                                    <input
                                        type="radio"
                                        id='existingSolarPanel'

                                        value={1}
                                        checked={formik.values.existingSolarPanel === '1'}
                                        name='existingSolarPanel'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                    /><label htmlFor='existingSolarPanel' className='mx-2'>Yes</label>
                                </div>
                                <div>
                                    <input type="radio"
                                        id='existingSolarPanel2'

                                        value={0}
                                        name='existingSolarPanel'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        checked={formik.values.existingSolarPanel === '0'}
                                    /><label htmlFor='existingSolarPanel2' className='mx-2'>No</label>
                                </div>
                                <div className="help-block with-errors">
                                    {formik.touched.existingSolarPanel && formik.errors.existingSolarPanel ? (
                                        <div className='text-danger'>{formik.errors.existingSolarPanel}</div>
                                    ) : null}
                                </div>
                            </div>
                            {formik.values.existingSolarPanel == "1" &&
                                <div className="col-md-6">
                                    <label htmlFor="complaint-details" className="form-label">
                                        Please provide details of inverters, panels and batteries<span className='text-danger'>*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="complaint-details"
                                        defaultValue=""
                                        required=""
                                        name='existingSolarPanelDetail'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.existingSolarPanelDetail}
                                    ></textarea>
                                    <div className="help-block with-errors">
                                        {formik.touched.existingSolarPanelDetail && formik.errors.existingSolarPanelDetail ? (
                                            <div className='text-danger'>{formik.errors.existingSolarPanelDetail}</div>
                                        ) : null}
                                    </div>
                                </div>}
                            <div className="col-md-12 py-2 label-background ">
                                <label htmlFor="" className="form-label mb-0 pb-0">
                                    Solar System Preferences
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Preferred Solar Inverter Technology<span className='text-danger'>*</span>
                                </label>
                                <select
                                    className="form-select"
                                    id="validationServerstate"
                                    aria-describedby=""
                                    required=""
                                    name='preferredSolarInverterTech'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.preferredSolarInverterTech}
                                >
                                    <option selected="" disabled="" value="">
                                        Select Preferred Solar Inverter Technology
                                    </option>
                                    <option>Off-Grid</option>
                                    <option>Off-Grid Hybrid</option>
                                    <option>On-Grid</option>
                                    <option>On-Grid Hybrid</option>
                                    <option>Other</option>
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.preferredSolarInverterTech && formik.errors.preferredSolarInverterTech ? (
                                        <div className='text-danger'>{formik.errors.preferredSolarInverterTech}</div>
                                    ) : null}
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                   Other
                                </label>
                                <div>
                                    <input
                                        type="radio"
                                        id='preferredSolarInverterTech'

                                        value={1}
                                        name='preferredSolarInverterTech'
                                       
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                    /><label htmlFor='preferredSolarInverterTech' className='mx-2'>Yes</label>
                                </div>
                                <div>
                                    <input type="radio"
                                        id='preferredSolarInverterTech2'

                                        value={0}
                                        name='preferredSolarInverterTech'
                                       
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                    /><label htmlFor='preferredSolarInverterTech2' className='mx-2'>No</label>
                                </div>
                            </div> */}
                            {formik.values.preferredSolarInverterTech === "Other" &&
                                <div className="col-md-6">
                                    <label htmlFor="full-name" className="form-label">
                                        Please provide details<span className='text-danger'>*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="preferredSolarInverterTechDetail"
                                        defaultValue=""
                                        required=""
                                        name='preferredSolarInverterTechDetail'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.preferredSolarInverterTechDetail}
                                    ></textarea>
                                    <div className="help-block with-errors">
                                        {formik.touched.preferredSolarInverterTechDetail && formik.errors.preferredSolarInverterTechDetail ? (
                                            <div className='text-danger'>{formik.errors.preferredSolarInverterTechDetail}</div>
                                        ) : null}
                                    </div>
                                </div>}
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Preferred Solar Panel Type<span className='text-danger'>*</span>
                                </label>
                                <select
                                    className="form-select"
                                    id="validationServerstate"
                                    aria-describedby=""
                                    required=""
                                    name='preferredSolarPanelType'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.preferredSolarPanelType}
                                >
                                    <option selected="" disabled="" value="">
                                        Select Preferred Solar Panel Type
                                    </option>
                                    <option>Monocrystalline</option>
                                    <option>Polycrystalline</option>
                                    <option>TOPCon</option>
                                    <option>DCR</option>
                                    <option>No preference</option>
                                    <option>Other</option>
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.preferredSolarPanelType && formik.errors.preferredSolarPanelType ? (
                                        <div className='text-danger'>{formik.errors.preferredSolarPanelType}</div>
                                    ) : null}
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Do you have any existing solar panels installed?
                                </label>
                                <div>
                                    <input
                                        type="radio"
                                        id='working'

                                        value={1}
                                        name='working'


                                    /><label htmlFor='working' className='mx-2'>Yes</label>
                                </div>
                                <div>
                                    <input type="radio"
                                        id='working2'

                                        value={0}
                                        name='working'

                                    /><label htmlFor='working2' className='mx-2'>No</label>
                                </div>
                            </div> */}
                            {formik.values.preferredSolarPanelType === "Other" &&
                                <div className="col-md-6">
                                    <label htmlFor="full-name" className="form-label">
                                        Please provide details<span className='text-danger'>*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="complaint-details"
                                        defaultValue=""
                                        required=""
                                        name='preferredSolarPanelTypeDetail'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.preferredSolarPanelTypeDetail}
                                    ></textarea>
                                    <div className="help-block with-errors">
                                        {formik.touched.preferredSolarPanelTypeDetail && formik.errors.preferredSolarPanelTypeDetail ? (
                                            <div className='text-danger'>{formik.errors.preferredSolarPanelTypeDetail}</div>
                                        ) : null}
                                    </div>
                                </div>}
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Energy Storage Options
                                </label>
                                <div>
                                    <input
                                        type="radio"
                                        id='working'
                                        checked={formik.values.energyStorageOption === '1'}
                                        value={1}
                                        name='energyStorageOption'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}


                                    /><label htmlFor='working' className='mx-2'>Yes</label>
                                </div>
                                <div>
                                    <input type="radio"
                                        id='working2'
                                        checked={formik.values.energyStorageOption === '0'}
                                        value={0}
                                        name='energyStorageOption'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                    /><label htmlFor='working2' className='mx-2'>No</label>
                                </div>
                                <div className="help-block with-errors">
                                    {formik.touched.energyStorageOption && formik.errors.energyStorageOption ? (
                                        <div className='text-danger'>{formik.errors.energyStorageOption}</div>
                                    ) : null}
                                </div>
                            </div>
                            {formik.values.energyStorageOption == "1" &&
                                <div className="col-md-6">
                                    <label htmlFor="full-name" className="form-label">
                                        Preferred Battery Type<span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className="form-select"
                                        id="validationServerstate"
                                        aria-describedby=""
                                        required=""
                                        name='preferredBatteryType'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.preferredBatteryType}
                                    >
                                        <option selected="" disabled="" value="">
                                            Select Preferred Battery Type
                                        </option>
                                        <option>Select preferred battery type</option>
                                        <option>Lithium-ion</option>
                                        <option>Lead-acid</option>
                                        <option>No preference</option>
                                    </select>
                                    <div className="help-block with-errors">
                                        {formik.touched.preferredBatteryType && formik.errors.preferredBatteryType ? (
                                            <div className='text-danger'>{formik.errors.preferredBatteryType}</div>
                                        ) : null}
                                    </div>
                                </div>}
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    System Size (KW)<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="full-name"
                                    defaultValue=""
                                    required=""
                                    placeholder='Enter System Size (KW) '
                                    name='systemSize'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.systemSize}
                                />
                                <div className="help-block with-errors">
                                    {formik.touched.systemSize && formik.errors.systemSize ? (
                                        <div className='text-danger'>{formik.errors.systemSize}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-12 py-2 label-background ">
                                <label htmlFor="" className="form-label mb-0 pb-0">
                                    Financing and Incentives
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Preferred Financing Option<span className='text-danger'>*</span>
                                </label>
                                <select
                                    className="form-select"
                                    id="validationServerstate"
                                    aria-describedby=""
                                    required=""
                                    name='preferredFinancingOption'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.preferredFinancingOption}
                                >
                                    <option selected="" disabled="" value="">
                                        Select Preferred Financing Option
                                    </option>
                                    <option>Purchase</option>
                                    <option>Lease</option>
                                    <option>Loan</option>
                                    <option>PPA (Power Purchase Agreement)</option>
                                    <option>Undecided</option>
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.preferredFinancingOption && formik.errors.preferredFinancingOption ? (
                                        <div className='text-danger'>{formik.errors.preferredFinancingOption}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="full-name" className="form-label">
                                    Are you interested in learning about available incentives and rebates?<span className='text-danger'>*</span>
                                </label>
                                <div>
                                    <input
                                        type="radio"
                                        id='working'
                                        checked={formik.values.interestedInIncentives === '1'}
                                        value={1}
                                        name='interestedInIncentives'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}


                                    /><label htmlFor='working' className='mx-2'>Yes</label>
                                </div>
                                <div>
                                    <input type="radio"
                                        id='working2'
                                        checked={formik.values.interestedInIncentives === '1'}
                                        value={0}
                                        name='interestedInIncentives'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}

                                    /><label htmlFor='working2' className='mx-2'>No</label>
                                </div>
                                <div className="help-block with-errors">
                                    {formik.touched.interestedInIncentives && formik.errors.interestedInIncentives ? (
                                        <div className='text-danger'>{formik.errors.interestedInIncentives}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12 py-2 label-background ">
                                <label htmlFor="" className="form-label mb-0 pb-0">
                                    Additional Information
                                </label>
                            </div>
                            <div className="col-md-12 ">
                                <label htmlFor="full-name" className="form-label">
                                    Preferred Installation Timeline<span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    id="validationServerstate"
                                    aria-describedby=""
                                    required=""
                                    name='preferredInstallationTimeline'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.preferredInstallationTimeline}
                                >
                                    <option selected="" disabled="" value="">
                                        Select Installation Timeline
                                    </option>
                                    <option>15 days</option>
                                    <option>30 days</option>
                                    <option>45 days</option>
                                    <option>More than 60 days</option>

                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.preferredInstallationTimeline && formik.errors.preferredInstallationTimeline ? (
                                        <div className='text-danger'>{formik.errors.preferredInstallationTimeline}</div>
                                    ) : null}
                                </div>

                            </div>
                            <div className="col-md-12 ">
                                <label htmlFor="complaint-details" className="form-label">
                                    Are there any specific concerns or questions you have about solar installation? Please write down your all queries
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="complaint-details"
                                    defaultValue=""
                                    required=""
                                    name='specificConcern'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.specificConcern}
                                ></textarea>
                                <div className="help-block with-errors">
                                    {formik.touched.specificConcern && formik.errors.specificConcern ? (
                                        <div className='text-danger'>{formik.errors.specificConcern}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12 ">
                                <label htmlFor="full-name" className="form-label">
                                    How did you hear about us?<span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    id="validationServerstate"
                                    aria-describedby=""
                                    required=""
                                    name='howHeardAbout'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.howHeardAbout}
                                >
                                    <option selected="" disabled="" value="">
                                        Select How did you hear about us?
                                    </option>

                                    {referenceData?.map((data) => (
                                        <option value={data?.name}>{data?.name}</option>
                                    ))}
                                </select>
                                <div className="help-block with-errors">
                                    {formik.touched.howHeardAbout && formik.errors.howHeardAbout ? (
                                        <div className='text-danger'>{formik.errors.howHeardAbout}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-12 ">
                                <div className='checkbox-dv'>
                                    <input
                                        // required
                                        type="checkbox"
                                        id="share_data"
                                        name="share_data"
                                        className='checkbox-input'
                                        checked={formik.values.share_data}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.share_data}
                                    />
                                    <label htmlFor="share-check" className='ms-1'>
                                        <span className="text-danger">*</span>
                                        I agree to be contacted by Eapro Team regarding my solar installation inquiry.</label>
                                </div>
                                <div className="help-block with-errors">
                                    {formik.touched.share_data && formik.errors.share_data ? (
                                        <div className='text-danger'>{formik.errors.share_data}</div>
                                    ) : null}
                                </div>
                            </div>

                            {/* <div className="col-md-6">
                                <label htmlFor="upload-product-front" className="form-label upload-product-front">
                                    <span>Front Image</span>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="upload-product-front"
                                        defaultValue=""
                                        required=""
                                        placeholder='Front Image' hidden
                                    />
                                    <label htmlFor="upload-product-front" className="upload-product-icon">
                                        <img src="./images/register-your-complaint/upload-file.png" alt="" />
                                    </label>
                                </label>

                            </div>
                            <div className="col-md-6">
                                <label htmlFor="upload-product-back" className="form-label upload-product-back">
                                    <span>Back Image</span>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="upload-product-back"
                                        defaultValue=""
                                        required=""
                                        placeholder='Back Image' hidden
                                    />
                                    <label htmlFor="upload-product-back" className="upload-product-icon">
                                        <img src="./images/register-your-complaint/upload-file.png" alt="" />
                                    </label>
                                </label>
                            </div> */}


                            <div className="col-12 text-center">
                                <button className="btn submit-btn-custom" type="submit" disabled={formik.isSubmitting || !hasDigitsAfterFourth}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <>
                    {/* Button trigger modal */}
                    <button
                        type="button"
                        className="btn btn-primary"
                        id='modal-button'
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{ display: 'none' }}
                    >
                        Launch demo modal
                    </button>

                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog ">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        style={{ display: 'none' }}
                                    />
                                    <div className="order-place text-center">
                                        {/* <h3>Order Placed Successfully</h3> */}
                                        <div className="order-image">
                                            <img
                                                src="/images/great.png"
                                                alt="order-place"
                                                className="img-fluid"
                                                style={{ width: '200px', height: '200px' }}
                                            />
                                        </div>
                                        <div className="thank-you">
                                            <h4 className='my-4'>Thank you for providing your details. </h4>
                                            <p className='my-4'>
                                                Our team will review your
                                                information and contact you shortly to discuss the next steps. If you have any immediate
                                                questions, please feel free to reach out to us at <strong>{contactDetailsData?.email}</strong>.

                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            </section>
        </>
    )
}

export default InstallationHelpPageContent