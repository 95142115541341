import React from 'react'
import "./ServiceCenterLocatorPageContent.css"
const StoreLocatorContentCard = (serviceCenterLocatorData) => {



  const address = serviceCenterLocatorData?.data?.BaseAddressLine;
  const addressLines = address?.split(/\r\n/);

  return (
    <>
      <section className='service-center-locator-content-section-card'>
        <div className='container'>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              {(serviceCenterLocatorData?.data) ?
                <div className="store-locator-card">
                  <h4>{serviceCenterLocatorData?.data?.CustomerName ? serviceCenterLocatorData?.data?.CustomerName : ""}{serviceCenterLocatorData?.data?.CustomerType ? `, ${serviceCenterLocatorData?.data?.CustomerType}` : ""}</h4>

                  <div className="row">
                    <div className="col-md-10">
                      <div className="para-store-add">
                        <p>{serviceCenterLocatorData?.data?.ContactNo ? `${serviceCenterLocatorData?.data?.ContactNo}` : ""}</p>
                        <p>{serviceCenterLocatorData?.data?.address}{serviceCenterLocatorData?.data?.city ? `, ${serviceCenterLocatorData?.data?.city}` : ""}{serviceCenterLocatorData?.data?.state ? `, ${serviceCenterLocatorData?.data?.state}` : ""}{serviceCenterLocatorData?.data?.country ? `, ${serviceCenterLocatorData?.data?.country}` : ""}{serviceCenterLocatorData?.data?.pincode ? `, ${serviceCenterLocatorData?.data?.pincode}` : ""}</p>
                      </div>
                    </div>
                    {serviceCenterLocatorData?.data?.ContactNo &&
                      <div className="col-md-2">
                        <div className="phone-icon">
                          <a href={`tel:${serviceCenterLocatorData?.data?.ContactNo}`}><img src="./images/store-locator/phone.png" alt="" /></a>
                        </div>
                      </div>}
                  </div>
                </div>
                : <h4>No Stores Available At This Location.</h4>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StoreLocatorContentCard