import React from 'react'
import "./QualityAssurance.css"
import "./ResponsiveQualityAssurance.css"
const host = process.env.REACT_APP_API_SERVER;
const QualityAssuranceBanner = (LearnMoreData) => {
  return (
    <>

      <section className='quality-assurance-banner-sec'>
        <div className=" align-items-center">
          <div className="text-start">
            <div className="banner-top-img-bg-rel">
              <img src={LearnMoreData?.LearnMoreData?.banner ? `${host}/${LearnMoreData?.LearnMoreData?.banner}` : ""} alt="" className='img-fluid banner-img-tag' />
              <div className="banner-top-content-absol text-start">

                {LearnMoreData?.LearnMoreData?.heading &&
                  <div className='business-banner-heading'>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 ">
                          <div className="business-heading text-center text-light" >
                            {/* <img src="./images/global-footprints/heading-bg.png" alt="" /> */}
                            <h3>{LearnMoreData?.LearnMoreData?.heading}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default QualityAssuranceBanner