import { React, useState } from 'react'
import "./ServiceCenterLocatorPageContent.css"
import ServiceCenterLocatorPageContentCard from './ServiceCenterLocatorPageContentCard'
const ServiceCenterLocatorPageContent = ({ serviceCenterLocatorData }) => {



  const [displayLimit, setDisplayLimit] = useState(10);


  const loadMore = () => {
    setDisplayLimit((prevLimit) => prevLimit + 5);
  };



  return (
    <>
      <section className='service-center-locator-content-section'>
        {serviceCenterLocatorData?.slice(0, displayLimit).map((data) => (

          <ServiceCenterLocatorPageContentCard data={data} key={data?.id} />
        ))}

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {serviceCenterLocatorData?.length > 0 ? <>
                {serviceCenterLocatorData && serviceCenterLocatorData.length > displayLimit ? (
                  <div className="see-more-btn">

                    <button onClick={loadMore}>Load More</button>
                  </div>
                ) : serviceCenterLocatorData && serviceCenterLocatorData.length <= displayLimit ? (
                  ""
                ) :
                  <h4>Search To Find Store Near You</h4>
                }
              </>
                :
                <h4> <img src='/images/no-connection.png' width={50}></img> No service center found</h4>}


            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServiceCenterLocatorPageContent