import React from 'react'
import "./PowerSolutionPageDetails.css"
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
const PowerSolutionPageDetails = (categoryData) => {
    const data = categoryData?.categoryData;
    return (
        <>
            <section className='power-solution-page-details-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="career-content-heading">
                                <h5>{data?.name}</h5>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {data?.description ? parse(data?.description) : <div className="power-solution-details">
                                <p>EAPRO stands tall as India's leading provider of power solutions, offering unparalleled quality and reliability. With a steadfast dedication to innovation and state-of-the-art technology, EAPRO has earned its reputation as the premier brand in the industry. For over 11 years, EAPRO has illuminated homes across India, enriching lives with hassle-free power solutions that enhance convenience and peace of mind.
                                </p>

                                <p>At the heart of EAPRO's success lies its commitment to excellence. Each product is meticulously crafted to deliver superior performance, ensuring a seamless experience for users. Whether it's inverters or inverter batteries, EAPRO's diverse range of offerings caters to every need with precision and finesse.
                                    .</p>
                                <p>The hallmark of EAPRO's success is its unwavering focus on customer satisfaction. By understanding and addressing the evolving needs of Indian households, EAPRO continues to exceed expectations and brighten countless lives. As India's power demands grow, EAPRO stands ready to meet the challenge with its robust solutions and unwavering dedication to quality.</p>

                                <p>In choosing EAPRO, customers not only invest in top-notch power solutions but also in a legacy of reliability, innovation, and customer-centricity. As we look towards the future, EAPRO remains committed to empowering Indian households with hassle-free days and brighter tomorrows.</p>
                            </div>}
                            <div className="book-a-free-consultation">
                                <a href='http://eaproerp.site:3006'>Book A Free Consultation</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PowerSolutionPageDetails