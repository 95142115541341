import { React, useEffect } from 'react'
import parse from 'html-react-parser'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './CsrContent.css'
import { Link } from 'react-router-dom'
import { getCsrData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const CsrContentCard = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getCsrData());
    }, []);

    const CsrData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.CsrDataList?.data) : []);


    const navigate = useNavigate();
    const handleClick = (name) => {

        if (name !== undefined) {
            navigate(`/csr-details/${name}`)
        }


    }
    return (
        <>
            <div className="blog-card-parent">
                <div className="row">
                    {CsrData?.map((data) => (


                        <div className="col-md-4">
                            <div className="card">
                                <img src={data?.image ? `${host}${data?.image}` : ""} className="card-img-top" alt="..." />
                                <div onClick={() => { handleClick(data?.slug) }} style={{ cursor: 'pointer' }}>
                                    <div className="card-body">
                                        <h5>{data?.title}</h5>
                                        <p className="card-text">
                                            {parse(data?.summary)}
                                        </p>
                                        <p><i>{new Date(data?.createdAt).toDateString()}</i></p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    ))}
                </div>
            </div>
        </>
    )
}

export default CsrContentCard