import React from 'react'
import "./ViewSolarProductSeriesListingContent.css"
import { Link } from 'react-router-dom'
const ViewSolarProductSeriesListingContent = () => {
    return (
      <>
        <section className="solar-searis-product-details-section">
          {/* graphic-1  */}
          {/* <div className="bg-graphic-right-top">
                    <img src="./images/power-solution/bg-graphic-1.png" className="img-fluid" />
                </div> */}
          <div className="container">
            <div className="row justify-content-center">
              <h4 className="col-lg-12 text-center">Trano series</h4>
              {/* ---- Product Item ----- */}
              <div className="col-lg-12 mb-5 text-start">
                <div className="row">
                  <div className="col-lg-4 mb-lg-0 mb-5">
                    <div className="product-item-img">
                      <img
                        src="./images/power-solution/product-one.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 offset-lg-1">
                    <div className="product-item-info">
                      <h2>TRANO-850S</h2>
                      <div className="key-features">
                        <h6>Key features</h6>
                        <ul>
                          <li>Nano technology for extra load handling</li>
                          <li>
                            Auto reset ac fuse technology during short circuit
                          </li>
                          <li>Active battery reverse protection</li>
                        </ul>
                      </div>
                      <div className="model">
                        <h6>Model No.</h6>
                        <h5>TRANO 850S 12V SOLAR UPS</h5>
                      </div>
                      <div className="capacity">
                        <h6>Capacity</h6>
                        <h4>700VA</h4>
                      </div>
                      <Link to="/product-details" className="view-product-btn">
                        View Product
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* ---- Product Item ----- */}
              <div className="col-lg-12 mb-5 text-start">
                <div className="row">
                  <div className="col-lg-4 mb-lg-0 mb-5">
                    <div className="product-item-img">
                      <img
                        src="./images/power-solution/product-one.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 offset-lg-1">
                    <div className="product-item-info">
                      <h2>TRANO-850S</h2>
                      <div className="key-features">
                        <h6>Key features</h6>
                        <ul>
                          <li>Nano technology for extra load handling</li>
                          <li>
                            Auto reset ac fuse technology during short circuit
                          </li>
                          <li>Active battery reverse protection</li>
                        </ul>
                      </div>
                      <div className="model">
                        <h6>Model No.</h6>
                        <h5>TRANO 850S 12V SOLAR UPS</h5>
                      </div>
                      <div className="capacity">
                        <h6>Capacity</h6>
                        <h4>700VA</h4>
                      </div>
                      <Link to="/product-details" className="view-product-btn">
                        View Product
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* ---- Product Item ----- */}
              <div className="col-lg-12 mb-5 text-start">
                <div className="row">
                  <div className="col-lg-4 mb-lg-0 mb-5">
                    <div className="product-item-img">
                      <img
                        src="./images/power-solution/product-one.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 offset-lg-1">
                    <div className="product-item-info">
                      <h2>TRANO-850S</h2>
                      <div className="key-features">
                        <h6>Key features</h6>
                        <ul>
                          <li>Nano technology for extra load handling</li>
                          <li>
                            Auto reset ac fuse technology during short circuit
                          </li>
                          <li>Active battery reverse protection</li>
                        </ul>
                      </div>
                      <div className="model">
                        <h6>Model No.</h6>
                        <h5>TRANO 850S 12V SOLAR UPS</h5>
                      </div>
                      <div className="capacity">
                        <h6>Capacity</h6>
                        <h4>700VA</h4>
                      </div>
                      <Link to="/product-details" className="view-product-btn">
                        View Product
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* graphic-2  */}
          {/* <div className="bg-graphic-left-bottom">
                    <img src="./images/power-solution/bg-graphic-2.png" className="img-fluid" />
                </div> */}
        </section>
      </>
    );
}

export default ViewSolarProductSeriesListingContent