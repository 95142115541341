import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ManufacturingPower.css"
import parse from 'html-react-parser'

const ManufacturingPowerContent = (whyEaproData) => {
  const whyEapro = whyEaproData?.whyEaproData

  return (
    <>
      <section className='manufacturing-power-content-sec'>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="new-page-heading">
                <h5 className="fw-bold">{whyEapro?.title}</h5>
              </div>
            </div>
            <div className="col-md-12 text-start mt-5">
              <div className="new-page-content">
                {whyEapro?.description ? parse(whyEapro?.description) : ""}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-start">
              <div className="new-page-left">

              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ManufacturingPowerContent