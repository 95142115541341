import React, { useEffect, useState, } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "./ContactUsPageNewContent.css"
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import { PhoneInput } from 'react-international-phone';
import { getCountryData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const ContactUsPageContent = (contactDetailsData) => {
  const dispatch = useDispatch();
  const [cphone, setPhone] = useState('');
  const [caltphone, setcaltPhone] = useState('');
  // Remove non-digit characters
  const cleanPhoneNumber = cphone.replace(/\D/g, '');

  // Check if there are digits after the fourth digit
  const hasDigitsAfterFourth = cleanPhoneNumber.length > 8;

  useEffect(() => {


    dispatch(getCountryData());
  }, []);

  const countryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.countryDataList?.data) : []);


  let [initialValues, setInitialValues] = useState({
    name: "",
    // phone: "",
    email: "",
    complaint: "",
    type: "Contact Us",
    // countryCode: "",
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required").matches(/^[a-zA-Z .]+$/, "Name can only contain letters."),
      email: Yup.string().required("Email ID is required.").matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid E-mail"),
      // altphone: Yup.string().matches(/^[0-9]{8,15}$/, "Please enter a valid Mobile Number."),
      // phone: Yup.string().required("Mobile Number is required").matches(/^[0-9]{8,15}$/, "Please enter a valid Mobile Number."),
      complaint: Yup.string().required("Query Message is required"),
      // countryCode: Yup.string().required("Country Code is required"),


    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {

      try {

        setSubmitting(true);


        document.body.style.overflow = 'hidden';
        const value = {
          name: values.name,
          email: values.email,
          phone: cphone,
          altphone: caltphone,
          complaint: values.complaint,
          type: "Contact Us",
        }

        await axios.post(`${host}/web/contactUs`, value)
          .then(function (response) {
            if (response.status == 200) {
              setSubmitting(false)
              document.body.style.overflow = 'auto';
              toast.success("Form Submitted Succesfully");
              resetForm();
              setPhone('');
              setcaltPhone('');

            } else {

              setSubmitting(false)
              document.body.style.overflow = 'auto';
              toast.error(response.data.msg);

            }
          })
          .catch(function (error) {

            document.body.style.overflow = 'auto';
            setSubmitting(false)
            toast.error('Error submitting form. Please try again.');

          })

      }
      catch (error) {

        document.body.style.overflow = 'auto';
        setSubmitting(false)
        toast.error('Error submitting form. Please try again.');

      }

    }
  })

  return (
    <>
      <section className='contact-us-content-section'>

        <div className="container">
          <div className="form-parent-custon-dv">
            <div className="row">
              <div className="col-md-12 text-center py-lg-3">
                <div className="help-support-heading">
                  <h5>Contact Details</h5>
                </div>
              </div>
            </div>
            <form className="row g-3 custom-row-form form-container" method='post' onSubmit={formik.handleSubmit} >
              <FocusError formik={formik} />
              <div className="col-md-12">
                <label htmlFor="full-name" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control rounded-0"
                  placeholder='Full Name'
                  name="name" // Add this line
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <div className="help-block with-errors">
                  {formik.touched.name && formik.errors.name ? (
                    <div className='text-danger'>{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <label htmlFor="email-id" className="form-label">
                  Email ID
                </label>
                <input
                  type="email"
                  className="form-control rounded-0"
                  placeholder='Email ID'
                  name="email" // Make sure this matches your initialValues object key
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <div className="help-block with-errors">
                  {formik.touched.email && formik.errors.email ? (
                    <div className='text-danger'>{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <label htmlFor="contact-details" className="form-label">
                  Contact Details
                </label>
              </div>

              <div className="col-md-6">
                <PhoneInput
                  className='rounded-0'
                  placeholder='Phone no.'
                  required
                  defaultCountry="in"
                  value={cphone}
                  onChange={(cphone) => setPhone(cphone)}
                />
                <div className="ms-2 help-block with-errors">
                  {!hasDigitsAfterFourth && (
                    <div className="text-danger text-start">Phone no. is required</div>
                  )}
                </div>

              </div>
              <div className="col-md-6">
                <PhoneInput
                  className='rounded-0'
                  placeholder='Alternate Phone no.'
                  required
                  defaultCountry="in"
                  value={caltphone}
                  onChange={(caltphone) => setcaltPhone(caltphone)}
                />

              </div>
              <div className="col-md-12">
                <label htmlFor="complaint-details" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control rounded-0"
                  rows="5"
                  placeholder='Type your query here'
                  name="complaint" // Make sure this matches your initialValues object key
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.complaint}
                >
                </textarea>
                <div className="help-block with-errors">
                  {formik.touched.complaint && formik.errors.complaint ? (
                    <div className='text-danger'>{formik.errors.complaint}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 text-center">
                <button className="btn submit-btn-custom" type="submit" disabled={!hasDigitsAfterFourth}>
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="map-two-div">
            <div className="row align-item-center justify-content-center">
              <div className="col-md-12 text-center py-lg-3">
                <div className="help-support-heading">
                  <h5>Contact Details</h5>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-12 text-start">
                <div className="map-dv-parent">
                  <h5>Corporate Tower</h5>
                  <p>{contactDetailsData?.contactDetailsData?.address1 ? contactDetailsData?.contactDetailsData?.address1 : "Upper Ganga canal Besides, Asaf Nagar, Delhi Rd, Roorkee, Uttarakhand 247666"}</p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27691.823042909327!2d77.85626409487556!3d29.821625000000015!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3909466b570d5169%3A0x963cbd4751495076!2sEAPRO%20Global%20Ltd%20(Corporate%20Tower)!5e0!3m2!1sen!2sin!4v1713178498739!5m2!1sen!2sin"
                    style={{ border: "0", height: "200px", width: "100%" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />

                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-12 text-start">
                <div className="map-dv-parent">
                  <h5>Industrial Address</h5>
                  <p>{contactDetailsData?.contactDetailsData?.address2 ? contactDetailsData?.contactDetailsData?.address2 : "106-107,Salempur Rajputana Industrial Area, Roorkee, Uttarakhand 247666"}</p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27675.054547652275!2d77.857262!3d29.882097!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390eb3749dc4515f%3A0xc99570757d64db3!2sEAPRO%20Global%20Limited!5e0!3m2!1sen!2sin!4v1713178411156!5m2!1sen!2sin"
                    style={{ border: "0", height: "200px", width: "100%" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />

                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </>
  )
}

export default ContactUsPageContent