import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./MeetOurTeamAbout.css"
import "./MeetOurTeamAboutResponsive.css"
import { getOurTeamData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;


const MeetOurTeamAbout = () => {
    // 
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getOurTeamData());

    }, []);

    const ourTeamData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.ourTeamDataList?.data) : []);

    const Slider_group = {
        dots: false,
        nav: true,
        infinite: ourTeamData?.length > 2,
        speed: 500,
        lazyLoad: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        initialSlide: 0,
        arrows: true,
        swipe: true,
        centerMode: true,
        centerPadding: '10px',
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: ourTeamData?.length > 2,
                    dots: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    dots: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <section className='meet-our-team-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h5>Meet Our Core Team</h5>
                    </div>
                    {ourTeamData?.length > 3 ?
                        <Slider {...Slider_group} className='slider-parent-dv'>
                            {ourTeamData && ourTeamData?.map((data) => (
                                <div className='slider-item-dv'>
                                    <div className="slider-item-img">
                                        <img src={data?.image ? `${host}${data?.image}` : ""} alt="" className='img-fluid' />
                                    </div>
                                    <div className="slider-item-heading">
                                        <h4>{data?.name}</h4>
                                        <p>{data?.description}</p>
                                    </div>
                                </div>
                            ))}

                        </Slider>
                        :
                        <section className='founder-md-section-parent-new-design-our-team'>

                            <div className="row g-5 justify-content-center g-lg-5 g-md-5 g-0">
                                {ourTeamData?.map((data, index) => (

                                    <div className="col-md-3 col-lg-3 mx-5">
                                        <div className={`founder-images-${index % 2 != 0 ? 'left' : 'right'}-dv`}>
                                            <img src={data?.image ? `${host}/${data?.image}` : ""} alt="" className='img-fluid' />
                                            <div className="founder-name-post">
                                                <h6 className=''><b>{data?.name}</b></h6>
                                                <p className='mb-0 pb-0'>{data?.description}</p>
                                            </div>
                                        </div>

                                    </div>
                                ))}

                            </div>

                        </section>

                    }
                </div>
            </section>
        </>
    )
}

export default MeetOurTeamAbout