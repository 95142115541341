import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import ManufacturingPowerBanner from './ManufacturingPowerBanner'
import ManufacturingPowerContent from './ManufacturingPowerContent'
import { getWhyEaproData } from "../../Redux/Action/Action";

const ManufacturingPower = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getWhyEaproData());
    }, []);

    const whyEaproData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.whyEaproDataList?.data) : []
    );


    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ManufacturingPowerBanner whyEaproData={whyEaproData} />
            <ManufacturingPowerContent whyEaproData={whyEaproData} />
            <Footer />
        </>
    )
}

export default ManufacturingPower