import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getAboutUsData, getFounderDirectorData, getOurSpecializationData } from '../../Redux/Action/Action';
import AboutBannerCompo from './AboutBannerCompo/AboutBannerCompo'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import OurJourneyComponent from './OurJourneyComponent/OurJourneyComponent'
import AboutUsContentDark from './AboutUsContentDark/AboutUsContentDark'
import VisionMissionSec from './VisionMissionSec/VisionMissionSec'
import FounderMDSection from './FounderMDSection/FounderMDSection'

import AchievementsComponent from './AchievementsComponent/AchievementsComponent'
import MeetOurTeamAbout from './MeetOurTeamAbout/MeetOurTeamAbout';
import GroupOfEaproCompanies from './GroupOfEaproCompanies/GroupOfEaproCompanies';

const AboutUs = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAboutUsData());
        dispatch(getOurSpecializationData());
        dispatch(getFounderDirectorData());

    }, []);

    const aboutUsData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.aboutUsDataList?.data) : []);
    const founderDirectorData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.founderDirectorDataList?.data) : []);

    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <AboutBannerCompo aboutUsData={aboutUsData} />
            <OurJourneyComponent aboutUsData={aboutUsData} />
            <AboutUsContentDark aboutUsData={aboutUsData} />
            <VisionMissionSec aboutUsData={aboutUsData} />
            <FounderMDSection founderDirectorData={founderDirectorData} />
            <MeetOurTeamAbout />
            {/* <AboutOurSpecialization ourSpecializationData={ourSpecializationData} /> */}
            <AchievementsComponent />
            <GroupOfEaproCompanies />
            <Footer />
        </>
    )
}

export default AboutUs