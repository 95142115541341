import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom/dist';
import PowerSolutionPageBanner from './PowerSolutionPageBanner/PowerSolutionPageBanner'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import PowerSolutionPageDetails from './PowerSolutionPageDetails/PowerSolutionPageDetails'
import PowerSolutionHomeInverter from './PowerSolutionHomeInverter/PowerSolutionHomeInverter'
import PowerSolutionBatteryPage from './PowerSolutionBatteryPage/PowerSolutionBatteryPage'
import ConverterAndControllerPage from './ConverterAndControllerPage/ConverterAndControllerPage'
import PowerSolutionPageAccessories from './PowerSolutionPageAccessories/PowerSolutionPageAccessories'
import { getCategoryData, getCategoryParamsData, getSubCategoryData } from '../../Redux/Action/Action';

const PowerSolutionPage = () => {

  const dispatch = useDispatch();
  const { name } = useParams();

  useEffect(() => {
    if (name !== undefined) {
      dispatch(getSubCategoryData(name));
      dispatch(getCategoryParamsData(name))
    }
  }, [name]);
  const subCategoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.subCategoryDataList?.data) : []);
  const categoryData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.categoryParamsDataList?.data) : []);


  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Navbar />
      <NavbarSecond />
      <MobileNavbar />
      <PowerSolutionPageBanner categoryData={categoryData} />
      <PowerSolutionPageDetails categoryData={categoryData} />
      {subCategoryData?.map((data, index) => (
        <React.Fragment key={data.id}>
          {index % 2 === 0 ? (
            <PowerSolutionBatteryPage data={data} />
          ) : (
            <PowerSolutionHomeInverter data={data} />
          )}
        </React.Fragment>
      ))}

      {/* <ConverterAndControllerPage /> */}
      {/* <PowerSolutionPageAccessories/> */}
      <Footer />
    </>
  )

}

export default PowerSolutionPage