import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import CareerAtEaproTwoBanner from './CareerAtEaproTwoBanner/CareerAtEaproTwoBanner'
import Navbar from '../../Header/Navbar'
import NavbarSecond from '../../Header/NavbarSecond'
import MobileNavbar from '../../Header/MobileNavbar'
import Footer from '../../Footer/Footer'
import CareerAtEaproTwoContent from './CareerAtEaproTwoContent/CareerAtEaproTwoContent'
import { getAllBannerData, getJobData } from '../../../Redux/Action/Action'
const CareerAtEaproTwo = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    const { name } = useParams();

    useEffect(() => {

        dispatch(getJobData(name));
        dispatch(getAllBannerData())

    }, []);


    const jobData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.jobDataList?.data) : []);
    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);

    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CareerAtEaproTwoBanner bannerData={bannerData} />
            <CareerAtEaproTwoContent jobData={jobData} />
            <Footer />
        </>
    )
}

export default CareerAtEaproTwo