import React from 'react'
import "./EaproGlobalEnergyContent.css"
import parse from 'html-react-parser'
const EaproGlobalEnergyContent = (eaproEnergyData) => {
    return (
        <>
            <section className='global-energy-con-page-content-section'>
                <div className="container">
                    <div className="global-energy-heading">
                        <h5>About EAPRO Global Energy Pvt Ltd.</h5>
                    </div>
                    <div className="top-para">
                        {eaproEnergyData?.eaproEnergyData?.about_us_content ? parse(eaproEnergyData?.eaproEnergyData?.about_us_content) : <p>Welcome to Eapro Global Energy Pvt Ltd, a pioneering force in the realm of sustainable energy solutions in India. Established in 2023 as a specialized entity under the umbrella of Eapro Group, we are dedicated to bringing the cutting-edge products of Eapro to the Indian market through a strategic blend of channel sales and e-commerce.</p>}
                    </div>
                    <div className="icon-row-parent row">
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/mission.png" alt="" />
                                    <h4>OUR  <span>MISSION</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-mission.png" alt="" />
                                    {eaproEnergyData?.eaproEnergyData?.our_mission ? parse(eaproEnergyData?.eaproEnergyData?.our_mission) : <p>Our mission is to make advanced and reliable energy products easily accessible to every corner of India. Through a robust network of channel partners and a seamless e-commerce experience, we aim to simplify the adoption of solar inverters, batteries, panels, and other Eapro products. By doing so, we strive to contribute significantly to India's energy transition and foster a culture of eco-conscious choices.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/vision.png" alt="" />
                                    <h4>OUR  <span>Vision</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-vision.png" alt="" />
                                    {eaproEnergyData?.eaproEnergyData?.our_vision ? parse(eaproEnergyData?.eaproEnergyData?.our_vision) : <p>At Eapro Global Energy Pvt Ltd, our vision is to be the driving force behind a greener and more sustainable future for India. We envision a nation where clean energy solutions are not just a choice but a norm, empowering individuals and businesses to contribute to a healthier planet.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card last-icon-heading-card">
                                    <img src="./images/global-energy/values.png" alt="" />
                                    <h4>OUR  <span>Values</span></h4>
                                </div>
                                <div className="dark-icons-content last-dark-icons-content">
                                    <img src="./images/global-energy/dark-values.png" alt="" />
                                    {eaproEnergyData?.eaproEnergyData?.our_value ? parse(eaproEnergyData?.eaproEnergyData?.our_value) : <p>Innovation: We embrace innovation as the cornerstone of progress. Our commitment to staying at the forefront of technology ensures that our products meet and exceed the evolving needs of our customers.</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="icon-row-parent row mt-5">
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/reliability.png" alt="" />
                                    <h4>OUR  <span>Reliability</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-reliability.png" alt="" />
                                    {eaproEnergyData?.eaproEnergyData?.our_reliability ? parse(eaproEnergyData?.eaproEnergyData?.our_reliability) : <p>Trust is the bedrock of our relationships. We are dedicated to delivering products that are not only technologically advanced but also reliable, ensuring the longevity and effectiveness of every solution we provide.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/sustainability.png" alt="" />
                                    <h4>OUR  <span>Sustainability</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-sustainability.png" alt="" />
                                    {eaproEnergyData?.eaproEnergyData?.our_sustainability ? parse(eaproEnergyData?.eaproEnergyData?.our_sustainability) : <p>Our passion for sustainability extends beyond our products. We are committed to minimizing our ecological footprint and promoting environmentally responsible practices throughout our operations.</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card last-icon-heading-card">
                                    <img src="./images/global-energy/collaboration.png" alt="" />
                                    <h4>OUR  <span>Collaboration</span></h4>
                                </div>
                                <div className="dark-icons-content last-dark-icons-content">
                                    <img src="./images/global-energy/dark-collaboration.png" alt="" />
                                    {eaproEnergyData?.eaproEnergyData?.our_collaboration ? parse(eaproEnergyData?.eaproEnergyData?.our_collaboration) : <p>We believe in the power of collaboration. By working closely with our channel partners, customers, and stakeholders, we aim to create a network that fosters mutual growth and success.</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EaproGlobalEnergyContent