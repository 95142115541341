import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import GlobalFootprintsBanner from './GlobalFootprintsBanner/GlobalFootprintsBanner'
import AboutExpert from './AboutExpert/AboutExpert'
import ExportEnquiryForm from './ExpertEnquiryForm/ExportEnquiryForm'
import EAPROOverseasForms from '../EAPROOverseas/EAPROOverseasContent/EAPROOverseasForms'
import { getAllBannerData } from '../../Redux/Action/Action';
const GlobalFootprintsPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);


    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <GlobalFootprintsBanner bannerData={bannerData} />
            <AboutExpert />
            {/* <ExportEnquiryForm/> */}
            <EAPROOverseasForms />
            <Footer />
        </>
    )
}

export default GlobalFootprintsPage