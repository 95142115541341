import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import './EventPageContent.css'
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom';
import EventPageContentCard from './EventPageContentCard'
import { getNewsCategoryData, getNewsData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const EventPageContent = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getNewsCategoryData());
        dispatch(getNewsData());
    }, []);

    const newsCategoryData = useSelector((state) => state?.Reducer?.newsCategoryDataList?.data || []);
    const newsData = useSelector((state) => state?.Reducer?.newsDataList?.data || []);
    const navigate = useNavigate();
    const handleClick = (newsId) => {

        if (newsId !== undefined) {
            navigate(`/news-event-details/${newsId}`)
        }


    }

    return (
        <>
            <section className='event-page-content-section'>
                {newsCategoryData ?
                    <div className="container">
                        {/* ------------------------------------------------ */}
                        <ul className="nav nav-tabs blog-nav-tabs" id="myTab" role="tablist">
                            {newsCategoryData?.map((category, index) => (
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${index === 0 ? "active" : ""}`} id={`${category.id}-tab`} data-bs-toggle="tab" data-bs-target={`#category${category.id}`} type="button" role="tab" aria-controls={`category${category.id}`} aria-selected={index === 0}>{category.name}</button>
                                </li>
                            ))}
                        </ul>
                        <div className="tab-content blog-tab-content" id="myTabContent">
                            {newsCategoryData.map((category, index) => {
                                const filteredBlogs = newsData?.filter(news => news.categoryId === category.id.toString());
                                return (

                                    <div className={`tab-pane fade ${index === 0 ? "show active" : ""}`} id={`category${category.id}`} role="tabpanel" aria-labelledby={`${category.id}-tab`} key={category.id}>
                                        <div className="row">
                                            {filteredBlogs?.map(news => (

                                                <div className="col-md-4 mb-5 text-decoration-none" onClick={() => { handleClick(news?.slug) }} style={{ cursor: "pointer" }}>
                                                    <div className="card">
                                                        <img src={news?.image ? `${host}${news?.image}` : "./images/blog/card-img.png"} className="card-img-top" alt="..." />

                                                        <div className="card-body">
                                                            <h5>{news?.title}</h5>
                                                            <p className="card-text border-0">
                                                                {parse(news?.summary)}
                                                            </p>
                                                            <p><i>{new Date(news?.createdAt).toDateString()}</i></p>
                                                        </div>

                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                        {/* ------------------------------------------------ */}

                    </div>
                    : <div className="container">

                    </div>}
            </section>
        </>
    )
}

export default EventPageContent