import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import TRCEfficientServicesBanner from './TRCEfficientServicesBanner/TRCEfficientServicesBanner'
import TRCEfficientServicesContent from './TRCEfficientServicesContent/TRCEfficientServicesContent'
import TRCEfficientServicesForms from './TRCEfficientServicesContent/TRCEfficientServicesForms'
import { getTrcEfficientData } from '../../Redux/Action/Action';

const TRCEfficientServices = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTrcEfficientData());

    }, []);

    const trcEfficientData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.trcEfficientDataList?.data) : []);

    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <TRCEfficientServicesBanner trcEfficientData={trcEfficientData} />
            <TRCEfficientServicesContent trcEfficientData={trcEfficientData} />
            <TRCEfficientServicesForms />
            <Footer />
        </>
    )
}

export default TRCEfficientServices