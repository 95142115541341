import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser'
import "./ECataloguePageContent.css"
import ECatalogueCard from './ECatalogueCard'
import { getCatalogueData } from '../../../Redux/Action/Action';
import Navbar from '../../Header/Navbar';
import NavbarSecond from '../../Header/NavbarSecond';
import MobileNavbar from '../../Header/MobileNavbar';
import Footer from '../../Footer/Footer';
import { useParams } from 'react-router-dom';

import ECatalogueCategoryBannerNew from './ECatalogueCategoryBannerNew';
const host = process.env.REACT_APP_API_SERVER;
const ECatalogueSubProducttListing = () => {


    const dispatch = useDispatch();
    const { name } = useParams();


    useEffect(() => {

        dispatch(getCatalogueData(name))

    }, []);
    const catalogueData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.catalogueDataList?.data?.[0]) : []);
    const cname = catalogueData?.name
    const banner = catalogueData?.banner
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ECatalogueCategoryBannerNew cname={cname} banner={banner} />
            <section className='e-catalogue-page-content-section'>
                <div className="container">
                    {/* ============================================================== */}
                    <div className="e-catelogue-before-row-parent-dv mb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="e-catalogue-heading">
                                    <h5>{catalogueData?.name}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='text-start'>
                                    {catalogueData?.description ? parse(catalogueData?.description) : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* ============================================================== */}
                    {/* ============================================================== */}
                    <div className="e-catelogue-before-row-parent-dv">

                        <div className="row">
                            {catalogueData?.catalogue?.map((data) => (
                                <div className="col-md-3 col-lg-3 my-2">
                                    <div className="catalogue-card-parent-dv">
                                        <div className="c-card-img">
                                            <img src={data?.image ? `${host}${data?.image}` : ""} alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className="heading-down-icon">
                                        <div className="first-content-strip">
                                            <p><a >{data?.title}</a></p>
                                        </div>
                                        <div className="second-content-strip">
                                            <p>
                                                <a href={`${host}${data?.catalogue_file}`} target='blank'><img src="/images/e-catalogue/download-icon.png" alt="" /></a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* ============================================================== */}

                </div>
            </section>
            <Footer />
        </>
    )
}

export default ECatalogueSubProducttListing