import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import CountUp from "react-countup";
import "./WhyEapro.css";
import "./ResponsiveWhyEapro.css";
import { getWhyEaproData } from "../../../Redux/Action/Action";
import { Link } from "react-router-dom";
const WhyEapro = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWhyEaproData());
  }, []);

  const whyEaproData = useSelector((state) =>
    state?.Reducer ? state?.Reducer?.whyEaproDataList?.data : []
  );

  const truncateText = (text, wordLimit) => {
    const words = text?.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };
  const [showDescription, setshowDescription] = useState(false);
  const handleDescription = () => {
    setshowDescription(!showDescription);
  };

  const wordCount = whyEaproData?.summary?.split(" ")?.length;

  const wordLimit = 50;

  return (
    <>
      <section className="why-eapro-section">
        <div className="container align-items-center">
          <div className="row">
            <div className="col-md-12">
              <div className="why-heading-dv">
                <h5>{whyEaproData?.title}</h5>
              </div>
            </div>
            <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1 mt-2 mb-5">
              <div className="para-content">

                {
                  whyEaproData?.summary ? parse(whyEaproData?.summary) : ""
                }
              </div>

              <div >
                <Link to='/why-eapro-detail' className="text-decoration-none text-dark">
                  <strong>Read More</strong>
                </Link>
              </div>

            </div>
            <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1">
              <div className="row counter-row">
                <div className="col-md-4">
                  <div className="card-box-counter">
                    <h4>
                      <CountUp
                        duration={5.0}
                        end={
                          whyEaproData?.box_1_count
                            ? whyEaproData?.box_1_count
                            : 11
                        }
                      />{" "}
                      M+
                    </h4>

                    <p>
                      {whyEaproData?.box_1_name
                        ? whyEaproData?.box_1_name
                        : "Customers"}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box-counter bg-yellow">
                    <h4>
                      <CountUp
                        duration={5.0}
                        end={
                          whyEaproData?.box_2_count
                            ? whyEaproData?.box_2_count
                            : 100
                        }
                      />{" "}
                      +
                    </h4>
                    <p>
                      {whyEaproData?.box_2_name
                        ? whyEaproData?.box_2_name
                        : "Products"}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-box-counter">
                    <h4>
                      <CountUp
                        duration={5.0}
                        end={
                          whyEaproData?.box_3_count
                            ? whyEaproData?.box_3_count
                            : 1500
                        }
                      />{" "}
                      +
                    </h4>
                    <p>
                      {whyEaproData?.box_3_name
                        ? whyEaproData?.box_3_name
                        : "Dealers Network"}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyEapro;
