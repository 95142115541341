import React from 'react'
import "./FounderMDSection.css"
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;
const FounderMDSection = (founderDirectorData) => {

    const navigate = useNavigate();

    const handleClick = (name) => {

        if (name !== undefined) {
            navigate(`/director-message/${name}`)
        }
    }

    return (
        <>

            <section className='founder-md-section-parent-new-design'>
                <div className="container">
                    <div className="row g-5 justify-content-center g-lg-5 g-md-5 g-0">
                        {founderDirectorData?.founderDirectorData && founderDirectorData?.founderDirectorData?.map((data, index) => (
                            <div className="col-md-6 col-lg-3 mx-5" onClick={() => handleClick(data?.slug)}>
                                <div className={`founder-images-${index % 2 != 0 ? 'left' : 'right'}-dv`}>
                                    <img src={data?.image ? `${host}/${data?.image}` : ""} alt="" className='img-fluid' />
                                </div>
                                <div className="founder-name-post">
                                    <Link to="/director-message"><h6 className=''><b>{data?.name}</b></h6></Link>
                                    <Link to="/director-message"><p className='mb-0 pb-0'>({data?.designation})</p></Link>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
        </>
    )
}

export default FounderMDSection