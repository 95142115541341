import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import AdvancedTechnologyBanner from './AdvancedTechnologyBanner'
import AdvancedTechnologyContent from './AdvancedTechnologyContent'
import { useParams } from 'react-router-dom';
import PillarVideos from './PillarVideos/PillarVideos'
import { getPillarsParamsData } from '../../Redux/Action/Action'

const AdvancedTechnology = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const { name } = useParams();

    useEffect(() => {
        dispatch(getPillarsParamsData(name));

    }, []);

    const pillarsData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.pillarsParamsDataList?.data) : []);
    const pillarVideo = pillarsData?.pillarVideo


    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <AdvancedTechnologyBanner pillarsData={pillarsData} />
            <AdvancedTechnologyContent pillarsData={pillarsData} />
            <PillarVideos pillarVideo={pillarVideo} />
            <Footer />
        </>
    )
}

export default AdvancedTechnology