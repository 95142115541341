import React from 'react'
import "./BlogBanner.css"
import "./ResponsiveBlogBanner.css"
const host = process.env.REACT_APP_API_SERVER;
const BlogBanner = (bannerData) => {

    return (
        <>
            {/* <section className='blog-banner-section'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-start">
                            <div className="business-heading text-start">
                                <img src="./images/help-and-support/heading-bg.png" alt="" />
                                <h1>Help & Support</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='blog-banner-section'>
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel">
                            <img src={bannerData?.bannerData?.blog ? `${host}${bannerData?.bannerData?.blog}` : ""} alt="" className='img-fluid banner-img-tag' />
                            <div className="banner-top-content-absol business-heading text-start">
                                <img src="./images/help-and-support/heading-bg.png" alt="" className='img-fluid' />
                                <div className='business-banner-heading'>
                                    <div className="container">
                                        <h1>Blogs</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogBanner