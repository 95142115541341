import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser'
import "./AchievementsComponent.css"

import { getAchievementData } from '../../../Redux/Action/Action';
const host = process.env.REACT_APP_API_SERVER;
const AchievementsComponent = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAchievementData());
    }, []);

    const achievementData = useSelector((state) => state?.Reducer?.achievementDataList?.data || []);

    return (
        <>
            {achievementData?.length != "0" ?
                <div className="achievements-component-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="achievements-component-heading-dv">
                                    <h5>Achievements</h5>
                                </div>
                            </div>
                        </div>
                        {achievementData && achievementData?.map((data, index) => (
                            <div key={index} className="row my-4 align-items-center">
                                {index % 2 === 0 ? (
                                    // For even indexes (odd rows) - Image on left, Description on right
                                    <>
                                        <div className="col-md-6">
                                            <div className="achievements-award-img-left">
                                                <img src={data?.image ? `${host}/${data?.image}` : ""} alt="" className='img-fluid' />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="achievement-award-right-dv-content">
                                                {parse(data?.description)}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    // For odd indexes (even rows) - Description on left, Image on right
                                    <>
                                        <div className="col-md-6">
                                            <div className="achievement-award-left-dv-content">
                                                {parse(data?.description)}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="achievements-award-img-right">
                                                <img src={data?.image ? `${host}/${data?.image}` : "./images/about-us/our-achievement-right.png"} alt="" className='img-fluid' />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                :
                <div className="achievements-component-section">

                </div>
            }
        </>
    );
};

export default AchievementsComponent;
