import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import "./Navbar.css";
import "./ResponsiveNavbar.css";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";
import {
  getCategoryData,
  getProductSearchData,
  getPwrCategoryMenuData,
  getSlrCategoryMenuData,
} from "../../Redux/Action/Action";
import SearchableDropdown from "./SearchableDropdown";

const NavbarSecond = () => {
  useEffect(() => {
    dispatch(getCategoryData());
  }, []);

  const categoryData = useSelector((state) =>
    state?.Reducer ? state?.Reducer?.categoryDataList?.data : []
  );


  const PwrCategoryMenuData = useSelector((state) =>
    state?.Reducer ? state?.Reducer?.PwrCategoryMenuDataList?.data : []
  );


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCatClick = (name) => {


    if (name !== undefined) {
      navigate(`/products/${name}`);
    } else {
      toast.error("There is no such product");
    }
  };

  const handleHover = (id) => {


    if (id !== undefined) {
      dispatch(getPwrCategoryMenuData(id))
    } else {
      toast.error("There is no such product");
    }
  };

  const handleSubClick = (name) => {
    if (name !== undefined) {
      navigate(`/view-product/${name}`)
    }
  }

  const handleChildClick = (name) => {

    if (name !== undefined) {
      navigate(`/view-product-series-listing/${name}`)
    }
  }

  const handleProductClick = (name) => {

    if (name !== undefined) {
      navigate(`/product-details/${name}`)
    }
  }

  const handleResultClick = (name) => {
    if (name != undefined) {
      navigate(`/product-details/${name}`);
    } else {
      toast.error("No such product found");
    }
  };
  const [showlist, setshowlist] = useState(false);

  const handleInputChange = (event) => {
    const query = event.target.value;

    dispatch(getProductSearchData(query));
    if (query === "") {
      setshowlist(false);
    } else {
      setshowlist(true);
    }
  };

  const searchResults = useSelector((state) =>
    state?.Reducer ? state?.Reducer?.productSearchDataList?.data : []
  );

  return (
    <>
      <section className="navbar-second-section d-none d-md-none d-lg-block">
        <nav className="navbar navbar-expand-lg navbar-light bg-white" >
          <div className="container">
            <a className="navbar-brand" href="#" style={{ width: "175px" }}></a>
            <button
              className="navbar-toggler d-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto navbar-nav-ms-auto-ul" style={{ position: 'relative' }}>
                {categoryData?.map((catData) => (
                  <li className="nav-item dropdown power-product-a" style={{ position: 'unset' }}>
                    <a
                      className="nav-link dropdown-toggle "
                      href="#"
                      onClick={() => { handleCatClick(catData?.slug) }}
                      onMouseOver={() => { handleHover(catData?.id) }}
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {" "}
                      {catData?.name}
                    </a>
                    <ul
                      className="dropdown-menu power-product-ul"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <div className="row">
                        {PwrCategoryMenuData?.map((subCatdata, index) => (
                          <div key={index} className="col-md-4">
                            <div className="megamenu-inner-parent-dv">

                              <h4 onClick={() => { handleSubClick(subCatdata?.slug) }} style={{ cursor: "pointer" }}> {subCatdata?.name}</h4>

                              <ul>
                                {subCatdata?.ChildCategory?.map((child, childIndex) => (
                                  <li key={childIndex}>
                                    <h5
                                      className="dropdown-item "
                                      // onClick={() => handlePowerClick(child.name)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <b onClick={() => { handleChildClick(child?.slug) }}> {child?.name}</b>
                                    </h5>
                                    <ul>
                                      {child?.producttt?.map(
                                        (prdct, productIndex) => (
                                          <li key={productIndex}>
                                            <h6
                                              className="dropdown-item"
                                              // onClick={() =>
                                              //   handlePowerClick(prdct.name)
                                              // }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <i onClick={() => { handleProductClick(prdct?.slug) }}> {prdct?.name}</i>
                                            </h6>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </ul>
                  </li>))}
                {/* <li className="nav-item dropdown solar-product-a">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    onClick={handleSolarClick}
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Solar Products
                  </a>
                  <ul
                    className="dropdown-menu solar-product-ul"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <div className="row">
                      {SlrCategoryMenuData?.map((data, index) => (
                        <div key={index} className="col-md-4">
                          <div className="megamenu-inner-parent-dv">
                            <h4>{data?.name}</h4>
                            <ul>
                              {data?.ChildCategory?.map((child, childIndex) => (
                                <li key={childIndex}>
                                  <h5
                                    className="dropdown-item "
                                    onClick={() => handlePowerClick(child.name)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <b> {child?.name}</b>
                                  </h5>
                                  <ul>
                                    {child?.producttt?.map(
                                      (prdct, productIndex) => (
                                        <li key={productIndex}>
                                          <h6
                                            className="dropdown-item"
                                            onClick={() =>
                                              handlePowerClick(prdct.name)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i> {prdct?.name}</i>
                                          </h6>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </ul>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/store-locator"
                  >
                    Store Locator
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/download">
                    Downloads
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        About Us <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link className="dropdown-item" to="/about-us">EAPRO Group</Link></li>
                                        <li><Link className="dropdown-item" to="/global-energy-ltd">EAPRO Global Ltd.</Link></li>
                                        <li><Link className="dropdown-item" to="/global-energy">EAPRO Global Energy Pvt. Ltd.</Link></li>
                                        <li><Link className="dropdown-item" to="/eapro-overseas">EAPRO Overseas Pvt. Ltd.</Link></li>
                                        <li><Link className="dropdown-item" to="/trc-efficient-services">TRC Efficient & Services Pvt. Ltd.</Link></li>
                                        <li><Link className="dropdown-item" to="/efficient-mechanical-solar">Efficient Mechanical & Solar Pvt. Ltd.</Link></li>
                                    </ul>
                                </li> */}
                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/business-division">Business Divisions</Link>
                                </li> */}
                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/help-and-support">Support</Link>
                                </li> */}
                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/career-at-eapro">Career</Link>
                                </li> */}
                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/knowledge-center">Knowledge Center</Link>
                                </li> */}
                {/* <li className="nav-item search-item my-auto">
                                    <div className="search-inner-dv">
                                        <input type="text" placeholder='Search for a product' />
                                        <a href="#" className='search-icon-a-tag'><i className="fa fa-search" aria-hidden="true"></i></a>
                                    </div>
                                </li> */}
              </ul>
              {/* <ul>
                                <Dropdown
                                    placeholder='Select Country'
                                    fluid
                                    search
                                    selection
                                    options={mappedOptions}
                                    onChange={handleInputChange}
                                />
                            </ul> */}
              <ul className="navbar-nav navbar-nav-second-ul">
                <li className="nav-item search-item my-auto">
                  <div className="search-inner-dv">
                    <input
                      type="text"
                      placeholder="Search for a product"
                      onChange={handleInputChange}
                    />
                    <a href="#" className="search-icon-a-tag">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </a>
                    {showlist && searchResults?.length > 0 && (
                      <ul className=" search-results-ul ">
                        {searchResults?.map((result) => (
                          <li
                            key={result?.id}
                            onClick={() => handleResultClick(result?.slug)}
                            style={{ cursor: "pointer" }}
                          >
                            {result?.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </section >
    </>
  );
};

export default NavbarSecond;
